<template>
  <div class="form-check p-0 flex flex-row">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :checked="internalChecked"
      :name="name"
      @click="toggleState"
    />
    <label :for="id" class="form-label">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "MaterialCheckbox",
  props: {
    checkedValue : {
      type : null,
      default : true,
    },
    uncheckedValue : {
      type : null,
      default : false,
    },
    modelValue : {
     type : Boolean,
     default : false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    checked : {
      type : Boolean,
      default : null
    },
  },
  emits: ['update:modelValue','change'],
  data : function() {
     return {
        internalChecked : false,
     }
  },
  computed : {
  },
  watch : {
     checked : function(newValue ) {
        this.internalChecked = newValue
     }
  },
  mounted() {
     if (this.modelValue != null) {
       this.internalChecked = this.modelValue
     } else {
       this.internalChecked = this.checked
     }
  },
  methods : {
    toggleState({target}) {
       const newState = target.checked
       if (this.checkedValue != null && newState) {
        this.$emit('update:modelValue',this.checkedValue)
        this.$emit('change',this.checkedValue)
       } else if (this.uncheckedValue != null && !newState) {
        this.$emit('update:modelValue',this.uncheckedValue)
        this.$emit('change',this.uncheckedValue)
       } else {
        this.$emit('update:modelValue',newState)
        this.$emit('change',newState)
       }
     }
  }
};
</script>
