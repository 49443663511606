<template>
  <form v-if="row != null" id="editaging" role="form" class="form container">
      
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="routetype" class="control-label ">Route type</label>
              
                <v-select v-model="row.routetype" :reduce="sel => sel.id" name="routetype" id="routetype" :options="basicRouteTypes">
                </v-select> 
            </div>
            <div class="form-group">
              <label for="gradeid" class="control-label col-sm-3">Choose a grade</label>
              <div class="col-sm-4">
              <v-select  id="gradeid" v-model="row.gradeid"  :reduce="sel => sel.id" :options="gradesDropdown" />
              </div>
            </div>
      
            <div class="form-group">
              <label for="aging" class="control-label ">Aging</label>
              
                <div class="col-4">
                <input type="text" name="aging" id="aging" class="form-control" required="required" v-model="row.aging">
                </div>
                <span class="help-block">Aging defaults to weeks. Usual values are 8 weeks for a boulder, 16 weeks for a sport route. You can use the following abbreviations for aging times if you want. 15d (15 days), 8w (8 weeks), 2m (2 months), 1y (1 year).</span>
              
            </div>
            <div class="form-group">
              <label for="active" class="control-label">Active?</label>
                <checkbox id="active" :unchecked-value="0" :checked-value="1" v-model="row.active" />
              
            </div>
          </div> <!-- end of col-8-->
        </div><!-- /row -->
      </form>
      <div v-else>Loading...</div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed } from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Checkbox from '@/components/MaterialCheckbox.vue'
import { basicRouteTypes } from '@/helpers/problemator'

const store = useStore()
const props = defineProps({ 'id': String })
const grades = computed(() => store.state.basic.grades )

const row = computed(() => store.state.aging.row)
const skeleton = {
  id: "*new*",
  active : true,
}

if (props.id == null) {
  // Assume new problem (skeleton)
  store.commit('aging/row', { ...skeleton })
} else {
  store.dispatch('aging/get', props.id)
}

// eslint-disable-next-line no-unused-vars
const save = (type) => {
  return store.dispatch('aging/save', { ...row.value })
}
const reset = () => {
  console.log("Resetting aging to", skeleton)
  const newSkeleton = { ...skeleton }
  store.commit('aging/row', newSkeleton)
}
const gradesDropdown = computed(() => {
  if (grades.value == null) {
    return []
  }
  return grades.value.map(a => ({id : a.id, label : a.name }))
})

// Expose save method outside
defineExpose({ save, reset })
</script>