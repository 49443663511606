<template>
  <div class="container">
    <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
      <h1 class="page-header">Circuits <small><button class="btn btn-sm btn-primary mt-3 ms-3" @click="circuitList.addNew" > Add new...</button></small></h1>
      <p class="lead">Circuits can serve a multitude of purposes.<br /><br />Generally they offer a way for climbers to climb pre-defined set
      of problems, which will consist a nice training or cool route experience.<br /><br />On the other hand circuits can be a tool for trainers
      and personal trainers to give climbers a certain train. Eg. Power endurance circuit, Training for 7a, Maximum power for 7c to give a few
      examples.</p>
      <div id="circuitlist_container" >
        <h3>Public circuits</h3>
        <p>These are the public circuits visible for everyone</p>
        <circuit-list id="circuitList" ref="circuitList" :type="visible" :circuits="publicCircuits"></circuit-list>
        <h3>Private circuits</h3>
        <p>These circuits are viewable only for the people who are given the access. Remeber, these are viewable by other routesetters, so they are private for climbers, not routesetters.</p>
        <circuit-list :type="hidden" :circuits="privateCircuits">></circuit-list>
      </div>
    </div>
  </div><!-- end of row -->
</template>
<script setup>
// eslint-disable-next-line no-unused-vars
import CircuitList from '@/components/circuits/CircuitList.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const circuitList = ref(null)

store.dispatch('circuits/getCircuits')
const publicCircuits = computed(() => store.getters['circuits/publicCircuits'])
const privateCircuits = computed(() => store.getters['circuits/privateCircuits'])
</script>
