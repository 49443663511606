<script setup>
import { ref } from 'vue'
import MaterialButton from '@/components/MaterialButton.vue'
import OmaTable from '@/components/OmaTable.vue'
import { useToast } from "vue-toastification"
import { useStore } from 'vuex'
const toast = useToast()
const store = useStore()
const startYear = 2015
const years = Array.from({ length: new Date().getFullYear() - startYear + 1 }, (v, k) => startYear + k).reverse()
const groupbys = ['year', 'month', 'week']
const filter = ref({
    year: new Date().getFullYear(),
    group_by: 'month'

})
const rows = ref([])
const fetchReport = () => {
    store.dispatch('reports/fetchRoutesetterReport', filter.value)
    .then(ret => rows.value = ret)
}
const sortable = {
    order: 'id',
    sort: 'desc',
}

const columns = [
    { id: 'year', label: 'Year', sortable: false, },
    { id: 'month', label: 'Month', sortable: false, },
    { id: 'week', label: 'Week', sortable: false, },
    { id: 'problems', label: 'Problem amount', sortable: false, },
    { id: 'author', label: 'author', sortable: false, },
]
</script>
<template>
    <div class="routesetter-report p-4">
        <h1>Routesetter Report</h1>
        <p>A simple report to show how many problems each routesetter has set</p>
        <!-- Add your report content here -->
        <div class="flex">
            <div>
                Year
                <select v-model="filter.year" class="px-8 py-2 rounded border">
                    <option v-for="y in years" :value="y">{{ y }}</option>
                </select>
            </div>
            <div>
                Group by
                <select v-model="filter.group_by" class="px-8 py-2 rounded border">
                    <option v-for="g in groupbys" :value="g">{{ g }}</option>
                </select>
            </div>
            <div>
                <MaterialButton @click="fetchReport">Fetch</MaterialButton>
            </div>
        </div>
    </div>

      <oma-table :rows="rows" :cols="columns" :sortable="sortable" class="table table-striped table-condensed table-hover">
      </oma-table>
    
</template>


<style scoped>
.routesetter-report {
    /* Add your styles here */
}
</style>
