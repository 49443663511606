<template>
    <table class="table">
        <thead>
        <slot name="filtering">
            <tr>
                <th v-for="col in columns" :key="col">
                  <div v-if="col.filtering===true" class="flex flex-col">
                  {{ col.label }} filter:   
                  <input class="w-16 py-1 px-2 border border-gray-700"  type="text" v-model="filtering[col.id]" placeholder="Filter text" />
                  </div>
                </th>
            </tr>
        </slot>
        <slot name="header" v-bind="sortByColumn">
            <tr>
                <th v-for="col in columns" :key="col">
                    
                    <a v-if="col.sortable ===true" href="#" @click.prevent="sortByColumn(col.id)">
                    <i v-if="!sortDirectionDown" class="fa fa-sort-down"></i>
                    <i v-else class="fa fa-sort-up"></i>
                    {{ col.label }}
                    </a>
                    <span v-else> {{ col.label }} </span>
                </th>
            </tr>
        </slot>
        </thead>
        <tbody>
        <slot >
            
            <tr v-for="row in rowsSortedAndFiltered" :key="row.id" class="relative">
                <td v-for="colObj in columns" :key="colObj.id" :class="colObj.class">
                    <slot :name="colObj.id" v-bind="{ value : row[colObj.id], row}">
                        {{getValue(row,colObj.id)}}
                    </slot>
                </td>
                <slot name="detailsRow" v-bind="{ row}"></slot>
            </tr>
            <tr v-if="rowsSorted.length == 0">
                <th colspan="100" class="mx-2 p-1">Much empty</th>
            </tr>
        </slot>
        </tbody>
        <tfoot>
        <slot name="footer"></slot>
        </tfoot>
    </table>
</template>
<script setup>
    import {computed, ref } from 'vue'

    const props = defineProps({
        'rows' : {type : Array, default : []},
        'cols' : {type : Array, default : []},
    })
    const activeSortFunction = ref(undefined) // Undefined equals to no sorting

    const filtering = ref({})
    const sortColumn = ref(null)
    const sortDirectionDown = ref(false) // denotes the active sort direction

    /**
     * Parses dot notation
     *  
     * @param {Object} obj Object to find the dot notation
     * @param {String} field Field name, eg. simple 'name' or 'grade.name'
     */
    const getValue = (obj, field) => {
        let ret = ""
        try {
          ret = field.split(".").reduce((o,i)=> o[i], obj)
        } catch(e) {
          ret = ""
        }
        return ret
          
    }
    const sortByColumn = (col) => {
        sortColumn.value = col
        sortDirectionDown.value = !sortDirectionDown.value 
        const colObj = props.cols.find(x => x.id == col)
        const sortKey = (colObj.sortKey != null) ?  colObj.sortKey : null

        activeSortFunction.value = (a,b) => {
            let sa = a[sortColumn.value]
            let sb = b[sortColumn.value]
            let tmp = null

           // If either value is null, assume greater/lesser
            if (sa == null) {
                return 1
            }
            if (sb == null) {
                return -1
            }
            // If sort direction is changed, change the comparable 
            // values to achieve the effect.
            if (sortDirectionDown.value) {
                tmp = sa
                sa = sb
                sb = tmp
            } 
            // Check if value is numeric and sort by numeric value
            if (!isNaN(parseFloat(sa)) && !isNaN(parseFloat(sb))) {
                return sa - sb
            }
            // Sort by sortkey, if it is defined in props
            if (sortKey != null) {
                return sa[sortKey].toString().localeCompare(sb[sortKey].toString())

            } else{
                // Assume string
                return sa.toString().localeCompare(sb.toString())
            }
        }

    }
    const rowsSortedAndFiltered = computed(() => {
        // Filter by active filters.
        return rowsSorted.value.filter(row => {
            const filterStatusAfterAllFiltered = Object.keys(filtering.value).reduce((acc,colName) => {
                const colFilterValue = filtering.value[colName]
                // If the target is object, what to do, no idea.
                let compareTo = row[colName]
                // If filterField is set, apply it
                const colObj = props.cols.find(x => x.id == colName)
                if (colObj.filterField != null ) {
                    compareTo = getValue(row,colName)
                } else if (compareTo != null  && colObj.filterFields != null) {
                    // There are multiple filterfields, so generate a string.
                    compareTo = colObj.filterFields.map(fieldName => compareTo[fieldName]).join("")
                }

                let isMatch = true
                if (typeof compareTo == 'string') {
                if (colObj.ignoreCase != null) {
                  isMatch = compareTo.toLocaleLowerCase().indexOf(colFilterValue.toLocaleLowerCase()) != -1

                } else {
                  isMatch = compareTo.indexOf(colFilterValue) != -1
                }
                } else if (typeof compareTo == 'object')  {
                    console.log(colName,'comparison against an object',compareTo,'needs probably a filterField attribute to cols-object')
                }
                // If acc is false, let it be false. If it is true,
                // just place the match value. This means that if there
                // is even one NOT match, the end results is a not match.
                if (acc) {
                    acc = isMatch
                }
                return acc
            },true)
            return filterStatusAfterAllFiltered
        })
    })
    const rowsSorted = computed(() => {
        if (props.rows == null) {
            return []
        }
        return props.rows.sort(activeSortFunction.value)
    })
    const columns = computed(() => {
        // Generate from data itself, handy for quick use of tables
        if (props.cols.length == 0) {
            if (props.rows.length == 0) {
                return []
            }
            const aRow = props.rows.find(x=>x!=null)
            return  Object.keys(aRow).map(key => ({id : key, label : key.toString().replace("_"," ").replace("."," ")}))
        // Otherwise use the props
        } 
        return props.cols.map(col => {
            // If col label is not given, fill it with col id
            if (col.label == null) {
                // Replace underscore with space also
                return {...col,['label'] : col.id.toString().replace("_"," ").replace("."," ")}
            }
            return col
        })
    })



</script>
