<template>
  <modal v-model="showAscentsDialog" title="Circuit ascents by climber" size="lg" ok-only>
    <p>Please remember, that even though someone might have ascentCount equal or larger than the amount of problems, but
      the finisher count is 0 or does not match, it usually means that the climber has climbed some problem more than once.</p>
    <circuit-ascents :id="selectedCircuit" />
  </modal>
  <modal v-model="showAllowedUsersDialog" title="Users who can access this circuit" ok-only @ok="showAllowedUsersDialog=false">
    <allowed-users :id="selectedCircuit" />
  </modal>
  <modal v-model="showCircuitProblemsDialog" title="Problems in this circuit" size="lg" ok-only close-on-ok>
    <div v-if="circuit != null">

      <div class="card text-dark bg-info "><div class="card-body">Hey, you can select all by clicking CTRL-A (or ⌘-A on Mac) when you have clicked the Problems selectbox. Or cherry pick by using CTRL+click a row (⌘+row on a Mac. Or ranges with SHIFT key pressed while choosing start and end.)</div></div>
      <column-selector  v-model="selectedProblems" :available="gymProblems" />
      <div class="">
      <button @click="attachProblemsToCircuit" class="w-full my-2 text-white rounded-sm px-2 py-2 bg-green-500">Attach problems to circuit</button>
      </div>
    
       
      <oma-table :rows="circuit.problems" :cols="circuitProblemCols" class="table table-striped table-condensed table-hover">
        <template #author="{value}">
          {{value.etunimi }} {{value.sukunimi}}
        </template>
        <template #wall="{value}">
          {{value.wallchar }} {{value.walldesc}}
        </template>

      </oma-table>
    </div>
  </modal>
  <modal v-model="showEditDialog" title="Circuit editor" ok-title="Save" @ok="onSaveCircuit" size="lg">
    <circuit-editor :id="selectedCircuit" ref="refCircuitEditor" />
  </modal>

  <oma-table :rows="circuits" :cols="columns" class="table table-striped table-condensed table-hover">
    <template #minmax="{row}">{{ row.mingrade.name }} - {{ row.maxgrade.name }}</template>
    <template #colour="{row}">
      <colour-box :bg="row.color.code" :text="row.color.textcolor" />
    </template>
    <template #activeproblems="{row}">{{ row.problems.length }} </template>
    <template #actions="{row}">
      <Dropdown @action="onAction">
          <template #default="{action}">
          <li><a class="link-info " href="#" @click.prevent="action('edit',row.id)">Edit</a></li>
          <li><a class="" href="#" @click.prevent="action('ascents',row.id)">Ascents</a></li>
          <li><a class="" href="#" @click.prevent="action('allowed_users',row.id)">Allowed users</a></li>
          <li><a class="" href="#" @click.prevent="action('circuit_problems',row.id)">Circuit problems</a></li>
          <li><a class="link-danger " href="#" @click.prevent="action('remove',row.id)">Remove</a></li>
          </template>
      </Dropdown>
    </template>
  </oma-table>
</template>
<script setup>

import { onMounted, computed, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import OmaTable from '@/components/OmaTable.vue'
import ColourBox from "@/components/ColourBox.vue"
import CircuitEditor from '@/components/circuits/CircuitEditor.vue'
import CircuitAscents from '@/components/circuits/CircuitAscents.vue'
import AllowedUsers from '@/components/circuits/CircuitAllowedUsers.vue'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '@/helpers/errors.js'
import Dropdown from '@/components/Dropdown.vue'
import ColumnSelector from '@/components/ColumnSelector.vue'

const attachProblemsToCircuit = () => {
  const payload = {
    circuitid : selectedCircuit.value,
    problemids : selectedProblems.value.map(x => x.id)
  }
  store.dispatch('circuits/syncCircuitProblems', payload)
}
const problems = computed(() => store.state.problems)
const selectedProblems = ref([])
const gymProblems = computed(() => Object.keys(problems.value).map(key => {
    const x = problems.value[key]
    // Include tag in map, because it will be used in sorting later
    let author = null
    if (x.author != null && x.author.nick != null) {
        author = x.author.nick
    }
    return { id: x.id, 'label': x.tag?.substr(7) + " " + x.grade.name + " " + x.colour.name + " by " + author, tag: x.tag }
}).sort((a, b) => {
    // Sort by tag
    return a.tag.localeCompare(b.tag)
})
)
const toast = useToast()
const store = useStore()
const circuit = computed(() => store.state.circuits.row)

const showAscentsDialog = ref(false)
const showAllowedUsersDialog = ref(false)
const showCircuitProblemsDialog = ref(false)
const showEditDialog = ref(false)
const selectedCircuit = ref(null)

const circuitProblemCols = [
  { id: 'id', label: 'Id' },
  { id: 'grade.name', label: 'Grade' },
  { id: 'colour.name', label: 'Colour' },
  { id: 'wall', },
  { id: 'author', },

]
const columns = [
  { id: 'id', label: 'Id' },
  { id: 'circuitname', label: 'Name' },
  { id: 'colour', label: 'Colour' },
  { id: 'circuitshortname', label: 'Short' },
  { id: 'minmax', label: 'Min-max grade' },
  { id: 'activeproblems', label: 'Active problems' },
  { id: 'allowed_users_count', label : "# allowed users" },
  { id: 'finisherCount', label: 'Finisher count' },
  { id: 'actions', label: 'Actions' },
]
const props = defineProps({
  type: { type: String, default: 'visible' },
  circuits: { type: Array, default: [] },
})
onMounted(() => {
  if (problems.value.length == 0) {
    store.dispatch('getProblems')
  }
})
const refCircuitEditor = ref(null)
const onAction = ({action, id}) => {
  switch (action) {
    case 'edit':
      selectedCircuit.value = id
      showEditDialog.value = true
      break

    case 'ascents':
      selectedCircuit.value = id
      showAscentsDialog.value = true
      break

    case 'allowed_users':
      showAllowedUsersDialog.value = true
      selectedCircuit.value = id
      store.dispatch('circuits/get', id)
      break

    case 'circuit_problems':
      showCircuitProblemsDialog.value = true
      selectedCircuit.value = id
      // Populate selectedProblems
      selectedProblems.value = props.circuits.find(x => x.id == id).problems.map(x => ({...x,'label' : x.tag?.substr(7)  }))
      store.dispatch('circuits/get', id)
      break

    case 'remove':
      console.log(id)
      if (confirm("Do you want to remove this circuit?")) {
        store.dispatch('circuits/delete', { id })
          .then(() => toast.success("Deleted"))
      }
      break
  }
}

const addNew = () => {
  selectedCircuit.value = null
  showEditDialog.value = true
}
const onSaveCircuit = () => {
  refCircuitEditor.value.save()
    .then(() => {
      toast.success("Saved")
      selectedCircuit.value = null
      showEditDialog.value = false
      refCircuitEditor.value.reset()
    })
    .catch(err => {
      toast.error(handleValidationErrors(err.response.data))
    })

}
defineExpose({ addNew })
</script>
