<template>
  <form v-if="row != null" id="editfloorplan" role="form" class="form container">
    <div class="row">
  
    </div> <!-- /row-->
  
  
  
  </form>
  <div v-else>Loading...</div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed} from 'vue'
const store = useStore()
const props = defineProps({ 'id': String })


const row = computed(() => store.state.floorplans.row)
const skeleton = {
  id: "*new*",
}

if (props.id == null) {
  // Assume new problem (skeleton)
  store.commit('floorplans/row', { ...skeleton })
} else {
  store.dispatch('floorplans/get', props.id)
}

// eslint-disable-next-line no-unused-vars
const save = async (type) => {
  return store.dispatch('floorplans/save', { ...row.value })
}
const reset = () => {
  console.log("Resetting floorplan to", skeleton)
  const newSkeleton = { ...skeleton }
  store.commit('floorplans/row', newSkeleton)
}
// Expose save method outside
defineExpose({ save, reset })
</script>