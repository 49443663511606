<template>
    <div class="row">

        <div class="col-12">
            <label class="control-label " for="tag">Problem tag</label>
            <p class="static-control">{{ eProblem.tag }}</p>
        </div>


        <div class="col-12 ">
            <label class="control-label " for="hidden">Hide problem (works only in sport comps)</label>
            <checkbox v-model="eProblem.pivot.hidden" checked-value="1" unchecked-value="0" />
        </div>


        <div class="col-12 ">
            <label class="control-label " for="max_points">Max points (eg. 45. This is obligatory if route progess graph is used)</label>
            <input type="text" name="max_points" v-model="eProblem.pivot.max_points" size="2" class="form-control">
        </div>


        <div class="col-12 ">
            <label class="control-label " for="num">Number (usually the number on the problem tape)</label>
            <input type="text" name="num" v-model="eProblem.pivot.num" size="2" class="form-control">
        </div>


        <div class="col-12">
            <label for="routetype">Route type (1. Only shows the categories which are already in this competiton. 2. works only with sport climbing competition)</label>
            <v-select id="routetype" v-model="eProblem.pivot.routetype" :reduce="sel => sel.id" :options="routetypeOptions" 
            >
            </v-select>
        </div>


        <div class="col-12">
            <label for="bind_to_category">Bind route to a category (works only with sport climbing competition)</label>
                <v-select id="serieid" v-model="eProblem.pivot.bind_to_category" :reduce="sel => sel.id" :options="seriesDropdown">
                </v-select>
        </div>

    </div>
</template>
<script setup>
import Checkbox from '@/components/MaterialCheckbox.vue'
import { ref, computed } from 'vue'
import { routeTypes } from '@/helpers/problemator.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const props = defineProps({ problem : Object, competition : Object})
const eProblem = ref({...props.problem})
const seriesDropdown = computed(() => {
    return props.competition.categories.map(c => {
        const label = c.nimi 
        return { id: c.id, label }
    })
})
// eslint-disable-next-line
const value = computed(() => eProblem.value)

const routetypeOptions = computed(() => {
    return [{ id: null, label: 'Select competition phase' }, ...routeTypes]
})
defineExpose({value})

</script>