<template>
  <form  id="usermerger" role="form" class="form container">
    <p class="lead">
    Users tend to add duplicate accounts. This is a tool which you can use to merge duplicate climbers.</p>
    
    <div class="row">
      <label for="master">Merge master (<small>Search the user the other climbers will be merged TO</small>).</label>
    </div>
    <div class="row">
    <div v-if="to != null">
     {{ to.id }} {{ to.etunimi }} {{ to.sukunimi }} {{ to.email}}
     <button @click="to=null" class="text-danger">clear</button>
    </div>
      <contender-search v-model="to" :url="searchURL" />
    </div>
    
    <div class="row">
      <label for="slaves">Climbers to be merged to the climber above</label>
    </div>
    <div class="row">
      <div v-if="from.length > 0">
        <ul>
      <li v-for="f in from" :key="f.id">{{ f.id }} {{ f.etunimi }} {{ f.sukunimi }} <button @click="from=from.filter(x => x.id!=f.id)" class="text-danger">clear</button> </li>
      </ul>
      </div>
      <contender-search v-model="from" :url="searchURL" @select="onSelect" multiple />
    </div>
    
    
    
    </form>
</template>
<script setup>
import ContenderSearch from '@/components/ContenderSearch.vue'
import { endpoint } from '@/helpers/problemator.js'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification";

const store = useStore()
const toast = useToast();

const searchURL = computed(() => {
  return endpoint + "climber/search"
})

// eslint-disable-next-line no-unused-vars
const merge = () => {
  store.dispatch('mergeClimbers', {from : from.value.map(x => x.id), to: to.value.id})
  .then(ret => {
    toast.success(ret.message)
    // reset
    to.value = null
    from.value = []

  })
}
const to = ref(null)
const from = ref([])

// Expose save method outside
defineExpose({ merge })
</script>