<template>
    <div class="row bg-white p-2">

        <modal v-model="showEditDialog" title="Circuit editor" ok-title="Save" @ok="onSaveCup">
            <cup-editor :id="selectedCup" ref="refCupEditor" />
        </modal>

        <h1 class="">Cup {{ route.params.id }}</h1>
        <div v-if="cup !== null" >

        </div>
</div>
<!-- end of row -->
</template>

<script setup>
import { useRoute } from 'vue-router'
import {  computed, onMounted, ref } from 'vue'
// eslint-disable-next-line
import CupEditor from '@/components/competitions/CupEditor.vue'
import Modal from '@/components/Modal.vue'
import store from '../../store/store'
const showEditDialog = ref(false)
const cupEditor = ref(null)
const route = useRoute()
const cup = computed(() => store.state.cups.cup)
const cupid = ref(null)
onMounted(() => {
    store.dispatch('cups/getCup', route.params.id)
    cupid.value = route.params.id
})
const onSaveCup = () => {
    /*
    cupEditor.value.save()
        .then(ret => {
            console.log(ret)
            selectedID.value = null
            showDialog.value['editcontender'] = false
            contenderEditor.value.reset()
            toast.success("Saved")
        })
        .catch(err => {
            toast.error(handleValidationErrors(err.response.data))
        })
        */
}
</script>
  