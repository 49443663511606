<template>
    <div v-if="comp != null" id="editcomp" role="form" class="form">

        <tabs ref="settingsTab" :options="{ defaultTabHash: 'first-tab' }">
            <tab id="first-tab" name="Basic settings">
                <div class="row">
                    <h3>Competition name, date and location</h3>
                    <div class="col-12 col-md-8">
                        <label class="control-label " for="name">Competition name</label>
                        <input type="text" id="name" name="name" v-model="comp.name" size="25" required="required" class="form-control" />
                        <!--  This competition is originally created by ({$comp.creator.name}, {$comp.creator.country})-->
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="form-label" for="compdate">Competition date </label>
                        <div class="flex w-full">
                            <date-picker :timezone="gym?.timezone" mode="dateTime" v-model="comp.compdate" class="flex-grow" is24hr :minute-increment="5">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input id="date" class="bg-white text-gray-700 w-full py-2 px-3 appearance-none border rounded-l focus:outline-none" :class="{ 'border-red-600': errorMessage }" :value="inputValue" v-on="inputEvents" />
                                </template>
                            </date-picker>
                            <button type="button" class="text-white font-bold py-2 px-4 rounded-r user-select-none focus:outline-none" :class="comp.compdate ? 'bg-red-500' : 'bg-red-300'" :disabled="!comp.compdate" @click="comp.compdate = null">
                                Clear
                            </button>
                        </div>
                        <p class="text-red-600 text-xs italic mt-1" v-if="errorMessage">
                            {{ errorMessage }}
                        </p>
                        <p class="text-green-500 text-xs font-bold mt-1" v-else>
                            Date is valid
                        </p>
                        <div class="text-sm text-blue-400 help-block">(and time when the competition venue opens)</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="control-label" for="location">Competition location </label>
                        <input size="16" type="text" name="location" class=" form-control" v-model="comp.location">
                        <p class="help-block">(Where the comp is help, eg. Exhibition Center Stockholm)</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="control-label" for="location">Competition timezone </label>
                        <input size="16" type="text" name="location" class=" form-control" v-model="comp.timezone">
                        <p class="help-block">(Competition timezone, the timezone where the competition venue is)</p>
                        <p class="help-block">Refer to <a href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones">this list</a> for valid timezones.</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="control-label" for="location">Competition judging password </label>
                        <input size="16" type="text" name="judge_pass" class=" form-control" v-model="comp.judge_pass">
                        <p class="help-block">Set this password to enable judging mobile page sharing to the judges.</p>
                    </div>
                </div>
                <div class="row">

                    <div class="col-12">
                        <h3>Competition publicity and registration</h3>
                        <checkbox id="ispublic" v-model="comp.ispublic">
                            Is public? (Show in public listings, membership comps should be private)
                        </checkbox>
                        <p>When the competition is NOT public, it is NOT shown on the public listing at all. This is usually used in competitions where the judges enter the points. <strong>OR</strong> in membership competitions where this is used along with the 'adhoc participation' setting.</p>
                    </div>



                    <div class="col-12 ">
                        <checkbox v-model="comp.adhocentry">
                            Adhoc participation enabled
                        </checkbox>
                        <p class="help-block">When the competition is NOT public, it can still be searched and it can be joined when the search results give a hit. This is very useful when having membership competitions which are not public but climbers (members) should be able to join the competitions.
                            <br /><br />
                            Advice members to go to The Problemator Mobile App, menu -> Competitions and then search for the competition name and click "REGISTER" from the search results.
                        </p>
                    </div>

                    <div class="col-12 ">
                        <checkbox v-model="comp.mobile_enabled">
                            Mobile point entry enabled
                        </checkbox>
                        <p class="help-block">This allows app users to enter points. If disabled, only the judges can enter the points. Please note that DISABLING this the competition will NOT show in The Problemator...</p>
                    </div>

                    <div class="col-md-12">
                        <checkbox v-model="comp.locked">
                            Locked
                        </checkbox>
                        <p class="helper-block">No ticks are allowed and resultlist does not update when the competition is locked</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h3>Competition type</h3>
                        {{ comp.tyyppi }}
                        <p class="w-4/5">There are many types for the competition. Sport, bouldering and different variations for each types. Here you
                            can choose the type/variant for the competition you see fit best. You can select an option and see an intro
                            text about the selected competition variant.</p>
                        <v-select id="comp_type" v-model="comp.tyyppi" :reduce="sel => sel.id" :options="competitionTypes">
                        </v-select>
                        <div class="w-4/5 border-2 border-radius-lg p-2 m-2 border-blue-700 bg-blue-100 ">
                            <h4>Competition type explanation</h4>
                            <p class="mx-1 font-bold">{{ comp.tyyppi }}</p>
                            <show-competition-type-texts :type="comp.tyyppi" @rule-template-selected="onRuleTemplateSelected" />
                        </div>

                    </div>

                    <div class="col-12 ">
                        <textarea name="pointsharing" rows="4" class="form-control" id="pointsharing" v-model="comp.pointsharing" required="required"></textarea>
                    </div>


                    <div class="col-12" v-if="comp.tyyppi == 'variable_points'">
                        <label class="control-label " for="nbest">Take into account n hardest ascents (0=include all)</label>
                        <input class="form-control" type="text" name="nbest" id="nbest" v-model="comp.nbest" size="3" /><br />
                        <p class="help-block ">If competition has eg. 50 problems. This settings gives the option to include only n hardest ascents of each climber. This is effective only in 'Variable points' calculation method.</p>
                    </div>

                </div>
            </tab>
            <tab name="Registration settings">
                <div class="row">

                    <div class="col-12">
                    <a class="text-primary" :href="webendpoint+'comps/'+competition.id+'/register/'" target="_print">Link to registration form</a>
                    </div>

                    <div class="col-12">
                        <label class="control-label " for="maxcontenders">Maximum amount of contenders allowed (0 = unlimited)</label>
                        <input class="form-control" type="text" name="maxcontenders" id="maxcontenders" v-model="comp.maxcontenders" size="3" />
                        <p>Applies only to web registrations. Meaning that routesetter can add as many contenders as one pleases.</p>
                    </div>



                    <div class="col-12">
                        <checkbox v-model="comp.offer_payment">
                            Offer payment via Paytrail
                        </checkbox>
                        <p>This will send also a payment instructions via email to the contender.</p>
                    </div>


                    <label>Payment service to use</label>
                    <v-select id="paymentservice_id" v-model="comp.paymentservice_id" name="paymentservice_id" :reduce="sel => sel.id" :options="getPaymentServices">
                        <!--
                        <option value="">##Use Problemator payment service##</option>
                        {foreach $paymentservices as $method}
                        <option data-username="{$method.username}" data-password="{$method.password}" value="{$method.id}" {if $comp.paymentservice_id==$method.id}selected="selected" {/if}>{$method.displayname|default:"My gym settings, username: {$method.username}"}</option>
                        {/foreach}
                        -->
                    </v-select>
                    <div class="well">If you want to have your settings in a selectbox below, please open <a href="/gym/gymsettings/">Gym Settings</a> and add them there.</div>
                    <p>If you offer a payment via Paytrail and you do NOT have your own Paytrail account, Problemator will handle the payment receiving and will charge you per payment. The fee will depend on current Paytrail price list, but it's around 1&euro;/contender. You will receive a receipt of payments made via Paytrail and our fee deducted from it. If we have your bank details, we will wire the money within a couple of working days after you have informed info@problemator.fi that you had a competition...</p>



                    <div class="col-12">
                        <checkbox v-model="comp.forcepayment">
                            Force payment (no registration if the registration fee is not paid). This also means that any registration
                            goes to "unpaid contenders" by default
                        </checkbox>
                        <small>If you also want the payment link to be included, you must select the 'Offer payment via Paytrail'-checkbox.</small>
                    </div>



                    <div class="col-12">
                        <label class="control-label " for="register_form_text">Registration page info text</label>
                        <textarea cols="80" rows="5" class="form-control" id="register_form_text" name="register_form_text" v-model="comp.register_form_text"></textarea>
                        <p>You can add competition information here, click here for a sample.</p>
                    </div>



                    <!--
                    <div class="col-md-12">
                        <label class="control-label " for="custom_template">Result page custom template</label>
                        <input type="text" name="custom_template" v-model="comp.custom_template" id="custom_template" />
                        <p class="helper-block">Be <strong>VERY</strong>careful with this. You will most probably break your results page with this...</p>
                    </div>
                    -->



                    <div class="col-md-12">
                        <label class="control-label " for="hinta">Registration prices</label>
                        <p>These can be entered in the category setting inside the competition page.</p>
                    </div>




                </div> <!-- tab panel -->
            </tab>
            <tab name=" Time related ">
                <div class=" row">

                    <div class="col-12 col-md-8 ">
                        <p>Set these dates and times, these are usually pretty important.
                            These are the times when user ticks are included in the competition.
                            Normally it is competition date. eg 2016-10-12 10:00 - 2016-10-12 16:00, but it can also be longer time period
                            eg. 2016-12-01 - 2016-12-31 to allow users to participate in a competition for a month.</p>
                        <p>Pay also attention to the registration start/end times. If they are set hastily,
                            contenders might not be able to register to the competition.</p>
                    </div>
                </div>
                <div class=" row">
                    <div class="col-12 col-md-8 ">
                    <strong v-if="gym != null && gym.timezone != null">Please not that ALL OF THE TIMES are IN {{ gym.timezone }} timezone.</strong>
                    <strong class="text-red-500 font-bold" v-else>GYM TIMEZONE IS NOT SET! Please set it from gym settings</strong>
                    </div>
                </div>
                <div class="row">

                    <div class="col-12 col-md-3">
                        <label class="control-label " for="timespan_start">Competition tick time starts</label>
                        <date-picker :timezone="gym?.timezone" mode="dateTime" v-model="comp.timespan_start" :minute-increment="5" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                            </template>
                        </date-picker>
                    </div>
                    <div class="col-12 col-md-3">
                        <label class="control-label " for="timespan_end">Competition tick time ends</label>
                        <date-picker :timezone="gym?.timezone" mode="dateTime" v-model="comp.timespan_end" :minute-increment="5" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                            </template>
                        </date-picker>

                    </div>
                    <div class="col-12 col-md-3">
                        <label class="control-label " for="registration_start">registration time starts</label>
                        <date-picker :timezone="gym?.timezone" mode="dateTime" v-model="comp.registration_start" :minute-increment="5" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                            </template>
                        </date-picker>

                    </div>
                    <div class="col-12 col-md-3">
                        <label class="control-label " for="registration_end">registration time ends</label>
                        <date-picker :timezone="gym?.timezone" mode="dateTime" v-model="comp.registration_end" :minute-increment="5" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                            </template>
                        </date-picker>

                    </div>
                </div>



            </tab>
            <tab name="Sharing with other gyms">
                <column-selector  v-model="comp.gyms" :available="availableGyms"  label-field="name" />
                
            </tab>
            <tab name="Judges">
                <judges-admin :id="comp.id" />
            </tab>
            <tab name="Other settings">
                <div class="row">
                    <div class="col-12">
                        <label class="control-label " for="register_form_text">Qualifying starting list</label>
                        <textarea cols="80" rows="5" class="form-control" id="qualstartorder" name="qualstartorder" vmodel="comp.qualstartorder"></textarea>
                    </div>
                </div>
            </tab>
        </tabs>
        <div class="flex flex-end">
            <button class="btn btn-block btn-success" @click="saveSettings">Save settings</button>
        </div>


    </div>
    <div v-else>Loading...</div>

</template>
<script setup>

import { computed, ref, onMounted } from 'vue'
import { Tabs, Tab } from 'vue3-tabs-component'
import { DatePicker } from 'v-calendar'
import Checkbox from '@/components/MaterialCheckbox.vue'
import JudgesAdmin from '@/components/competitions/JudgesAdmin.vue'
import ShowCompetitionTypeTexts from '@/components/competitions/ShowCompetitionTexts.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import store from '../../store/store'
import { useToast } from "vue-toastification"
import ColumnSelector from '@/components/ColumnSelector.vue'

const availableGyms = computed(() => store.state.gyms.map((gym) => {
    return { id: gym.id, name: gym.name, gym: gym }
}))
const onRuleTemplateSelected = (data) => {
    computed.pointsharing = data
}
const props = defineProps({ 'competition': Object })
const paymentServices = computed(() => store.state.basic.paymentservices)
const gym = computed(() => store.state.basic.gym)
const getPaymentServices = computed(() => {
    return [{ id: null, 'label': 'Choose a payment service' }, ...paymentServices.value.map(p => ({ id: p.id, label: p.specialname }))]
})
const toast = useToast()
const comp = ref(null)
const competitionTypes = [
    { id: 'null', label: 'Select a competition type' },
    { id: 'variable_points', label: 'Variable points defined by ascents (AKA Koivukylä)', rules: { points_per_route: 100 } },
    { id: 'traditional', label: 'Traditional, tops and zones' },
    { id: 'flashcomp', label: 'Flash comp, different points depending on tries', rules: { 1: 50, 2: 30, 3: 20, other: 10 } },
    { id: 'sport', label: 'Sport climbing (IFSC)', },
    { id: 'sportbonus', label: 'Sport climbing (Tops + bonus holds)', rules: { 1: 1, 2: 4, 3: 6 } },
    { id: 'sport', label: '', rules: { 1: 50, 2: 30, 3: 20, other: 10 } },
    { id: 'ifsc_2018_bouldering', label: 'IFSC Bouldering 2018 rules', },
]
const saveSettings = () => {
    const payload = { competition: JSON.parse(JSON.stringify(comp.value)), 'compid': comp.value.id }
    store.dispatch('competitions/saveCompetition', payload)
    .then(ret => {
      console.log(ret)
        toast("Settings saved")
    })
}
const errorMessage = computed(() => {
    if (!comp.value.compdate) {
        return 'Date is required.'
    }
    return ''
})

onMounted(() => {
    comp.value = { ...props.competition }
})


</script>
