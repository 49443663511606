<template>

  <div class="card">
    <div class="card-body p-3 pt-2">
      <div class="icon icon-lg icon-shape shadow text-center border-radius-xl mt-n3 position-absolute"
        :class="`bg-gradient-${icon.background} shadow-${icon.background}`"
        >
        <i class="material-icons opacity-10" 
          :class="icon.color"
          aria-hidden="true">{{ icon.name }}</i>
      </div>
      <div class="text-end pt-0">
        <p class="text-sm mb-0 text-capitalize">{{ title.text }}</p>
        <div class="d-flex flex-row justify-content-end ">
          <div v-if="link != null && link.text != null" class="mt-1 pr-1 mx-2 fs-6 "><small><a href="#" class="text-primary" @click="$emit('click')" >{{ link.text }}</a></small></div>
          <h4 class="mb-0">{{ title.value }}</h4>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "StatisticsCard",
  props: {
    title: {
      type: Object,
      required: true,
      text: String,
      value: [Number, String],
      default: () => ({
        text: "",
      }),
    },
    detail: {
      type: String,
      default: "",
    },
    link : {
      type: Object,
      text: String,
      default: () => ({
        text: ""
      }),
    },
    icon: {
      type: Object,
      required: true,
      name: String,
      color: String,
      background: String,
      default: () => ({
        color: "text-white",
        background: "success",
      }),
    },
  },
  emits : ['click'],
};
</script>
