<template>
    <div class="container">
        <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
            <h2>Gym Settings</h2>
            <p>
                Here you can find gym settings. There's not super much to adjust, but
                enough to make you feel at home.
            </p>

            <h3>Gym Basic information</h3>

            <h5>Gym location
            </h5>
                <div class="fs-6 text-muted"> You can drag the marker or click a location to update the gym coordinates.  </div>
                <div class="fs-6 text-muted">You can also search for the address, and click where the gym is to update the coordinates.</div>
                <strong>Remember to save the gym info after changing the coordinates.</strong>
            <div id="map"></div>
            <form v-if="settings != null" role="form">

                <div class="row">
                    <div class="col-6">
                        <label>Latitude </label>
                        <input type="text" class="form-control" v-model="settings.latitude" />
                    </div>
                    <div class="col-6">
                        <label>Longitude</label>
                        <input type="text" class="form-control" v-model="settings.longitude" />
                    </div>
                    <div class="col-4">
                        <label>Timezone for the gym</label>
                        <input type="text" class="form-control" v-model="settings.timezone" />
                    </div>
                    <div class="col-4">
                        <label>City</label>
                        <input type="text" class="form-control" v-model="settings.city" />
                    </div>
                    <div class="col-4">
                        <label>Country</label>
                        <input type="text" class="form-control" v-model="settings.country" />
                    </div>
                    <div class="col-4">
                        <label>Continent</label>
                        <input type="text" class="form-control" v-model="settings.continent" />
                    </div>
                    <div class="col-6">
                        <label>Contact information (you can use HTML)</label>
                        <textarea cols="20" rows="5" type="text" class="form-control" v-model="settings.descr" />
                    </div>
                </div>



                <h3>Route Settings</h3>
                <div class="row">
                    <div class="col-md-4">
                        <label for="startdefault" class="control-label">Problem/Route starting default</label>
                        <select name="startdefault" class="form-control" id="startdefault" v-model="settings.startdefault">
                            <option value="">Not selected</option>
                            <option value="Two holds">Two holds</option>
                            <option value="One hold">One hold</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="enddefault" class="control-label">Problem/Route ending default</label>
                        <select name="enddefault" class="form-control" id="enddefault" v-model="settings.enddefault">
                            <option value="">Not selected</option>
                            <option value="Top edge">Top edge</option>
                            <option value="Last hold">Last hold</option>
                            <option value="Top-out">Top-out</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label for="gradepreference" class="control-label">Boulder grading system</label>
                        <select name="gradepreference" class="form-control" id="gradepreference" v-model="settings.gradepreference">
                            <option value="vscale">vscale</option>
                            <option value="south_africa">south_africa</option>
                            <option value="yds">yds</option>
                            <option value="uiaa">uiaa</option>
                            <option value="australian">australian</option>
                            <option value="font">font</option>
                            <option value="color133">color133</option>
                            <option value="modifier_tries">modifier_tries</option>

                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="gradepreference_sport" class="control-label">Sport grading system</label>
                        <select name="gradepreference_sport" class="form-control" id="gradepreference_sport" v-model="settings.gradepreference_sport">
                            <option value="vscale">vscale</option>
                            <option value="south_africa">south_africa</option>
                            <option value="yds">yds</option>
                            <option value="uiaa">uiaa</option>
                            <option value="australian">australian</option>
                            <option value="font">font</option>
                            <option value="color133">color133</option>
                            <option value="modifier_tries">modifier_tries</option>

                        </select>
                    </div>
                </div>
                <h3>Payment Settings</h3>

                <div class="row">
                    <div class="col-md-4">
                        <label for="paytrail_account" class="control-label">Paytrail account</label>
                        <input type="text" class="form-control" id="paytrail_account" name="paytrail_account" v-model="settings.paytrail_account">
                    </div>
                    <div class="col-md-4">
                        <label for="paytrail_secret" class="control-label">Paytrail secret</label>
                        <input type="text" class="form-control" id="paytrail_secret" name="paytrail_secret" v-model="settings.paytrail_secret">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                        <br>
                        <button type="button" class="btn btn-primary" @click="onSave">Save changes</button>
                    </div>
                </div>
            </form>


        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
import { mapboxMapKey } from '@/helpers/problemator'
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from 'mapbox-gl'
import "mapbox-gl/dist/mapbox-gl.css"

let map = null
let marker = null

const store = useStore()

const toast = useToast()
const settings = computed(() => store.state.basic.gym)
watch(settings, (newValue) => {
    console.log(newValue)
    const center = [settings.value.longitude, settings.value.latitude]
    map.flyTo({ center })
    marker = new mapboxgl.Marker({
        draggable: true,
        color: "#D80739",
        }).setLngLat(center)
          .addTo(map)
          console.log(marker)
     marker.on("dragend", (e) => {
        const { lat, lng } = e.target.getLngLat()
        settings.value.latitude = lat
        settings.value.longitude = lng

     })
})

onMounted(() => {
    mapboxgl.accessToken = mapboxMapKey
    // init the map
    map = new mapboxgl.Map({
        container: 'map',
        style: "mapbox://styles/mapbox/light-v9",
        minzoom: 1.3,
        center: [24, 60],
        zoom: 15
    })
    let geocoder = new MapboxGeocoder({
        accessToken: mapboxMapKey,
        mapboxgl: mapboxgl,
        marker: false,
    });
    map.addControl(geocoder);
    map.on('click', function (e) {
        const { lat, lng } = e.lngLat
        settings.value.latitude = lat
        settings.value.longitude = lng
        const center = [lng, lat]
        marker.setLngLat(center)

    })
})



const onSave = () => {
    const payload = settings.value
    store.dispatch('saveGymSettings', payload)
        .then(ret => {
            console.log(ret)
            toast.success("Saved")
        })
        .catch(err => {
            toast.error(handleValidationErrors(err))
        })

}

</script>
<style>
#map {
    height: 40vh;
    width: 150vh;
}
</style>