<template>
  <div class="w-full bg-white p-2">
  <h2 class="mb-0 block">Latest ascents</h2>
  <small class="block">Updates every 10 secs and shows the latest 10 ascents entered</small>
  <small class="font-bold">
  Last update: {{ latestUpdate }}, {{ updatedSecondsAgo }} seconds ago.
  </small>

  <table  class="table table-striped table-bordered table-hover w-full">
    <tr>
    <th>Tick id</th>
    <th>tag</th>
    <th>num</th>
    <th>Name</th>
    <th>Tries top</th>
    <th>Tries bonus</th>
    <th>Sport points</th>
    <th>Time</th>
    <th>Actions</th>
    </tr>
    <transition-group tag="tbody" name="table-row">
    <tr v-for="tick in ticks" :key="tick.id">
    <td>{{ tick.id }}</td>
    <td>{{ right(tick.tag,4) }}</td>
    <td class="font-bold">{{ tick.num }}</td>
    <td>{{ tick.etunimi }} {{ tick.sukunimi }}</td>
    <td>{{ tick.tries}}</td>
    <td>{{ tick.tries_bonus}}</td>
    <td>{{ tick.sport_points}}</td>
    <td>{{ right(tick.tstamp,8) }}</td>
    <td>
      <button @click="() => removeAscent(tick.id)" class="btn btn-primary px-1 py-1" type="button">del</button>
    
    </td>
    </tr>
    </transition-group>
  </table>
  </div>
</template>
<script setup>
import {  ref, onBeforeUnmount, computed } from 'vue'
import store from '../../store/store'
const props = defineProps({ compid : String})
const ticks = computed(() => store.state.competitions.latestAscents)
import { right } from '../../helpers/strings.js'
const latestUpdate = ref(null)
const updatedSecondsAgo = ref(0)
const updateLastUpdateAgo = () => {
  updatedSecondsAgo.value++

  }
setInterval(updateLastUpdateAgo,1200)
const removeAscent = (tickid) => {
  if (confirm("Are you sure?")) {
    const payload = {
      compid: props.compid,
      tickid: tickid,
    }
    store.dispatch("competitions/removeAscent", payload)
  }
}

const  fetchAscents = () => {
  // Find latest n ticks for the comp
  store.dispatch("competitions/getLatestAscents", { compid: props.compid })
  .then(() => {
    const d = new Date()
    latestUpdate.value = d
    updatedSecondsAgo.value = 0
    })

  }
const interval = ref(setInterval( fetchAscents,10000))
fetchAscents()

onBeforeUnmount(() => {
    clearInterval(interval.value)
  })  

</script>
<style>
.tbody-enter-active,
.tbody-leave-active {
  transition: all 0.5s ease;
}
.tbody-enter-from,
.tbody-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
