<template>
    <div class="container">

        <modal v-model="showCopypasteDialog" title="Paste contenders" size="lg" ok-title="Import" @ok="onImportContenderCopyPaste">

            <p>Copy the data from a spreadsheet and paste it here. The data will be parsed and you can then add the contenders to the competition.</p>
            <div class="row">
                <div class="col-6">
                    <strong>Fields in use (in this order)</strong>
                    <draggable class="list-group" :list="fieldsInUse" group="people" @change="log" itemKey="name">
                        <template #item="{ element }">
                            <div class="list-group-item">{{ element.name }}</div>
                        </template>
                    </draggable>
                </div>

                <div class="col-6">
                    <strong>All/Unused fields</strong>
                    <draggable class="list-group" :list="availableFields" group="people" @change="log" itemKey="name">
                        <template #item="{ element }">
                            <div class="list-group-item">{{ element.name }} </div>
                        </template>
                    </draggable>
                </div>
            </div>

            <textarea class="border w-full" cols="80" rows="10" v-model="copypasteData"></textarea>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="importCSV.auto_create_email" />
                <label class="form-check-label" for="newcat">Auto-create emails</label>
                <br />
                <small>The contenders are identified by their emails, if you don't have them, tick this and let Problemator create an unique (but ugly) email address for them</small>
            </div>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="importCSV.preview" />
                <label class="form-check-label " for="newcat">Preview?</label>
                <br />
                <small>Use this to test your paste from the spreasheet</small>
                <pre class="">
                {{ pasteResult }}
                </pre>
            </div>
        </modal>
        <modal v-model="showEmailsDialog" title="Contender emails" ok-only size="lg" :close-on-ok="true">
            <copy-paste>
                <div class="form-control">
                    {{contenderEmails}}
                </div>
            </copy-paste>

        </modal>
        <modal v-model="showAddContenderCSVDialog" title="Add contenders via CSV" ok-title="Import" size="md" @ok="onImportContenderCSV">

            <div class="well well-sm">Create the file in Excel, Numbers or Google Docs. Then save it as CSV.</div>

            <div class="row">
                <div class="col-12 text-sm my-2">

                    <p>The birthday is in in format yyyy-mm-dd, eg. 2006-01-14</p>
                    Please note that if the list is empty, you must drag the items to the
                    title text (All fields or Fields in use)
                </div>

                <div class="col-12 my-2">
                    <label class="control-label">Select file</label><br />
                    <DropZone class="drop-area" @files-dropped="addFiles" #default="{ dropZoneActive }">
                        <label for="file-input">
                            <span v-if="dropZoneActive">
                                <span>Drop Them Here</span>
                                <span class="smaller">to add them</span>
                            </span>
                            <span v-else>
                                <span>Drag Your Files Here</span>
                                <span class="smaller">
                                    or <strong><em>click here</em></strong> to select files
                                </span>
                            </span>

                            <input type="file" id="file-input" multiple @change="onInputChange" />
                        </label>
                        <div v-if="importCSV.file">
                            {{ importCSV.file.name }}
                        </div>
                    </DropZone>

                </div>
                <div class="col-6">
                    <strong>Fields in use (in this order)</strong>
                    <draggable class="list-group" :list="fieldsInUse" group="people" @change="log" itemKey="name">
                        <template #item="{ element }">
                            <div class="list-group-item">{{ element.name }}</div>
                        </template>
                    </draggable>
                </div>

                <div class="col-6">
                    <strong>All fields</strong>
                    <draggable class="list-group" :list="availableFields" group="people" @change="log" itemKey="name">
                        <template #item="{ element }">
                            <div class="list-group-item">{{ element.name }} </div>
                        </template>
                    </draggable>
                </div>




                <div class="form-group">
                    <label class="control-label " for="newcat">Field Separator</label>

                    <input type="text" class="form-control" v-model="importCSV.separator" />

                </div>

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="importCSV.auto_create_email" />
                    <label class="form-check-label" for="newcat">Auto-create emails</label>
                    <br />
                    <small>The contenders are identified by their emails, if you don't have them, tick this and let Problemator create an unique (but ugly) email address for them</small>
                </div>


                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="importCSV.preview" />
                    <label class="form-check-label " for="newcat">Preview?</label>
                    <br />
                    <small>Use this to test your CSV</small>
                </div>
            </div>
            <pre class="">
            {{ pasteResult }}
            </pre>

        </modal>

        <modal v-model="showDialog['editcontender']" title="Add/Edit Climber" ok-title="Save" size="lg" @ok="onSaveContender">
            <ContenderEditor :id="selectedID" ref="contenderEditor" />
        </modal>

        <modal v-model="showDialog['editcontenderincomp']" title="Edit contender extra info" ok-title="Save" size="lg" @ok="onSaveContenderExtraInfo">
            <contender-extra-info-editor :series="seriesDropdown" ref="contenderExtraInfoEditor" v-model="extra" />
            <template #footer="{ok, close}">
                <button type="button" class="btn btn-secondary" @click="close">Close</button>
                <button type="button" class="btn btn-primary" @click="ok">Save</button>
            </template>
        </modal>
        <modal v-model="showDialog['showpaymentlink']" title="Payment link" ok-title="OK" size="lg" @ok="showDialog['showpaymentlink']=false">
            <a :href="paymentLink">Open payment link</a><br />
            <a :href="paymentLink">{{ paymentLink }}</a>
        </modal>

        <modal v-model="showEditDialog" title="Circuit editor" ok-title="Save" @ok="onSaveCircuit">
            <circuit-editor :id="selectedCircuit" ref="refCircuitEditor" />
        </modal>

        <h1 class="">Competition {{ route.params.id }} - {{ competition?.name }}</h1>

        <div v-if="competition !== null">
            <div class="flex flex-col">
                <ul class="flex flex-wrap md:flex-row gap-4 bg-white px-4 py-3 shadow-md border border-2 rounded-md border-gray-800">
                    <li class="" :class="getClasses('pointentry')"><a href="#" @click.prevent="activeTab='pointentry';">Point entry </a></li>
                    <li class="" :class="getClasses('contenders')"><a href="#" @click.prevent="activeTab='contenders';">Contenders <small class="top-0 start-100  badge rounded-pill bg-danger">{{ competition.paidregistrations.length}}</small></a></li>
                    <li class="" :class="getClasses('unpaid_contenders')"><a href="#" @click.prevent="activeTab='unpaid_contenders';">Unpaid Contenders</a> <small class="top-0 start-100  badge rounded-pill bg-danger">{{ competition.unpaidregistrations.length}}</small></li>
                    <li class="" :class="getClasses('routes')"><a href="#" @click.prevent="activeTab='routes';">Routes</a> <small class="top-0 start-100  badge rounded-pill bg-success">{{ competition.problems.length}}</small></li>
                    <li class="" :class="getClasses('categories')"><a href="#" @click.prevent="activeTab='categories';">Categories</a> <small class="top-0 start-100  badge rounded-pill bg-info">{{ competition.categories.length}}</small></li>
                    <li class="" :class="getClasses('lottery')"><a href="#" @click.prevent="activeTab='lottery';">Prize lottery</a> </li>
                    <li class="" :class="getClasses('misc')"><a href="#" @click.prevent="activeTab='misc';">Misc</a> </li>
                    <li class="" :class="getClasses('results')"><a href="#" @click.prevent="activeTab='results';">Results </a></li>
                    <li class="" :class="getClasses('settings')"><a href="#" @click.prevent="activeTab='settings';">Settings </a></li>
                    <li class="" :class="getClasses('help')"><a href="#" @click.prevent="activeTab='help';">Help </a></li>
                </ul>
            </div>
            <tab title="Point entry" :active="activeTab == 'pointentry'">
                <div class="flex">
                    <div class="flex-1 flex flex-col">
                        <point-entry :competition="competition" />
                    </div >
                    <div class="flex-2 flex flex-col">
                        <CurrentlyClimbing :compid="competition.id"/>
                        <latest-points-entered class="mt-5" :compid="competition.id" />
                    </div>
                </div>
            </tab>
            <tab title="Contenders" :active="activeTab == 'contenders'">
                <div class="my-2">
                    <a href="#" @click.prevent="showAddNewContender" class=" py-2 px-4 text-black rounded-lg bg-blue-400">Add new Problemator climber...</a>
                </div>
                <h5 class="my-0 p-0">Contender related </h5>
                <a href="#" @click.prevent="addContendersCSV" class="btn btn-outline text-info">Add contenders via CSV...</a>
                <a href="#" @click.prevent="showCopypasteDialog=true" class="btn btn-outline text-info">Copy/Paste contenders from a spreadsheet...</a>
                <a :href="webendpoint+'comps/'+route.params.id+'/contenders/list'" class="btn btn-outline text-info"> Show plain contender list </a>
                <a :href="webendpoint+'comps/'+route.params.id+'/contenders/list?format=csv'" class="btn btn-outline text-info"><i class="fa fa-file"></i> Download contenders as CSV</a>
                <a :href="webendpoint+'comps/'+route.params.id+'/contenders/list?format=excel'" class="btn btn-outline text-info"><i class="fa fa-file"></i> Download contenders as Excel</a>
                <a :href="webendpoint+'comps/'+route.params.id+'/contenders/list?format=json'" class="btn btn-outline text-info"><i class="fa fa-file"></i> Get contenders as JSON</a>
                <a href="#" @click.prevent="showEmails" class="btn btn-outline text-info"><i class="fa fa-file"></i> List contender emails</a>
                <br />
                <h5 class="my-0">Starting lists </h5>
                <div v-if="competition.tyyppi.match(/boulder/i)">
                    Bouldering
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/boulder_qualifying'" class="btn btn-outline text-info">Qualifying (single group)</a>
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/boulder_qualifyinga'" class="btn btn-outline text-info">Qualifying Group A</a>
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/boulder_qualifyingb'" class="btn btn-outline text-info">Qualifying Group B</a>
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/boulder_semifinal'" class="btn btn-outline text-info">Semifinal</a>
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/boulder_final'" class="btn btn-outline text-info">Final</a>
                </div>
                <div v-else>
                    <a :href="webendpoint + 'comps/' + route.params.id + '/create_start_list/qualifying1'" class="btn btn-outline text-info">Qualifying 1</a>
                    <a :href="webendpoint + 'comps/' + route.params.id + '/create_start_list/qualifying2'" class="btn btn-outline text-info">Qualifying 2</a>
                    <a :href="webendpoint + 'comps/' + route.params.id + '/create_start_list/semifinal'" class="btn btn-outline text-info">Semifinal</a>
                    <a :href="webendpoint+'comps/'+route.params.id+'/create_start_list/final'" class="btn btn-outline text-info">Final</a>
                </div>




                <list-contenders :competition="competition" type="paidregistrations" @action="onAction" />
            </tab>
            <tab title="Unpaid Contenders" :active="activeTab == 'unpaid_contenders'">
                <template #badge></template>
                <div class="my-2">
                    <a href="#" @click.prevent="showAddNewContender" class=" py-2 px-4 text-black rounded-lg bg-blue-400">Add new Problemator climber...</a>
                </div>
                <list-contenders :competition="competition" type="unpaidregistrations" @action="onAction" />
            </tab>
            <tab title="Routes" :active="activeTab == 'routes'">
                <problem-list :competition="competition" />
            </tab>
            <tab title="Categories" :active="activeTab == 'categories'">
                <category-list :categories="categories" :competition="competition" />
            </tab>
            <tab title="Prize lottery" :active="activeTab == 'lottery'">
                <lottery :competition="competition" />
            </tab>
            <tab title="Misc" :active="activeTab == 'misc'">
                <div class="w-4/5">
                    <h5 class="my-0">Misc. functions</h5>
                    <a class="btn btn-primary" :href="webendpoint+'countdowntimer/'" target="_timer">Competition Timer/Clock</a><br />
                    <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/print_entry_form/'" target="_print">Print out form for points entry</a><br />
                    <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/register/'" target="_print">Registration form</a>
                    <br />
                    <button class="btn btn-danger" @click.prevent="askRemoveTicks">Remove all ticks</button>
                    This is dangerous. It will remove ALL the ticks during the competition.<br />
                </div>

            </tab>
            <tab title="Results" :active="activeTab == 'results'">
                <h2>Result lists</h2>

                <show-admin-results />


                <br clear="all" />
                <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/results'" target="_print">Results</a> (based on comp type, this is the one you usually would use)
                <br />
                <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/results/media'" target="_media">Results for MEDIA (json)</a> This is for the streaming purposes
                <p>For complexity's sake we show all the result variants so you can choose the best fit.</p>
                <ul>
                    <li>
                        <hr />
                    </li>
                    <li> <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/results/variable_points'" target="_print">Variable points</a> (AKA Koivukylä)</li>
                    <li> <a class="btn btn-primary" :href="webendpoint+'comps/'+competition.id+'/results/boulder_tops_and_bonuses'" target="_print">Boulder - olympic points (+ tops and bonuses)</a> </li>
                </ul>
                <p>
                    You can add some additional fields to the result lists according to your taste.
                    By default the following fields are shown: <strong>standing, name and results</strong>
                </p>
                You can check one or more from the following fields:

                <ul class="my-1">
                    <li v-for="(extraField, idx) in extraFields" :key="extraField.id">
                        <checkbox v-model="extraFields[idx].selected">{{ extraField.label }}</checkbox>
                    </li>
                </ul>
                <button class="btn btn-sm btn-success" @click="saveExtraFields">Save extrafields</button>

                <br />

            </tab>
            <tab title="Settings" :active="activeTab == 'settings'">
                <competition-editor :competition="competition" />
            </tab>
            <tab title="Help" :active="activeTab == 'help'">
                <p>I'm a disabled tab!</p>
            </tab>
        </div>

    </div><!-- end of row -->
</template>

<script setup>
import { useRoute } from 'vue-router'
import { watch, computed, onMounted, ref } from 'vue'
import PointEntry from '@/components/competitions/PointEntry.vue'
import ListContenders from '@/components/competitions/ListContenders.vue'
import LatestPointsEntered from '@/components/competitions/LatestPointsEntered.vue'
import CurrentlyClimbing from '@/components/competitions/CurrentlyClimbing.vue'
// eslint-disable-next-line
import ContenderEditor from '@/components/competitions/ContenderEditor.vue'
import ShowAdminResults from '@/components/competitions/ShowAdminResults.vue'
import CompetitionEditor from '@/components/competitions/CompetitionSettings.vue'
import ProblemList from '@/components/competitions/ProblemList.vue'
import CategoryList from '@/components/competitions/CategoryList.vue'
import Lottery from '@/components/competitions/Lottery.vue'
import ContenderExtraInfoEditor, { extraSkeleton } from '@/components/competitions/ContenderExtraInfoEditor.vue'
import Modal from '@/components/Modal.vue'
import store from '../../store/store'
import Tab from '@/components/bs5/Tab.vue'
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '@/helpers/errors.js'
import { webendpoint, endpoint } from '@/helpers/problemator.js'
import Checkbox from '../../components/MaterialCheckbox.vue'
import CopyPaste from '../../components/CopyPaste.vue'
import draggable from "vuedraggable"
import DropZone from '@/components/upload/DropZone.vue'
import axios from 'axios'

const askRemoveTicks = () => {
    if (confirm("Do you understand, that this will remove ALL the ascents climbers have made. This is IRREVERSIBLE")) {
        store.dispatch("competitions/deleteAllTicks", { compid: route.params.id })
    }
}
const buttonBusy = ref(false)
const copypasteData = ref(null)
const pasteResult = ref(null)
const showCopypasteDialog = ref(false)
const showEmailsDialog = ref(false)
const showEmails = () => {
    showEmailsDialog.value = true
}

const showAddNewContender = () => {
    selectedID.value = '*new*'
    showDialog.value['editcontender'] = true
}

const onInputChange = (e) => {
    addFiles(e.target.files)
    e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
}
const contenderEmails = computed(() => {
    return competition.value.paidregistrations.map(c => c.email).join(",")
})

const onImportContenderCopyPaste = async () => {
    buttonBusy.value = true
    importCSV.value.data = copypasteData.value
    importCSV.value.separator =  '\t'

    let formData = new FormData()
    Object.keys(importCSV.value).forEach((key) => {
        formData.append(key, importCSV.value[key])
    })
    formData.append('fields', JSON.stringify(fieldsInUse.value))
    // Upload 
    const url = endpoint + "competitions/" + route.params.id + "/contenders/csv_import"
    axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(ret => ret.data)
        .then((ret) => {
            console.log(ret)
            pasteResult.value = ret
            buttonBusy.value = false
        })
        .catch(err => {
            toast.error(handleValidationErrors(err))
            buttonBusy.value = false
        })
}

const onImportContenderCSV = async () => {
    let formData = new FormData()
    console.log(importCSV.value)
    Object.keys(importCSV.value).forEach((key) => {
        formData.append(key, importCSV.value[key])
    })
    formData.append('fields', JSON.stringify(fieldsInUse.value))
    // Upload 
    const url = endpoint + "competitions/" + route.params.id + "/contenders/csv_import"
    axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(ret => ret.data)
        .then((ret) => {
            console.log(ret)
            pasteResult.value = ret
            buttonBusy.value = false
        })
        .catch(err => {
            toast.error(handleValidationErrors(err))
            buttonBusy.value = false
        })

}

const addContendersCSV = () => {
    showAddContenderCSVDialog.value = true
}
/*
const fileExists = (otherId)  => {
        return importCSV.value.files.some(({ id }) => id === otherId)
    }
    */

const addFiles = (newFiles) => {
    let [firstFile, ...rest] = newFiles
    console.log(rest)
    importCSV.value.file = firstFile
}


const fieldsInUse = ref([
    { name: "firstname", id: 1 },
    { name: "lastname", id: 2 },
    { name: "email", id: 3 },
    { name: "team", id: 4 },
    { name: "seriename", id: 5 },
    { name: "country", id: 6 },
    { name: "birthdate", id: 7 },
    { name: "height", id: 8 },
    { name: "apeindex", id: 9 },
])
const availableFields = ref([
])
const importCSV = ref({
    data : null,
    files: [],
    file: null,
    compid: null,
    separator: ';',
    preview: true,
    auto_create_email: false,
})

const showAddContenderCSVDialog = ref(false)
const compid = ref(null)
const showDialog = ref({})
const contenderEditor = ref(null)
const selectedID = ref(null)
const toast = useToast()
const contenderType = ref(null)
const activeTab = ref('pointentry')
const extraFields = ref([
    { id: 'id', label: 'Contender id', selected: false },
    { id: 'tshirtnumber', type: "pivot", label: 'T-Shirt number', selected: false },
    { id: 'startposition', type: "pivot", label: 'Start position', selected: false },
    { id: 'lastroundposition', type: "pivot", label: 'Prev. round position', selected: false },
    { id: 'startgroup', type: "pivot", label: 'Start group', selected: false },
    { id: 'lastroundresults', type: "pivot", label: 'Prev. round results', selected: false },
    { id: 'team', label: 'Team', selected: false },
    { id: 'maa', label: 'Country', selected: false },
    { id: 'birthday', label: 'Birthday', selected: false },
    { id: 'height', label: 'Height', selected: false },
    { id: 'apeindex', label: 'Ape index', selected: false },
])
const saveExtraFields = () => {
    const payload = {
        compid: competition.value.id,
        competition: { 'results_extrafields': { ...extraFields.value } }
    }

    store.dispatch('competitions/saveCompetition', payload)
        .then(ret => {
            console.log(ret)

        })
}
const seriesDropdown = computed(() => {
    const ret =  competition.value.categories.map(c => {
        const label = c.nimi + " price:" + c.pivot.price + "€/" + (c.pivot.memberprice ?? 0) + "€, max. part.: " + c.pivot.maxparticipants
        return { id: c.id, label }
    })
    return ret
})


const getClasses = (tab) => {
    return {
        'text-red-500': activeTab.value == tab,
        'underline': activeTab.value == tab,
    }

}
const extra = ref({ ...extraSkeleton })

const paymentLink = computed(() => {
    return 'https://www.problemator.fi/comps/' + compid.value + "/contender/" + selectedID.value + "/payment"
})

const onAction = (payload) => {
    // Inject compid just in case
    payload = { ...payload, ['compid']: compid.value }
    // One can do some preparations before showing the dialog ( or not show the dialog at all)
    switch (payload.action) {
        case 'movetounpaid': {
            store.dispatch('competitions/moveToUnpaidList', payload)
            toast.success("Moved to Unpaid-list")
        }
            break
        case 'movetopaid': {
            store.dispatch('competitions/moveToPaidList', payload)
            toast.success("Moved to Paid-list")
        }
            break
        case 'removecontender': {
            store.dispatch('competitions/removeContender', payload)
            .then((json) => {
              toast.success(json.message)
            })
            .catch((err) => {
              toast.error(err.response.data.message)
            })
        }
            break
        case 'editcontenderincomp': {
            if (payload.type == 'paidregistrations') {
                extra.value = competition.value.paidregistrations.find(x => payload.id == x.id).pivot
            } else {
                extra.value = competition.value.unpaidregistrations.find(x => payload.id == x.id).pivot
            }
        }
            break

        case 'sendpaymentemail': {
            let participation_id = null
            if (payload.type == 'paidregistrations') {
                participation_id= competition.value.paidregistrations.find(x => payload.id == x.id).pivot.id
            } else {
                participation_id = competition.value.unpaidregistrations.find(x => payload.id == x.id).pivot.id
            }
            store.dispatch("competitions/sendPaymentEmail", { compid: compid.value, participation_id , type: payload.type})
                .then(ret => {
                    selectedID.value = null
                    toast.success(ret.message)
                })
                .catch(err => {
                    toast.error(handleValidationErrors(err))
                })
        }
            break

        default: {
            console.log("Did nothing extra for the action", payload.action)
        }


    }
    showDialog.value[payload.action] = true
    contenderType.value = payload.type
    selectedID.value = payload.id

}
const route = useRoute()
const competition = computed(() => store.state.competitions.competition)
watch(competition, (newValue) => {
    if (newValue != null) {
        try {
        extraFields.value = extraFields.value.map(item => {
            const newExtraFields = newValue.results_extrafields
            let newItem = null
            if ((newItem = Object.keys(newExtraFields).find(i => i.id === item.id)) != null) {
                const newVal = newItem.selected
                item.selected = newVal
            }
            return item
        })
        } catch (e) {
            // Extrafields are in wrong format.. Just use the original ones
            
        }
    }
})
onMounted(() => {
    store.dispatch('competitions/getCompetition', route.params.id)
    store.dispatch('competitions/getResults', route.params.id)

    compid.value = route.params.id
    importCSV.value.compid = route.params.id
})


const onSaveContenderExtraInfo = () => {

    const payload = { ...extra.value, target_list_paid: (contenderType.value == 'paidregistrations') }
    store.dispatch("competitions/saveContenderExtraInfo", payload)
        .then(ret => {
            console.log(ret)
            selectedID.value = null
            showDialog.value['editcontenderincomp'] = false
            toast.success("Saved")
        })
        .catch(err => {
            toast.error(handleValidationErrors(err.response.data))
        })
}
const onSaveContender = () => {
    contenderEditor.value.save(contenderType.value)
        .then(ret => {
            console.log(ret)
            selectedID.value = null
            showDialog.value['editcontender'] = false
            contenderEditor.value.reset()
            toast.success("Saved")
        })
        .catch(err => {
            toast.error(handleValidationErrors(err.response.data))
        })
}
</script>
  
