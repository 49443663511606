<template>
  <div v-if="row != null">

        <p>Search and add users who are allowed to see this circuit in Problemator mobile</p>
        <ul>
        <li v-for="user in users" :key="user.id">
        {{ user.etunimi}} {{ user.sukunimi }}<a class="ms-2 mt-2 btn btn-sm btn-info py-0" href="#" @click.prevent="addToAllowed(user.id)">add</a>
        </li>
        </ul>
      <contender-search v-model="users" :url="searchURL"  multiple />
        <oma-table :rows="row.allowed_users" :cols="cols" class="table table-striped">

          <template #climber="{row}">
              {{ row.etunimi }}
              {{ row.sukunimi }}
          </template>

          <template #actions="{row}">
            <a class="ms-2 mt-1 btn btn-sm btn-danger btn-info py-0" href="#" @click.prevent="removeFromAllowed(row.id)">del</a>
          </template>
        </oma-table>

  </div>
  <div v-else>Loading...</div>
</template>
<script setup>

import ContenderSearch from '@/components/ContenderSearch.vue'
import { useStore } from 'vuex'
import {  ref, computed} from 'vue'
import OmaTable from '@/components/OmaTable.vue'
import { endpoint } from '@/helpers/problemator'
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '../../helpers/errors'

const toast = useToast()
const users = ref([])
const searchURL = computed(() => {
  return endpoint + "climber/search"
})
const removeFromAllowed = (id) => {
  const payload = {...row.value, ['allowed_users'] : row.value.allowed_users.filter(x => x.id != id)}
  store.dispatch('circuits/save',payload)
  .then(ret => {
    toast.success(ret.message)
  })
  .catch(err => toast.error(handleValidationErrors(err)))
}

const addToAllowed = () => {
  const payload = {...row.value, ['allowed_users'] : users.value}
  store.dispatch('circuits/save',payload)
  .then(ret => {
    users.value = []
    toast.success(ret.message)
  })
  .catch(err => toast.error(handleValidationErrors(err)))

}
const cols = [
  { id: 'id', sortable : true},
  { id: 'climber', sortable : true},
  { id: 'actions', },
]
const store = useStore()
defineProps({ 'id': String })
const row = computed(() => store.state.circuits.row)

</script>


