<template>
  <div class="container-fluid" v-if="user != null">
    <div
      class="page-header min-height-300 border-radius-xl mt-4"
      style="
        background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
      "
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <!--
            <img
              src="../../../assets/img/bruce-mars.jpg"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
            -->
            <i class="fa fa-user-ninja fa-2x text-info bg-gradient-blue"></i>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ user.etunimi }} <strong v-if="user.nick != ''">'{{ user.nick }}'</strong> {{ user.sukunimi}}</h5>
            <p class="mb-0 font-weight-normal text-sm">Routesetter 
            <span v-if="user.ismanager"> / Manager</span>
            <span v-if="user.globalmanager"> / Superuser</span>
            </p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          
        </div>
      </div>
      <div class="row">
        <div class="mt-3 row">
        <!--
          <div class="col-12 col-md-6 col-xl-4 position-relative">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Platform Settings</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault"
                      label-class="mb-0 text-body text-truncate w-80"
                      checked
                      >Email me when someone sends feedback </material-switch
                    >
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault1"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault1"
                      label-class="mb-0 text-body text-truncate w-80"
                      >Email me when someone likes my problem</material-switch
                    >
                  </li>

                  <li class="px-0 border-0 list-group-item">
                    <material-switch
                      id="flexSwitchCheckDefault2"
                      class="ps-0 ms-0"
                      name="flexSwitchCheckDefault2"
                      label-class="mb-0 text-body text-truncate w-80"
                      checked
                      >Email me when someone dislikes my problem</material-switch
                    >
                  </li>
                </ul>
                
                
              </div>
            </div>
            <hr class="vertical dark" />
          </div>
              -->
            <div class="col-12 col-md-6 col-xl-4 mt-md-0 mt-4 position-relative">
              <profile-info-card
                title="Routesetter Introduction"
                :description="user.bio ?? 'Please introduce yourself to the climbers'"
                :info="{
                  mobile: user.puhelin,
                  email: user.email,
                }"
                :social="[
                  {
                    link: 'https://www.facebook.com/CreativeTim/',
                    icon: faFacebook,
                  },
                  {
                    link: 'https://twitter.com/creativetim',
                    icon: faTwitter,
                  },
                  {
                    link: 'https://www.instagram.com/creativetimofficial/',
                    icon: faInstagram,
                  },
                ]"
                :action="{
                  route: 'javascript:;',
                  tooltip: 'Edit Profile',
                }"
              />
              <hr class="vertical dark" />
            </div>
            <div class="mt-4 col-12 col-xl-4 mt-xl-0">
            <!--
              <div class="card card-plain h-100">
                <div class="p-3 pb-0 card-header">
                  <h6 class="mb-0">Conversations</h6>
                </div>
                <div class="p-3 card-body">
                  <ul class="list-group">
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <material-avatar
                        class="me-3"
                        :img="sophie"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Sophie B.</h6>
                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                        >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <material-avatar
                        class="me-3"
                        :img="marie"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Anne Marie</h6>
                        <p class="mb-0 text-xs">Awesome work, can you..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                        >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <material-avatar
                        class="me-3"
                        :img="ivana"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Ivanna</h6>
                        <p class="mb-0 text-xs">About files I can..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                        >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                    >
                      <material-avatar
                        class="me-3"
                        :img="peterson"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Peterson</h6>
                        <p class="mb-0 text-xs">Have a great afternoon..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                        >Reply</a
                      >
                    </li>
                    <li
                      class="px-0 border-0 list-group-item d-flex align-items-center"
                    >
                      <material-avatar
                        class="me-3"
                        :img="nick"
                        alt="kal"
                        border-radius="lg"
                        shadow="regular"
                      />
                      <div
                        class="d-flex align-items-start flex-column justify-content-center"
                      >
                        <h6 class="mb-0 text-sm">Nick Daniel</h6>
                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                      </div>
                      <a
                        class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                        href="javascript:;"
                        >Reply</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              -->
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="mb-5 ps-3">
                <h6 class="mb-1">My problems / routes</h6>
                <p class="text-sm">Here's a list of your routes</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script setup>

  import ProfileInfoCard from "./components/ProfileInfoCard.vue";
  //import DefaultProjectCard from "./components/DefaultProjectCard.vue";
  //import MaterialSwitch from "@/components/MaterialSwitch.vue";
/*
import MaterialAvatar from "../../../components/MaterialAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "../../../assets/img/team-1.jpg";
import team2 from "../../../assets/img/team-2.jpg";
import team3 from "../../../assets/img/team-3.jpg";
import team4 from "../../../assets/img/team-4.jpg";
*/

import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import {  computed, onMounted,onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const user = computed(() => store.state.basic.user )

onMounted(() => {
  store.commit('isAbsolute',true)
})
onBeforeUnmount(() => {
  store.commit('isAbsolute',false)
})
</script>
