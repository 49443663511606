<template>
  <form  id="editcircuitproblems" role="form" class="form container">


    <p class="">Select the circuit colours this problem belongs to. You can select as many circuits as you want.<br><br>
      It's always a good idea to keep track which routes/problems actually make a circuit. Keep the route list open in other browser tab and check
      that your circuit is "meaningful". For example red circuit should hold problems between 6a and 6c</p>
      <p>Edit the circuits from the <router-link :to="{ name: 'Circuits' }">Circuit editor</router-link></p>
    <h5>Selected problems to be attached</h5>
    <ul>
      <li><strong>{{ problem.grade.name }}</strong> {{ problem.tag?.substr(7) }}, Megahänkki, <span :style="{color : problem.colour.code}">{{ problem.colour.name }}</span> by {{ problem.author.etunimi }} {{ problem.author.sukunimi }} </li>
    </ul>
    <h5 class="header">Available circuits</h5>
    <div class="form-group">
      <div class="col-sm-12">
        <table class="availablecircuits table table-striped datatable table-condensed" width="100%">
          <thead>
            <tr>
              <th>Selected</th>
              <th> id </th>
              <th>Colour</th>
              <th>Circuit short name</th>
              <th>Circuit description</th>
              <th>Pref. min grade</th>
              <th>Pref. max grade</th>
              <th>Public</th>
            </tr>
          </thead>
          <tbody v-if="publicCircuits.length > 0">
            <tr v-for="circuit in publicCircuits" :key="circuit.id">
              <td><input type="checkbox"  v-model="selectedCircuits" :value="circuit.id" /></td>
              <td>{{ circuit.id }}</td>
              <td>
                <div class="font-bold item-media" :style="{color : circuit.color?.code }">{{ circuit.color?.name }}</div>
              </td>
              <td><span class=""> {{ circuit.circuitshortname }}</span></td>
              <td><span class="">{{ circuit. circuitname}}</span></td>
              <td><span class=""> {{ circuit.mingrade.name }}</span></td>
              <td><span class=""> {{ circuit.maxgrade.name}}</span></td>
              <td>
                <span v-if="circuit.ispublic == 1" class="fa fa-check text-success"></span>
                <span v-else class="fa fa-box "></span>
              </td>
            </tr>
          </tbody>
          <tr v-else>
           <th colspan="100">No public circuits.</th>
          </tr>
        </table>
      </div>
    </div>



  </form>
</template>
<script setup>

import { useStore } from 'vuex'
import { onMounted, computed , ref } from 'vue'

const store = useStore()
const props = defineProps({ 'id': String })

const selectedCircuits = ref([])
const problem = computed(() => store.state.problems.find(x => x.id == props.id))

// eslint-disable-next-line no-unused-vars
const save = async (type) => {
  const payload = {
    id: props.id,
    circuits : selectedCircuits.value,
  }
  return store.dispatch('circuits/saveProblemCircuits', payload )
}
onMounted(() => {
  // Find selected circuits
  selectedCircuits.value = problem.value.circuits.map(x => x.id)
})
store.dispatch('circuits/getCircuits')
const publicCircuits = computed(() => store.getters['circuits/publicCircuits'])

// Expose save method outside
defineExpose({ save })
</script>