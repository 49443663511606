<template>

  <div class="py-4 container-fluid">
        <div class="row mb-4">
          <div class="col-md-3 mt-4 col-6">
            <mini-statistics-card
              :title="{ text: 'Ascents today', value: stats.ticks ?? 0}"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-md-3 mt-4 col-6">
            <mini-statistics-card
              :title="{ text: 'Users today', value: stats.users ?? 0}"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-md-3 mt-4 col-6">
            <mini-statistics-card
              :title="{ text: 'Problem likes', value: stats.likesToday ?? 0}"
              :icon="{
                name: 'favorite',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-md-3 mt-4 col-6">
            <mini-statistics-card
              :title="{ text: 'New users (global)', value: '+'+stats.newUsers}"
              :icon="{
                name: 'person_add',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
        </div>
    

      
      
    

    <div class="row">
      <div class="col-12 col-md-4">
        <problem-type-radar-chart /> 
      </div>
      <div class="col-12 col-md-4">
        <boulder-bar-chart /> 
      </div>
      <div class="col-12 col-md-4">
        <route-bar-chart /> 
      </div>
    </div>


    

    <div class="row"></div>
  </div>
</template>



<script setup>
//import ChartHolderCard from "./components/ChartHolderCard.vue";
//import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
//import ReportsLineChart from "./components/ReportsLineChart.vue";
import MiniStatisticsCard from "@/components/StatisticsCard.vue";
import ProblemTypeRadarChart from './components/ProblemTypeRadarChart.vue'
import BoulderBarChart from './components/BoulderBarChart.vue'
import RouteBarChart from './components/RouteBarChart.vue'
import {computed } from 'vue'
import store from "@/store/store.js"
const stats = computed(() => store.state.basic.stats)
const basic = computed(() => store.state.basic)
// get basic information (grades, colours, walls, authors etc)
if (basic.value.gym == null) {
  store.dispatch('getBasic')
}
</script>
