<template>
  <div>
  
  <li class="dropdown-item bg-gray-300 m-1 border-radius-md my-2 py-1 " v-for="msg in messages" :key="msg.id">
      <div class="py-0 d-flex">
        <div class="w-full d-flex flex-column justify-content-center">
          <div class="flex flex-row justify-between">
          <div class="m-0 text-sm font-weight-normal">
              <i class="fa fa-user text-info mx-1"></i>
            <span class="font-weight-bold">Message</span> from   {{ msg.climber?.etunimi }} {{ msg.climber?.sukunimi }}
          </div>

          </div>
          <div class=" m-0 text-sm break-normal whitespace-normal	" :title="msg.message" v-html="truncate(msg.message,160,'...')"></div>
          <div class="flex">

          <span class="pt-1">Problem </span>
          <div class="mx-1 text-lg font-bold">{{ msg.problem?.grade.name }}</div>
          <span class="text-blue-500 font-bold text-lg">|</span>
          <a @click.prevent="setEditableProblem(msg.problem.id)" class="mx-1 text-lg font-bold">{{ right(msg.problem?.tag,4) }}</a>
          <p class="pt-2 mb-0 text-xs text-secondary">
            <i class="fa fa-clock me-1"></i>
             {{ dayjs(msg.timestamp).fromNow()  }}
          </p>
          </div>
        </div>
      </div>
            <div class="flex flex-row justify-between" v-if="msg.moderated_by != null">
              <div class="text-xs my-1 w-full border-radius-md bg-green-500 px-1 py-1">
                Moderated by {{ msg.moderator.etunimi }} {{ msg.moderator.sukunimi }} @{{ msg.moderated_at }}
              </div>
            </div>
            <div class="flex flex-row justify-between">
              <button v-if="msg.moderated_by == null" class="btn btn-sm btn-success py-0" @click.stop="approve(msg.id)">approve</button>
              <button v-if="msg.moderated_by == null" class="btn btn-sm btn-danger py-0" @click.stop="reject(msg.id)">reject</button>
              <button class="btn btn-sm btn-info py-0" @click.stop="markAsRead(msg.id)"> Mark as read </button>
            </div>
            
    
  </li>
  </div>
</template>
<script setup>
import {  computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { right } from '@/helpers/strings'

dayjs.extend(relativeTime)
const toast = useToast()
const truncate = (str, len, fill) => {
  if (str == null) {
    return null
  }
  if (str.length > len) {
    return str.substring(0,len)+fill
  }
  return str
}
const store = useStore()
const setEditableProblem = (pid) => {
  store.commit('openDialog',{name : 'problemeditor','value' : pid})
}
const messages = computed(() => store.state.problemMessages)
defineProps({ 'title': { type: String, default: '' } })
 const approve = (mid) => {
   store.dispatch('approveProblemMessage', mid)
   .then(() => {
     toast.success("Message approved")
   })
 }
 const reject = (mid) => {
   store.dispatch('rejectProblemMessage', mid)
     toast.success("Message rejected (and deleted)")
 }
 const markAsRead = (mid) => {
   store.dispatch('markProblemMessageAsRead', mid)
 }
</script>
