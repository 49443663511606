<template>
  <div class="container">
    <h1>Competitions
      <button class="btn btn-primary btn-sm mt-3" @click="newComp">Add a competition... <i class="fa fa-plus"></i></button>
    </h1>
    <p>
      Here you can see your competitions and the competitions which are shared to you by other gyms.
    </p>
    <h2>Competitions types</h2>
    Problemator works with both sport climbing and bouldering.
    You can have different types of competitions and you can also use different combinations also. A normal
    combination is that you have qualification competition using variable points and eg. the best 6
    in each category move to finals and the final standigns are calculated by IFSC Bouldering rules.
    <button class="btn btn-primary" @click="showDocs = !showDocs">{{ (!showDocs) ? 'Show' : 'Hide' }} details about competitions</button>
    <div class="row py-3" v-if="showDocs">
      <div class="col-12 my-2 lead">
        Here is the list of supported competition types. The small print means where the type is usually used.
        Nothing stops you from using 'variable points' in sport climbing or vice versa.
      </div>
      <div class="col-4">
        <h4>Variable points <small class="text-sm ">boulder</small></h4>
        This is the most used competition type. Usually every problem scores 100 points. Then the
        amount of points to a climber is 100 / &lt;ascents_for_the_problem&gt;. So if there are 4 ascents,
        that problem scores 100 /4 = 25 points. You can also spice up things in competition settings
        by giving a bonus for climbing the problem with lesser tries.
      </div>
      <div class="col-4">
        <h5>Flash comp <small class="text-sm">boulder</small></h5>
        Flash comp is a competition type where you give points based on tries per problem. For example
        flash (1st go) gives 100 points, 2nd go 50 points, 3rd go 30 points and other tries 10 points.
      </div>
      <div class="col-4">
        <h5>Traditional tops and bonuses <small class="text-sm">boulder</small></h5>
        This is the good old format where tops and bonuses count. First amount of tops, then
        tries to top. Then amount of bonuses and then tries to bonus. Please note that this IS NOT
        the way the points are shared nowadays in IFSC world cup.
      </div>
      <div class="col-4">
        <h5>IFSC Bouldering <small class="text-sm">boulder</small></h5>
        This follows the rules of IFSC Bouldering rules. First the tops, then the bonuses and after
        that tries to top and tries to bonuses are compared. This method also calculates the
        Paris Olympics points.
      </div>
      <div class="col-4">
        <h5>IFSC Sport <small class="text-sm">sport</small></h5>
        This follows the rules of IFSC Sport climbing rules. Each holds gives a point,
        and attempt from a controlled hold gives a plus. The competition type supports
        2 qualification routes, semifinal route and finals. You can opt to use 0-2 qualification
        routes, 0-1 semifinal rules and 0-1 final routes. So whichever suits you best.
      </div>
      <div class="col-4">
        <h5>Sport, with bonus holds <small class="text-sm">sport</small></h5>
        The route can have n bonus holds, which score extra points.
      </div>
    </div>
    <div class="mt-2">
      <div class="gap-2 flex flex-row">
        <i class="fa fa-lock text-success"></i> = Competition is locked, no further changes are allowed.
      </div>
      <div class="gap-2 flex flex-row">
        <i class="fa fa-unlock text-danger"></i> = Competition is not locked, changes can be made.
      </div>
    </div>

    <oma-table class="table table-striped table-condensed table-sm" :rows="sortComps(competitions)" :cols="columns">
      <template #name="{ value, row }">
        <router-link class="dropdown-item" href="#" :to="'/competitions/' + row.id">{{ value }}</router-link>
      </template>
      <template #locked="{ value }">
        <i v-if="value == 1" class="fa fa-lock text-success"></i>
        <i v-else class="fa fa-unlock text-danger"></i>
      </template>
      <template #compdate="{ value }">
        {{ dayjs(value).format("DD.MM.YYYY") }}
      </template>
      <template #actions="{ row }">
        <Dropdown @action="onAction">
          <template #default="{ action }">

            <li>
              <router-link class="dropdown-item" href="#" :to="'/competitions/' + row.id">Open</router-link>
            </li>
            <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('del_comp', row.id)">Remove</a></li>
          </template>
        </Dropdown>
      </template>


    </oma-table>

  </div><!-- end of row -->
</template>
<script setup>
import { computed, ref } from 'vue'
import store from '../../store/store'
import OmaTable from '@/components/OmaTable.vue'
import dayjs from 'dayjs'
import Dropdown from '@/components/Dropdown.vue'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification";
const toast = useToast();

const sortComps = (comps) => {
  return comps.sort((a,b) => b.id - a.id)
}
const showDocs = ref(false)
const competitions = computed(() => store.state.competitions.competitions)
const columns = [
  { id: 'locked', label: '' },
  { id: 'id', },
  { id: 'name', },
  { id: 'compdate', label: 'Date' },
  { id: 'tyyppi', label: "Type" },
  { id: 'actions', },
]

const onAction = ({ action, id }) => {
  if (action == 'del_comp') {
    delComp(id)
  }
}
const delComp = (id) => {
  console.log(id)
}
const newComp = () => {
  store.dispatch('competitions/addNewCompetition')
  .then(() => {
    toast('Competition added')
  })
  .catch(err => toast(handleValidationErrors(err)))
}
store.dispatch('competitions/getCompetitions')

</script>
