import { createRouter , createWebHashHistory} from "vue-router";
import Dashboard from "../views/dashboards/Dashboard.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import WallTwister from "../views/pages/WallTwister.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Charts from "../views/pages/Charts.vue";
import Circuits from "../views/pages/Circuits.vue";
import RoutesettingReport from "../views/pages/reports/RoutesettingReport.vue";
import BySetterReport from "../views/pages/reports/BySetterReport.vue";
import Manager from "../views/pages/Manager.vue";
import ProblemAging from "../views/pages/ProblemAging.vue";
import Floorplans from "../views/pages/Floorplans.vue";
import GymSettings from "../views/pages/GymSettings.vue";
import Users from "../views/pages/Users.vue";
import TrainingTemplates from "../views/pages/TrainingTemplates.vue";
import Exercises from "../views/pages/Exercises.vue";
import Planning from "../views/pages/Planning.vue";
import Competitions from "../views/pages/Competitions.vue";
import RTL from "../views/pages/Rtl.vue";
import Competition from "../views/pages/Competition.vue";
import Cup from "../views/pages/Cup.vue";
import EditContender from "@/components/competitions/EditContender.vue";
import Cups from "../views/pages/Cups.vue";
import Walls from "../views/pages/Walls.vue";
import Problems from "../views/pages/Problems.vue";
import Notifications from "../views/pages/Notifications.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
  path : '/competitions/:compid/editcontender/:contid',
  name : "EditContender",
  component : EditContender
  },
  {
    path: "/competitions/:id",
    name: "Competition",
    component: Competition,
  },
  {
    path: "/rtl",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/reports/routesetters",
    name: "RoutesettingReport",
    component: RoutesettingReport,
  },
  {
    path: "/competitions",
    name: "Competitions",
    component: Competitions,
  },
  {
    path: "/problems",
    name: "Problems",
    component: Problems,
  },
  {
    path: "/walls",
    name: "Walls",
    component: Walls,
  },
  {
    path: "/cups",
    name: "Cups",
    component: Cups,
  },
  {
    path: "/cups/:id",
    name: "Cup",
    component: Cup,
  },
  {
    path: "/aging",
    name: "ProblemAging",
    component: ProblemAging,
  },
  {
    path: "/routesetters",
    name: "Routesetters",
    component: Users,
  },
  {
    path: "/gymsettings",
    name: "GymSettings",
    component: GymSettings,
  },
  {
    path: "/floormaps",
    name: "Floormaps",
    component: Floorplans,
  },
  {
    path: "/coaching/templates",
    name: "Training Templates",
    component: TrainingTemplates,
  },
  {
    path: "/coaching/exercises",
    name: "Exercises",
    component: Exercises,
  },
  {
    path: "/coaching/planning",
    name: "Planning",
    component: Planning,
  },
  {
    path: "/manager",
    name: "Manager",
    component: Manager,
  },
  {
    path: "/circuits",
    name: "Circuits",
    component: Circuits,
  },
  {
    path: "/dashboard",
    name: "Default",
    component: Dashboard,
  },
  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/wallster",
    name: "Wall Twister",
    component: WallTwister,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode : history , 
  routes,
  linkActiveClass: "active",
});

export default router;
