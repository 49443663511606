<template>
    <div class="flex gap-2">
    <slot ref="data"></slot>
    <button @click="copy"><i class="fa fa-copy"></i></button>
    </div>
</template>
<script setup>
    import { useSlots } from 'vue';
    const slots = useSlots();
    const copy = () => {
        const defaultSlot = slots.default()[0].children
        navigator.clipboard.writeText(defaultSlot)
    }
</script>
