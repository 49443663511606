<template>
  <Modal v-model="showChangeGym" title="Change gym" @ok="changeGym" ok-title="Change gym">
    <v-select id="gymid" v-model="selectedGym" :reduce="sel => sel.id" :options="gymsDropdown"> </v-select>
  </Modal>
  <Disclosure as="nav" class="bg-gray-800 " v-slot="{ open }">
    <div class="w-full px-4 sm:px-6 lg:px-8">
      <div class="flex flex-row h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img class="block h-8 w-auto lg:hidden" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
            <img class="hidden h-8 w-auto lg:block" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company" />
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <div class="pt-1">
              <router-link to="/dashboard">
                <a href="#" class="rounded-md px-3 mt-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Dashboard</a>
              </router-link>
              <router-link to="/problems">
                <a href="#" class="rounded-md px-3 mt-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Problems</a>
              </router-link>
              </div>

              <Menu as="div" class="">
                <div>
                  <MenuButton class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                    <a href="#" class="rounded-md px-3 py-2 text-sm font-medium text-white">Routesetter</a>
                  </MenuButton>
                </div>

                <MenuItems class="absolute z-10 mt-2 w-48 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <router-link to="/problems">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Problems</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/walls">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Walls</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/circuits">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Circuits</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/competitions">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Competitions</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/cups">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Cups</a>
                    </MenuItem>
                  </router-link>
                </MenuItems>

              </Menu>

              <Menu as="div" class="">
                <div>
                  <MenuButton class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                    <a href="#" class="rounded-md px-3 py-2 text-sm font-medium text-white">Manager</a>
                  </MenuButton>
                </div>

                <MenuItems class="absolute z-10 mt-2 w-48 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <router-link to="/manager">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Misc. functions</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/aging">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Problem Aging</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/routesetters">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Routesetters</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/floormaps">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Floor maps</a>
                    </MenuItem>
                  </router-link>
                  <router-link to="/gymsettings">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Gym Settings</a>
                    </MenuItem>
                  </router-link>

                </MenuItems>

              </Menu>

  <Menu as="div" class="">
                <div>
                  <MenuButton class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                    <a href="#" class="rounded-md px-3 py-2 text-sm font-medium text-white">Reports</a>
                  </MenuButton>
                </div>

                <MenuItems class="absolute z-10 mt-2 w-48 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <router-link to="/reports/routesetters">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Routesetter report</a>
                    </MenuItem>
                  </router-link>

                </MenuItems>

              </Menu>
              <Menu as="div" class="">
                <div>
                  <MenuButton class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                    <a href="#" class="rounded-md px-3 py-2 text-sm font-medium text-white">Pages / Extra</a>
                  </MenuButton>
                </div>

                <MenuItems class="absolute z-10 mt-2 w-48 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <a @click.prevent="navigateToProblematorInfo" href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Info Display</a>
                  </MenuItem>
                  <router-link to="/wallster">
                  <MenuItem v-slot="{ active }">
                  <a href="#"  :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Wall Twister</a>
                  </MenuItem>
                  </router-link>
                </MenuItems>
              </Menu>

              <!--
              <a href="#" class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Projects</a>
              -->
            </div>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex items-center">
            <span class="text-red-500">{{ getName }}</span>
            <Menu as="div" class="relative ml-3">
              <a href="#" class="rounded-md ps-3 pe-1 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"><i class="fa fa-industry"></i> {{ gym?.name }}</a><a href="#"><small @click="showChangeGym=true" class="text-blue-400">change gym</small></a>
            </Menu>
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span class="sr-only">Open notifications</span>
                  <i class="pt-1 fa fa-bell fa-lg text-white " aria-hidden="true" />
                  <span class="badge bg-danger ms-1 -mt-1 ">{{ messages.length }}</span>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <ul class="m-0 p-0 h-screen overflow-auto">
                    <li class="font-bold">
                      <h4 class="ms-4 my-1">Problem messages</h4>
                    </li>
                    <new-messages />
                  </ul>
                </MenuItems>
              </transition>
            </Menu>

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span class="sr-only">Open user menu</span>
                  <i class="fa fa-user fa-lg text-white " />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <router-link to="/user/settings/">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</a>
                    </MenuItem>
                  </router-link>
                    <MenuItem v-slot="{ active }">
                    <a href="#" @click.prevent="store.dispatch('logout')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign out</a>
                    </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="-mr-2 flex sm:hidden">
          <!-- Mobile menu button -->
          <div class="px-2 text-white">
              <i class="text-white px-2 fa fa-industry"></i> {{ gym?.name }} <a href="#"><small @click="showChangeGym=true" class="text-blue-400">change gym</small></a>
              </div>
          
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <i v-if="!open" class="block class fa fa-bars" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <!-- This is the mobile menu -->
    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link to="/dashboard">
          <DisclosureButton as="a" href="#" class="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white">Dashboard</DisclosureButton>
        </router-link>
        <router-link to="/problems">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Problems</DisclosureButton>
        </router-link>
        <router-link to="/walls">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Walls</DisclosureButton>
        </router-link>
        <router-link to="/circuits">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Circuits</DisclosureButton>
        </router-link>
        <router-link to="/competitions">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Competitions</DisclosureButton>
        </router-link>
        <router-link to="/cups">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Cups</DisclosureButton>
        </router-link>
        <router-link to="/routesetters">
          <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Routesetters</DisclosureButton>
        </router-link>
      </div>
      <div class="border-t border-gray-700 pt-4 pb-3">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <i class="fa fa-user fa-lg text-white" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-white">{{ getName }}</div>
            <div class="text-sm font-medium text-gray-400">{{ getEmail }}</div>
          </div>
          <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
            <span class="sr-only">View notifications</span>
            <i class="fa fa-bell text-white" />
          </button>
        </div>
        <div class="mt-3 space-y-1 px-2">
          <router-link to="/user/settings/">
            <DisclosureButton as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Settings</DisclosureButton>
          </router-link>
          <DisclosureButton @click.prevent="store.dispatch('logout')" as="a" href="#" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">Sign out</DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { onMounted, computed, ref } from "vue"
import { useStore } from 'vuex'
import { webendpoint } from '@/helpers/problemator'
import Modal from '@/components/Modal.vue'
import NewMessages from "@/components/NewMessages.vue"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
const toast = useToast()
const store = useStore()
const gym = computed(() => store.state.basic.gym)
const messages = computed(() => store.state.problemMessages)
const infoEndpoint = computed(() => webendpoint + "pi/" + gym.value.id)
//const wallsterUrl = computed(() => webendpoint + "wallster")

const user = computed(() => { return store.state.basic.user })
const showChangeGym = ref(false)
const getEmail = computed(() => {
  if (user.value != null) {
    return user.value.email
  }
  return ""
})
const gyms = computed(() => store.state.gyms)
const selectedGym = ref(null)
const gymsDropdown = computed(() => {
  if (gyms.value == null) {
    return []
  }
  return gyms.value.map(a => ({ id: a.id, label: a.name }))
})

const changeGym = () => {
  store.dispatch('changeGym', selectedGym.value)
    .catch((err) => toast.error(handleValidationErrors(err.response.data)))
  
}
onMounted(() => {
  store.dispatch('getProblemMessages')
})
const getName = computed(() => {
  if (user.value != null) {
    return user.value.etunimi + " " + user.value.sukunimi
  }
  return ""
})

const navigateToProblematorInfo = () => {
  window.location.href = infoEndpoint.value
}
/*
const navigateToWallster = () => {
  window.location.href = wallsterUrl.value
}
*/

</script>
  
