<template>
  <span class="badge rounded-pill" :class="activeVariant"  @click.prevent="toggleState"><slot></slot></span>
</template>
<script setup>
  import { computed } from 'vue'
  const props = defineProps ({
    variant : {type : String, default : 'primary'},
    size : {type : String, default: null},
    label : { type : String, default :''},
    checked : { type : Boolean, default : false},
    modelValue : { type : Boolean, default : false, }
  })
  const activeVariant = computed(() => {
    let str = ""
    if (props.checked) {
      str += 'bg-'+props.variant
    } else {
      str += 'bg-secondary'
    }
    str += " cursor-pointer"
    return str
  })
  const emit = defineEmits(['checked','iupdate:modelValue'])
  const toggleState = () => {
    emit('checked',!props.checked)
    emit('update:modelValue',!props.checked)
  }


</script>


