<template>
    <div class="card mb-4">
        <div class="d-flex">
            <div class="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4">
                <i class="material-icons opacity-10" aria-hidden="true">list</i>
            </div>
            <div class="position-relative">
                <h6 class="mt-3 mb-2 ms-3 fs-4">Problem attributes</h6>
            </div>
        </div>
        <div class="card-body p-3">
            <div style="max-width: 400px" v-if="data.datasets != null">
                <Radar :chart-data="data" chart-id="c0" :width="400" :height="200" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { Radar } from 'vue-chartjs'
import {  computed  } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const data = computed(() => ( {
    'labels' : store.state.basic.charts.problem_types_radar.labels,
    'datasets' : store.state.basic.charts.problem_types_radar.datasets
} ))
</script>
