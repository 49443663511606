<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="material-icons py-2">settings</i>
    </a>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <div class="mt-3">
          <h6 class="mb-0">Routesetter location</h6>
          <p class="text-sm">Choose the gym you are working on</p>
        </div>
        <v-select id="authorid" v-model="selectedGym" :reduce="sel => sel.id" :options="gymsDropdown"> </v-select>
        <div class="d-grid gap-2">
        <button @click="changeGym" class="px-3 mt-1 mb-2 btn btn-block bg-gradient-info" >Change gym</button>
        </div>
        
        

        <!-- Navbar Fixed -->
        <div class="mt-3 d-flex">
          <h6 class="mb-0">Navbar Fixed</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              id="navbarFixed"
              class="mt-1 form-check-input"
              :class="isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              :checked="isNavFixed"
              @click="setNavbarFixed"
            />
          </div>
        </div>

        <hr class="horizontal dark my-3" />
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Light / Dark</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              :checked="this.$store.state.isDarkMode"
              @click="darkMode"
            />
          </div>
        </div>
        <hr class="horizontal dark my-sm-4" />

        
        
        
        <div class="text-center w-100">
          
          <h6 class="mt-3">Thank you for sharing!</h6>
          <a
            href="https://twitter.com/intent/tweet?text=Check%20Problemator%20%23problemator%20%23climbing%20%23bouldering&amp;url=https%3A%2F%2Fwww.problemator.fi"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.problemator.fi"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { mapMutations, mapActions, mapState } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: "Configurator",
  // eslint-disable-next-line vue/require-prop-types
  props: ["toggle"],
  data : function() {
    return {
      selectedGym : null,
    }
  },
  computed: {
    ...mapState([
      "isNavFixed",
      "isAbsolute",
      "isRTL",
      "absolute",
      "color",
      "sidebarType",
    ]),
    gymsDropdown() {
      if (this.gyms == null) {
        return []
      }
      return this.gyms.map(a => ({id : a.id, label : a.name}))
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
    gyms() {
      if (this.$store.state.basic.user != null) {
        return this.$store.state.basic.user.gyms
      }
      return []
    },
    gym()  {
      return this.$store.state.basic.gym
    }

  },
  components : {
    vSelect
  },
  beforeMount() {
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
  mounted() {
  },
  watch : {
    gym(newValue ) {
      if (newValue != null) {
        this.selectedGym = newValue.id
      }

    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "navbarFixed"]),
    ...mapActions(["setColor"]),
    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.setColor(color);
    },
    changeGym() {
      this.$store.dispatch('changeGym',this.selectedGym)
    },

    darkMode() {
      if (this.$store.state.isDarkMode) {
        this.$store.state.isDarkMode = false;
        deactivateDarkMode();
        return;
      } else {
        this.$store.state.isDarkMode = true;
        activateDarkMode();
      }
    },
  }
};
</script>
