<template>

    <div v-if="competition != null" class="w-full">
    
        <div class="flex gap-2">
          <div class="py-1">{{  competition.tyyppi   }}</div>
          <button class="bg-blue-500 hover:bg-blue-600 text-white font px-4 py-2 rounded-lg" @click.prevent="showJudgeSharing=!showJudgeSharing">Show/hide judging sharing</button>
        </div>
        <div v-if="showJudgeSharing">
        <h3>Share judging page to mobile devices</h3>
        <br />
        <div v-if="competition.judge_pass == null">
        Judge password is NOT set. Please set it from the competition settings and try again.
        </div>
        <div v-else>
        Remember that the judging shared with this url CANNOT delete ascents.
        <br />
        You can share this page to the judges by showing this QR code or sharing this URL:
        <br />
        <div class="my-1">
        <a :href="judgingPageURL">{{judgingPageURL}}</a>
        <QRCodeVue3
        :width="200"
        :height="200"
        :value="judgingPageURL"
        :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
        :dotsOptions="{
type: 'dots',
        color: '#26249a',
        gradient: {
type: 'linear',
      rotation: 0,
      colorStops: [
      { offset: 0, color: '#26249a' },
      { offset: 1, color: '#26249a' },
      ],
        },
        }"
:backgroundOptions="{ color: '#ffffff' }"
/>
</div>
</div>
</div>

        <div class="m-2">
            
                <combined-judging :comp="competition"></combined-judging>

            
        </div>

    </div>
</template>
<script setup>

import { computed , ref } from 'vue'
import CombinedJudging from './CombinedJudging.vue'
import QRCodeVue3 from "qrcode-vue3"
const showJudgeSharing = ref(false)
const props = defineProps({
    competition: { type: Object },
})
const judgingPageURL = computed(() => {
    return  window.location.protocol + "//" + window.location.host + "/judging.html?compid="+props.competition.id+"&key="+props.competition.judge_pass
  })




</script>
