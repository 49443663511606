<template>
  <div class="container">
      <modal v-model="showEditDialog" title="Cup editor" ok-title="Save" @ok="onSaveCup" size="lg">
          <cup-editor :id="selectedCup" ref="refCupEditor" />
      </modal>
    <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
      <h1 class="page-header">Cups <small><button class="btn btn-sm btn-primary mt-3 ms-3" @click.prevent="addNewCup" > Add new...</button></small></h1>
      <p class="lead">Cups are easy and fun way to engage the climbers. Cups are really simple. Just give cup a name, add competitions into
      the cup and that's it. The result list grows as the competitions are being held. You can use the default scoring system or whip
      up your own scoring system from the cup settings. </p>
      <oma-table class="table table-sm table-striped table-responsive text-sm table-condensed" :rows="cups" :cols="cols">
        <template #pointsharing="{value}">
          {{ parsePointSharing(value)}}
        </template>
        <template #actions="{row}">
        <div class="flex flex-row gap-2">
        <a :href="webendpoint+'cups/'+row.slug+'/results'" target="_results" class="btn btn-sm btn-info">Results</a>
        <div class="dropdown">
          
          
      <Dropdown @action="onAction">
        <template #default="{action}">
          <li>
            <a class="dropdown-item" href="#" @click.prevent="action('editCup',row.id)">Edit...</a>
          </li>
          <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('delCup',row.id)">Remove</a></li>
        </template>
      </Dropdown>
          
        </div>
        </div>
          
        </template>
      </oma-table>
    </div>
  </div><!-- end of row -->
</template>
<script setup>
// eslint-disable-next-line no-unused-vars
import OmaTable from '@/components/OmaTable.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import CupEditor from '@/components/competitions/CupEditor.vue'
import { useToast } from "vue-toastification"
import Modal from '@/components/Modal.vue'
import { webendpoint } from '@/helpers/problemator.js'
import Dropdown from '@/components/Dropdown.vue'



const toast = useToast()
const refCupEditor = ref(null)
const cols  = [
  {id : 'id'},
  {id : 'cupname', label : 'name'},
  {id : 'comps_count', },
  {id : 'published'},
  {id : 'pointsharing', label : 'Point sharing'},
  {id : 'actions' },
]
const addNewCup = () =>{
  selectedCup.value = null
  showEditDialog.value = true
}
const onAction =  ({action, id}) =>{
  switch (action) {
    case 'editCup':
      editCup(id)
      break
    case 'delCup':
      delCup(id)
      break
  }
}
const editCup = (id) => {
  selectedCup.value = id
  showEditDialog.value = true
}
const delCup = (id) => {
  if (confirm("Are you sure you want to delete this cup?")) {
    store.dispatch('cups/deleteCup', { id })
      .then(ret => {
        console.log(ret)
        toast.success("Deleted")
      })
      .catch(err => {
        toast.error(handleValidationErrors(err))
      })
  }
}
const onSaveCup = () => {
  refCupEditor.value.save()
      .then(ret => {
          console.log(ret)
          selectedCup.value = null
          showEditDialog.value = false
          refCupEditor.value.reset()
          toast.success("Saved")
      })
      .catch(err => {
          toast.error(handleValidationErrors(err))
      })
  
}
const parsePointSharing =(sharing) => {
  let obj = sharing
  let rules = null
  try {
    if (typeof (sharing) != 'object') {
      obj = JSON.parse(sharing)
    }
    rules = Object.keys(obj).map(key => key + "=" + obj[key]).join(" | ")
    if (rules.length > 80) {
      return rules.substring(0, 77) + "..."
    }
  } catch (e) {
    console.log(e)
  }
  return rules
}
const cups = computed(() => store.state.cups.all)
const store = useStore()
const selectedCup = ref(null)
const showEditDialog = ref(false)
store.dispatch('cups/all')


</script>
  