<template>
  <form v-if="row != null" id="edituser" role="form" class="form container">
    <div class="row">
      <div class="col-12">
  
        <div class="mb-3">
          <label for="etunimi" class="control-label ">First name</label>
          <div class="col-sm-6">
            <input type="text" id="etunimi" name="etunimi" class="form-control" required="required" v-model="row.etunimi" />
          </div>
        </div>
  
        <div class="mb-3">
          <label for="surname" class="control-label ">Last name</label>
          <div class="col-sm-8">
            <input type="text" name="sukunimi" id="surname" class="form-control" required="required" v-model="row.sukunimi" />
          </div>
        </div>
  
        <div class="mb-3">
          <label for="email" class="control-label ">Email<br><small>equals username</small></label>
          <div class="col-sm-12">
            <input type="text" id="email" name="email" class="form-control" required="required" v-model="row.email">
          </div>
        </div>
  
        <div class="mb-3">
          <label for="password1" class="control-label ">Password</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control mb-3" v-model="row.password" placeholder="Password" aria-label="Password" aria-describedby="button-addon2">
            <button class="btn btn-outline-primary" type="button" id="button-addon2" @click="generatePassword">Generate password</button>
          </div>
          <label for="password2" class="control-label ">Password again</label>
          <input type="text" name="password2" v-model="row.password_again" id="password2" class="form-control password">
  
        </div>
  
        <div class="mb-3">
          <label for="nick" class="control-label ">Nick</label>
          <div class="col-sm-6">
            <input type="text" id="nick" name="nick" class="form-control" v-model="row.nick">
          </div>
        </div>
  
  
        <div class="mb-3">
          <label for="phone" class="control-label ">Phone</label>
          <div class="col-sm-6">
            <input type="text" id="phone" name="phone" class="form-control" v-model="row.phone">
          </div>
          <br><small> (in format eg. 358440781120 or 4492151594)</small>
        </div>
  
        <div class="mb-3">
          <label for="ismanager" class="control-label ">Is user a manager? (show manager menu, allow add/edit/del routesetters etc.)</label>
          <div class="col-sm-8">
            <checkbox :unchecked-value="0" :checked-value="1" id="ismanager" name="ismanager" v-model="row.ismanager">
              Is manager?
            </checkbox>
          </div>
        </div>
  
        <div class="mb-3">
          <div class="col-sm-4">
            <checkbox :unchecked-value="0" :checked-value="1" id="active" name="active" v-model="row.active">
              Active?
            </checkbox>
            <small>If the routesetter is NOT active, it means the routesetter cannot log in.</small>
          </div>
        </div>
      </div>
  
      <div class="col-12">
        <h3>Authorized gyms </h3>
        <v-select multiple v-model="selectedGyms" :options="gymsDropdown" />
      </div>
    </div> <!-- /row-->
  
  
  
  </form>
  <div v-else>Loading...</div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'
import Checkbox from '@/components/MaterialCheckbox.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { randomPassword } from '@/helpers/helpers'
import bcrypt from 'bcryptjs'
import { useToast } from "vue-toastification"
const toast = useToast()
const selectedGyms = ref([])
const store = useStore()
const props = defineProps({ 'id': String })

const gyms = computed(() => store.state.basic.user.gyms)
const allGyms = computed(() => store.state.gyms)
const user = computed(() => store.state.basic.user)

const gymsDropdown = computed(() => {
  if (gyms.value == null) {
    return []
  }
  // If user is global manager, replace with all gyms...
  if (user.value.globalmanager == 1) {
    return allGyms.value.map(a => ({ id: a.id, label: a.name }))
  } 
  return gyms.value.map(a => ({ id: a.id, label: a.name }))
})

const row = computed(() => store.state.routesetters.row)
watch(row, (newValue) => {
  selectedGyms.value = newValue.gyms.map(a => ({ id: a.id, label: a.name }))
})
const skeleton = {
  id: "*new*",
  active: true,
  gyms : [],
}

if (props.id == null) {
  // Assume new problem (skeleton)
  store.commit('routesetters/row', { ...skeleton })
} else {
  store.dispatch('routesetters/get', props.id)
}

// eslint-disable-next-line no-unused-vars
const save = async (type) => {
  // IF password is set, check that passwords match.
  const pass = row.value.password
  if (pass!= null && pass != "") {
    if (pass != row.value.password_again) {
      toast.error("Password confirmation does not match!");
    } else {
      // hash before sending
      const salt = await bcrypt.genSalt(10);
      // now we set user password to hashed password
      // eslint-disable-next-line require-atomic-updates
      const pass = await bcrypt.hash(row.value.password, salt);
      // eslint-disable-next-line require-atomic-updates
      row.value.password = pass
      // eslint-disable-next-line require-atomic-updates
      row.value.password_again = pass
    }
  }
  // Otherwise we can assuem that the password is not altered
  return store.dispatch('routesetters/save', { ...row.value, ['gyms']: selectedGyms.value.map(x => x.id) })
}
const reset = () => {
  console.log("Resetting routesetter to", skeleton)
  const newSkeleton = { ...skeleton }
  store.commit('routesetters/row', newSkeleton)
}
const generatePassword = () => {
  const pass = randomPassword(12)
  row.value.password =  pass
  row.value.password_again =  pass
}
// Expose save method outside
defineExpose({ save, reset })
</script>