<template>
    <div>
      <modal v-model="dialogStatuses['problemeditor_dialog']" size="xl">
        <template #title>Edit problem</template>
        <problem-editor :id="dialogStatuses['problemeditor']" ref="problemEditor" />
        <template #footer="{ close }">
          <button type="button" class="btn btn-secondary" @click="close">Close</button>
          <button v-if="selectedProblem == null" type="button" class="btn btn-info" @click="onSaveProblem(false)">Save and keep adding problems</button>
          <button type="button" class="btn btn-primary" @click="onSaveProblem">Save changes</button>
        </template>
      </modal>
      <modal v-model="showCircuitEditorDialog" size="xl" title="Circuit editor" @ok="onSaveCircuitProblems">
        <circuit-problem-editor :id="selectedProblem" ref="circuitProblemEditorRef" />
      </modal>
    </div>
</template>
<script setup>
// All the global editor dialogs are placed here so they can be called anywhere from the app
// using global store state
import {  ref, computed } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
// eslint-disable-next-line no-unused-vars
import ProblemEditor from '@/components/ProblemEditor.vue'
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '@/helpers/errors.js'
const store = useStore()
const dialogStatuses = computed(() => store.state.dialogs)
const toast = useToast()
const problemEditor = ref(null)


const onSaveProblem = (keepDialogOpen) => {
    problemEditor.value.save()
        .then(() => {
            toast.success("Saved")
            problemEditor.value.reset()
            store.commit('resetDialogEditable', 'problemeditor')
            if (keepDialogOpen !== false) {
                store.commit('closeDialog', 'problemeditor')
            }
        })
        .catch(err => {
            toast.error(handleValidationErrors(err.response.data))
        })
}

</script>