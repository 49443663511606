<template>
  <div>
    imagew: {{  imageFileWidth}}, imageh: {{  imageFileHeight }}<br />
    w: {{  width }}, h: {{  height }}<br />
    x: {{  cursorX }}, y: {{  cursorY }}
    <div ref="mapContainer" class="m-0 p-0" style="position: relative" :style="getStyles">
      <img
        @load="imageLoaded"
        :src="props.src"
        class="m-0 p-0"
        :style="getImgStyles"
        ref="imagemapcontainer"
      />
      <canvas
        @mousemove="handleMouseMove"
        @click="handleClick"
        @mousedown="dragging = true"
        @mouseup="dragging = false"
        :dummy-value="ctx  && isImageLoaded && width"
        ref="graph"
        style=" border: 2px solid red; position: absolute; z-index: 2000; top: 0; left: 0; margin: 0; padding: 0; "
        :width="width"
        :height="height"
      >
      </canvas>
    </div>
    <div class="">
     <button
        @click.prevent="draw"
        class="m-1 bg-red-500 px-4 py-2 rounded-md text-white"
      >
      redraw
      </button> 
      <button
        @click.prevent="addNewArea"
        class="m-1 bg-blue-500 px-4 py-2 rounded-md text-white"
      >
        add new area
      </button>
      <button
        v-if="addingNew"
        @click.prevent="undo"
        class="m-1 bg-red-500 px-4 py-2 rounded-md text-white"
      >
        undo
      </button>
      <button
        v-if="addingNew"
        @click.prevent="cancelAdd"
        class="m-1 bg-red-500 px-4 py-2 rounded-md text-white"
      >
        cancel
      </button>
    </div>
    Areas
    <ul v-if="imageMaps.length > 0">
      <li v-for="(anArea, idx) in imageMaps" :key="idx">
        Coords: {{ anArea.coords.length }}, title: {{ anArea.title ?? 'No title' }},
        <a href="#" @click.prevent="selectArea(idx)" class="px-1 text-blue-500">select</a>
        <a href="#" @click.prevent="deleteArea(idx)" class="px-1 text-red-500">del</a>
      </li>
    </ul>
    <div v-else>No areas (yet)</div>
    <div v-if="selectedRow != null" class="rounded-md p-2 border bg-gray-400">
      <h3 class="font-bold block">Area</h3>
      <h4>Title</h4>
      <input
        type="text"
        v-model="selectedRow.title"
        class="block px-4 py-1"
        placeholder="Enter title"
      />
      <h4>Points <small class="text-sm">Click the new are on the image</small></h4>
      {{ selectedRow.coords }}
      <button
        v-if="addingNew"
        @click.prevent="save"
        class="m-1 bg-green-500 px-4 py-2 rounded-md text-white"
      >
        save
      </button>
    </div>
    <br />
  </div>
</template>
<script setup>
import { onUpdated, computed, ref, onMounted , onUnmounted} from 'vue'
const props = defineProps({
  modelValue: null,
  src: String,
})
const emit = defineEmits(['update:modelValue', 'update:title'])
const selectedIndex = ref(null)
const addingNew = ref(false)
const selectedRow = ref(null)
const isImageLoaded = ref(false)
const graph = ref(null)
const width = ref(800)
const height = ref(600)
const ctx = ref(null)
const mapContainer = ref(null)
const draggingCoords = ref(null)
const imagemapcontainer = ref(null)
let img = new Image()
const imageFileHeight = ref(null)
const imageFileWidth = ref(null)
const cursorX = ref(null)
const cursorY = ref(null)
const draggingPointIndex = ref(null)
const dragging = ref(false)
const distanceBetweenTwoPoints = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

const findClosestPoint = (radius) => {
  if (selectedRow.value == null) {
    return -1
  }
  return selectedRow.value.coords.findIndex((point) => {
    // Check distance to point, if it is less than radius, select it
    const dist = distanceBetweenTwoPoints(
      point[0],
      point[1],
      reverseX(cursorX.value),
      reverseY(cursorY.value)
    )
    return dist < radius
  })
}
const handleMouseMove = (e) => {
  draggingPointIndex.value = findClosestPoint(15)

  // Check if key is pressed, then we are dragging.
  if (dragging.value) {
    // Now we are dragging, find the polygon point we are dragging...
    // Then update the point, if the point was found
    if (draggingPointIndex.value != -1) {
      selectedRow.value.coords = selectedRow.value.coords.map((point, index) => {
        if (draggingPointIndex.value == index) {
          draggingCoords.value = [reverseX(cursorX.value), reverseY(cursorY.value)]
          return draggingCoords.value
        } else {
          return point
        }
      })
    }
  } else {
    draggingPointIndex.value = null
  }

  cursorX.value = e.offsetX
  cursorY.value = e.offsetY
}
const selectArea = (idx) => {
  selectedIndex.value = idx
  selectedRow.value = imageMaps.value[idx]
}
onMounted(() => {
  img.src = props.src
  ctx.value = graph.value.getContext('2d')
  setTimeout(() => {
    draw()
  }, 100)
})
onUpdated(() => {
    draw()
})
const deleteArea = (idx) => {
  const afterDeletion = imageMaps.value.filter((x, index) => index != idx)
  const generated = generateImagemap(afterDeletion)
  emit('update:modelValue', generated)
}
const selectedColor = '#00ff00'
const defaultColor = '#ff0000'

img.onload = function () {
  imageFileWidth.value = this.width
  imageFileHeight.value = this.height
  width.value = imagemapcontainer.value.clientWidth
  height.value = imagemapcontainer.value.clientHeight
  isImageLoaded.value = true
}
const getImgStyles = computed(() => {
  return {
    opacity: 0.8,
    width: '100%',
    border: '1px solid blue',
  }
})
const getStyles = computed(() => {
  let cursor = addingNew.value ? 'crosshair' : 'pointer'
  if (draggingPointIndex.value != -1 && draggingPointIndex.value != null) {
    cursor = 'grabbing'
  }
  return {
    cursor,
  }
})

const path = (ctx, coords, color = 'red', opt = {}) => {
  ctx.beginPath()
  for (let idx in coords) {
    const coord = coords[idx]
    if (idx == 0) {
      ctx.moveTo(coord[0], coord[1])
    } else {
      ctx.lineTo(coord[0], coord[1])
    }
    if (opt.dots) {
      // Add circles.
      ctx.arc(coord[0], coord[1], 2, 0, 2 * Math.PI, false)
      ctx.moveTo(coord[0], coord[1])
    }
  }
  ctx.globalAlpha = 0.9
  ctx.lineWidth = opt.lineWidth || 1
  ctx.strokeStyle = color
  ctx.stroke()

  ctx.stroke()
}

const polygon = (ctx, coords, stroke = false, fillColor, opt = {}) => {
  ctx.beginPath()

  for (let idx in coords) {
    const coord = coords[idx]
    if (idx == 0) {
      ctx.moveTo(coord[0], coord[1])
    } else {
      ctx.lineTo(coord[0], coord[1])
    }
  }
  ctx.closePath()
  if (stroke) {
    ctx.globalAlpha = 0.9
    ctx.lineWidth = opt.lineWidth || 2
    ctx.strokeStyle = 'red'
    ctx.stroke()
  } else {
    ctx.fillStyle = fillColor
    ctx.globalAlpha = 0.7
    ctx.fill()
  }
}

// Translates coordinates by getting the image size, then the
// container size and applying the ratio for x and y
const translateCoords = (coords) => {
  let ratiox =  width.value / imageFileWidth.value
  let ratioy =  height.value / imageFileHeight.value 
  return coords.map((item) => {
    return [Math.round(ratiox * item[0]), Math.round(ratioy * item[1])]
  })
}
const reverseX = (x) => {
  return Math.round((imageFileWidth.value / width.value) * x)
}
const reverseY = (y) => {
  return Math.round((imageFileHeight.value / height.value) * y)
}
const drawNewPolygon = () => {
  if (selectedRow.value == null) {
    return
  }
  let translatedCoords = translateCoords(selectedRow.value.coords)
  if (selectedRow.value.coords.length > 2) {
    polygon(ctx.value, translatedCoords, false, '#a0a0a0')
  }
  // Add the first coordinate to last, so that the line will be filled as a polygon
  if (selectedRow.value.coords.length > 2) {
    const firstCoord = translatedCoords[0]
    translatedCoords = [...translatedCoords, firstCoord]
  }

  path(ctx.value, translatedCoords, '#262626', { dots: true })
}

const myEventHandler = () => {
    width.value = imagemapcontainer.value.clientWidth
    height.value = imagemapcontainer.value.clientHeight

}
window.addEventListener("resize", myEventHandler)
onUnmounted(() => {
    window.removeEventListener("resize", myEventHandler)
})
const draw = () => {
  console.log('Drawing')
  ctx.value.clearRect(0, 0, ctx.value.canvas.width, ctx.value.canvas.height)
  ctx.value.save()
  console.log("imagemaps",imageMaps.value.length)
  imageMaps.value.forEach((item, index) => {
    const translatedCoords = translateCoords(item.coords)
    if (index == selectedIndex.value) {
      polygon(ctx.value, translatedCoords, false, selectedColor)
    } else {
      polygon(ctx.value, translatedCoords, false, defaultColor)
    }
  })
  if (addingNew.value) {
    drawNewPolygon()
  }
  ctx.value.restore()

}
/*
const drawMap = computed(() => {
  draw()

})
*/

const parseMapString = (str) => {
  if (str == null) {
    return []
  }
  const matches = str.matchAll(/<area.*?>/g)
  let areas = []
  for (const match of matches) {
    const area = match[0]
    // Then look for coords, assume shape always poly
    const coords = area.match(/coords..(.*?)"/)[1]
    const coordsArray = coords.split(',').map((i) => parseInt(i))
    const coordPairs = coordsArray.reduce(function (result, value, index, array) {
      if (index % 2 === 0) result.push(array.slice(index, index + 2))
      return result
    }, [])

    const title = area.match(/title..(.*?)"/)[1]
    areas = [{ title, coords: coordPairs }, ...areas]
  }
  return areas
}
/*
const generatedImagemap = computed(() => {
  const areas = imageMap.value.map((item) => {
    return `<area target="" alt="${item.title}" title="${item.title}" href="#" coords="${item.coords}" >`
  })
  return '<map name="image-map">' + areas.join('\n') + '</map>'
})
*/
const generateImagemap = (maps) => {
  const areas = maps.map((item) => {
    return `<area target="" alt="${item.title}" title="${item.title}" href="#" coords="${item.coords}" >`
  })
  return '<map name="image-map">' + areas.join('\n') + '</map>'
}

const imageMaps = computed(() => {
  // Parse the imagemap string to internal json
  return parseMapString(props.modelValue)
})
const cancelAdd = () => {
  addingNew.value = false
  selectedRow.value = { title: '', coords: [] }
}
const addNewArea = () => {
  const newRow = { title: '', coords: [] }
  selectedRow.value = newRow
  selectedIndex.value = imageMaps.value.length
  addingNew.value = true
  //const updated = [...imageMaps.value, newRow]
  //emit('update:modelValue', generateImagemap(updated))
}
const undo = () => {
  selectedRow.value.coords = selectedRow.value.coords.filter(
    (i, idx) => idx != selectedRow.value.coords.length - 1
  )
}
const isCoordinateAlreadyIn = (newValue) => {
  if (selectedRow.value == null) {
    return false
  }
  const canditate = selectedRow.value.coords.find((point) => {
    return point[0] == newValue[0] && point[1] == newValue[1]
  })
  return canditate != null
}
const handleClick = () => {
  if (selectedRow.value == null) {
    return false
  }
  // Check that draggin coordinates are not same as the
  // clicked coordinates. ANd only add if that's so.
  const newValue = [reverseX(cursorX.value), reverseY(cursorY.value)]
  // IF the new value already exists, don't put in...
  if (!isCoordinateAlreadyIn(newValue)) {
    console.log('Adding new coordinate', newValue)
    selectedRow.value.coords.push(newValue)
  } else {
    console.log('Already exists')
  }
}
const save = () => {
  let updated = null
  if (addingNew.value) {
    updated = [...imageMaps.value, selectedRow.value]
  } else {
    updated = imageMaps.value.map((item, idx) => {
    if (idx == selectedIndex.value) {
      return selectedRow.value
    }
    return item
  })
  }
  dragging.value = false
  emit('update:modelValue', generateImagemap(updated))
  selectedRow.value = null
}
</script>
