const right = (str,index) => {
  if (str == null) {
    return null
  }
  if (index < 0) {
     return str.substring(-index)
  } 
  const len = str.length
  return str.substring(len-index)
}
export {
  right 
}
