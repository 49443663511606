<template>
    <modal v-model="showEditDialog" title="User editor" ok-title="Save" @ok="onSave" size="md">
        <user-editor :id="selectedRow" ref="refEditor" />
    </modal>
    <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
        <h2>Routesetters<small class="mx-2 fs-6 text-muted">{{ rows.length }} routesetters(s) <a class="btn btn-sm btn-primary" href="#" @click.prevent="addNew">Add new...</a></small></h2>
        <p>
            Here you can manage the routesetters.
        </p>
        <oma-table :cols="cols" :rows="rows" class="table table-striped">
        <template #active="{value}">
            <i v-if="value==1" class="fa fa-check text-success"></i>
            <i v-else class="fa fa-trash text-danger"></i>
        </template>
        <template #ismanager="{value}">
            <i v-if="value==1" class="fa fa-check text-success"></i>
            <i v-else class="fa fa-x-mark text-danger"></i>
        </template>
            <template #actions="{row}">
                <Dropdown @action="onAction">
                    <template #default="{action}">
                        <li> <a class="dropdown-item" href="#" @click.prevent="action('editRow',row.id)">Edit...</a> </li>
                        <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('del',row.id)">Remove</a></li>
                    </template>
                </Dropdown>
            </template>
        </oma-table>
    
    </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
import Modal from '@/components/Modal.vue'
import UserEditor from '@/components/UserEditor.vue'
import OmaTable from '../../components/OmaTable.vue'
import Dropdown from '@/components/Dropdown.vue'

const selectedRow = ref(null)
const showEditDialog = ref(false)
const refEditor = ref(null)
const toast = useToast()

const cols = [
    { id: 'id' },
    { id: 'active' },
    { id: 'etunimi' },
    { id: 'sukunimi' },
    { id: 'nick' },
    { id: 'email' },
    { id: 'ismanager' },
    { id: 'actions' },
]
const del = (id) => {
    if (confirm("Are you sure you want to delete this item?")) {
        store.dispatch('routesetters/delete', { id })
            .then(ret => {
                console.log(ret)
                toast.success("Deleted")
            })
            .catch(err => {
                toast.error(handleValidationErrors(err))
            })
    }
}
const addNew = () => {
    selectedRow.value = null
    showEditDialog.value = true
}
const onAction = ({ action, id }) => {
    switch (action) {
        case 'editRow':
            editRow(id)
            break
        case 'del':
            del(id)
            break
    }
}

const editRow = (id) => {
    selectedRow.value = id
    showEditDialog.value = true
}
const onSave = () => {
    refEditor.value.save()
        .then(ret => {
            console.log(ret)
            selectedRow.value = null
            showEditDialog.value = false
            toast.success("Saved")
            refEditor.value.reset()
        })
        .catch(err => {
            toast.error(handleValidationErrors(err))
        })

}
const store = useStore()
const rows = computed(() => store.state.routesetters.all.sort((b,a) => a.id - b.id))
store.dispatch('routesetters/all')

</script>