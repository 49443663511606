<template>
  <div
    class="page-header align-items-start min-height-300 m-3 border-radius-xl"
    style=" background-image: url('https://images.unsplash.com/photo-1491466424936-e304919aada7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80'); "
  >
    <span class="mask bg-gradient-dark opacity-5"></span>
    <h1 class="mx-auto my-2 text-white">Problemator routesetter</h1>
  </div>
  <div class="container mb-4">
    <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
      <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
        <div class="card mt-8">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 text-center py-4"
            >
              <h4 class="font-weight-bolder text-white mt-1">Sign In</h4>
              <p class="mb-1 text-sm text-white">
                Enter your email and password to Sign In
              </p>
            </div>
          </div>
          <div class="card-body">
            <form role="form" class="text-start" @submit.prevent="onSubmit">
              <div class="mb-4">
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  class="form-control px-2"
                  placeholder="john@email.com"
                  label="Email"
                  variant="static"
                  required
                />
              </div>
              <div class="mb-4">
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="form-control px-2"
                  placeholder="password"
                  label="Password"
                  variant="static"
                  required
                />
              </div>
              <div class="mb-4">
                <select 
                  id="gym"
                  v-model="gym"
                  class="form-control px-2"
                  required
                >
                  <option :value="null">-- Choose a gym --</option>
                  <option v-for="agym in gyms" :key="agym.id" :value="agym.id">{{ agym.name }}</option>
                </select>
              </div>

              <div class="text-center">
                <material-button
                  class="my-4 mb-2"
                  type="submit"
                  variant="gradient"
                  color="dark"
                  full-width
                  >Sign in</material-button
                >
              </div>
            </form>
            <div class="px-1 pt-0 text-center card-footer px-lg-2 mt-3">
              <p class="mx-auto mb-4 text-sm ">
              Cannot login?<strong class="text-success ">If your username was not your email, contact your head routesetter.</strong>.
              </p>
              <p class="mx-auto mb-4 text-sm ">
              Don't have an account? <strong class="text-success ">Contact your head routesetter</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script >
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import store from '@/store/store'
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '@/helpers/errors.js'
import { mapState, mapMutations } from "vuex";
const toast = useToast()
const body = document.getElementsByTagName("body")[0];

export default {
  name: "SigninCover",
  components: {
    AppFooter,
    MaterialButton,
  },
  data : function() {
     return {
       email : null,
       password : null,
       gym : null,
     }
  },
  computed : {
     ...mapState([
       'gyms'
       ]),
       authToken() {
        return localStorage.getItem('authToken')
       }
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
    const token = localStorage.getItem('authToken')
    if (token != null) {
      this.$router.push("/dashboard")
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async onSubmit() {
      store.dispatch('login',{email : this.email, password : this.password, location : this.gym})
      .then(ret => {
        console.log(ret)
        if (ret.status != null && ret.status=== "success") {
          // Route to dashboard index
          //this.$router.go( "/dashboard")
          const url = location.origin;
          const pathname = "/#/dashboard"
          //const hash = location.hash;
          debugger

          location = url + pathname 
          //+ '?application_refresh=' + (Math.random() * 100000) + hash;

        }
      })
      .catch(err => {
        toast.error(handleValidationErrors(err))
      })

    }
  },
};
</script>
