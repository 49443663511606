<template>
    <div class="w-full flex flex-row ">
        <div id="left" class="flex-grow flex flex-col w-5/12">
            <div class="text-center">Selected</div>
            <div class="input-group ">
                <input type="text" class="mb-3 form-control" v-model="selectedFilter" />
                <button @click="selectedFilter=''" class="btn btn-primary mb-3 ">clear</button>
            </div>
            <select id="selected"  size="10" multiple v-model="left" class="border"> 
                <option v-for="opt in selectedDropDown" :key="opt.id" :value="opt.id">{{ getOptionLabel(opt) }}</option>
            </select>
        </div>
        <div id="middle" class="flex flex-col mx-1 self-center w-2/12">
           <button @click.prevent="toLeft" class="btn btn-primary">&lt;&lt;</button>
           <button @click.prevent="toRight" class="btn btn-primary">&gt;&gt;</button>
        </div>
        <div id="right" class="flex-grow flex flex-col w-5/12">
            <div class="text-center">Available</div>
            <div class="input-group ">
                <input type="text" class="mb-3 form-control" v-model="availableFilter" />
                <button  @click="availableFilter=''" class="btn btn-primary mb-3 ">clear</button>
            </div>
            <select id="available" size="10" v-model="right" multiple class="border"> 
                <option v-for="opt in availableDropDown" :key="opt.id" :value="opt.id">{{getOptionLabel(opt)}}</option>
            </select>
        </div>
    </div>
</template>
<script setup>
    import { ref, computed} from 'vue'

    const props = defineProps({
        modelValue : { type : Array, default : []},
        available: { type: Array, default: [] },
        labelField :{ type : String, default : 'label'},
        labelFieldLeft :{ type : String, default :null},
    })
    const emit = defineEmits([ 'update:modelValue' ])

    const getValue = (obj, field) => {
        const ret = field.split(".").reduce((o,i)=> o[i], obj)
        return ret
    }
    const getOptionLabel = (opt) => {
        if (typeof props.labelField == 'function') {
            try {
                return getValue(opt,props.labelField)
            } catch (e) {
                return "N/A"
            }
        }
        return getValue(opt,props.labelField)
    }
    const selectedFilter = ref("")
    const availableFilter = ref("")
    const left = ref([])
    const right = ref([])

    const toLeft = () => {
        // right contains ids, get the objects
        const rightObjs = right.value.map(pid => props.available.find(x => x.id == pid))
        emit('update:modelValue',[...props.modelValue, ...rightObjs])
    }

    const toRight = () => {
        const leftAfterRemoval = props.modelValue.filter(obj => !left.value.includes(obj.id))    
        emit('update:modelValue',[...leftAfterRemoval])
    }

    const selectedDropDown = computed(() => {
        let filterFunction = () => true // Initial pass through all
        if (selectedFilter.value != "") {
            filterFunction = (x) => x[props.labelField].toLocaleLowerCase().indexOf(selectedFilter.value.toLocaleLowerCase()) != -1
        }
        return props.modelValue.filter(filterFunction)
    })
    const availableDropDown = computed(() => {
        let filterFunction = () => true // Initial pass through all
        if (availableFilter.value != "") {
            filterFunction = (x) => getValue(x,props.labelField).toLocaleLowerCase().indexOf(availableFilter.value.toLocaleLowerCase()) != -1
        }
        const filterOutSelected = (x) => (props.modelValue.find(y => y.id == x.id)==null)
        return props.available.filter(filterOutSelected).filter(filterFunction)
    })

</script>