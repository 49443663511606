<template>
    <div >
        <button @click.stop="onShowDropDown" ref="el" class="btn btn-sm btn-primary" >
          menu <i class="ps-1 fa fa-chevron-down"></i>
        </button>
        <ul @focusout.stop="handleFocusOut" class="px-4 -ml-36 mt-0 py-2 position-absolute bg-white rounded-lg "  style="z-index : 2000;" v-if="showDropdown" >
            <slot name="default" :action="onAction"></slot>
        </ul>
    </div>
</template>
<script setup>
import { ref } from 'vue'
const emit = defineEmits(['action'])
const showDropdown = ref(false)
const el = ref(null)
const handleFocusOut = () => {
  showDropdown.value = false
}

// eslint-disable-next-line no-unused-vars
const onAction = (action,id) => {
  emit('action',{action , id})
}
const onShowDropDown = () => {
  showDropdown.value = true
}
</script>