<template>
    <div>
    <modal v-model="showAssignCategoryDialog" title="Assign a category" @ok="onCategoryAssign">
        <label>Select a category</label>
        <v-select id="categoryid" v-model="addCategoryID" :reduce="sel => sel.id" :options="getPossibleCategories" >
        </v-select>
        <template #footer="{ok, close}">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-primary" @click="ok">Assign</button>
        </template>
    </modal>
    <modal v-model="showCreateNewCategoryDialog" title="New category" @ok="onCategoryCreate">
        <label>Category name</label>
      <input v-model="newCategoryName" type="text" class="form-control" placeholder="Category name">

        <template #footer="{ok, close}">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-primary" @click="ok">Create</button>
        </template>
    </modal>

    <modal v-model="showCategoryEditDialog" title="Category information"   @ok="onCategorySave">
        <category-in-competition-editor  :category="selectedCategory" :competition="competition" ref="categoryInCompEditor" />
        <template #footer="{ok, close}">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-primary" @click="ok">Save</button>
        </template>
    </modal>

    <div class="w-4/5">
        <h2>Competition categories</h2>
    </div>


    <button @click="showAssignCategoryDialog=true" class="btn btn-primary">Assign a category...</button>
        
<a class="btn btn-outline-primary btn-sm mx-1" href="#" @click.prevent="showCreateNewCategoryDialog=true">or create a new category...</a>
    <oma-table class="table table-sm table-striped table-responsive text-sm table-condensed" :rows="categories" :cols="cols">
        <template #participants="{ value }">
        <span v-if="value != null">
        {{ value.length}}
        </span>
        </template>
        <template #final="{ row }">
            <div v-if="row.pivot.final_serie_id != null && row.pivot.final_serie_id != 0">
            {{ row.pivot.final_serie_id}}, finalist amount: {{ row.pivot.finalist_amount}}
            </div>
        </template>
        <template #catincomp="{ row }">
            <small>{{ row.pivot.id}}</small>
        </template>
        <template #memberprice="{ row }">
            {{ row.pivot.memberprice}}
        </template>
        <template #price="{ row }">
            {{ row.pivot.price}}
        </template>
        <template #max_participants="{ row }">
            {{ row.pivot.max_participants}}
        </template>
        <template #female="{ value }">
            <span v-if="value">Women</span>
            <span v-else>Men</span>
        </template>
        <template #actions="{ row }">
            <Dropdown @action="onAction">
                <template #default="{action}">
                    <li><a class="dropdown-item" href="#" @click.prevent="action('editCategoryInComp',row.pivot.id)">Edit category in competition</a></li>
                    <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('removeFromComp',row.pivot.id)">Remove from competition</a></li>
            
                </template>
            </Dropdown>
        </template>
    </oma-table>
    </div>

</template>

<script setup>
import { computed, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import OmaTable from '@/components/OmaTable.vue'
//import ClickToEdit from '@/components/ClickToEdit.vue'
import CategoryInCompetitionEditor from '@/components/competitions/CategoryInCompEditor.vue'
import store from '../../store/store'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Dropdown from '@/components/Dropdown.vue'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification";

const addCategoryID = ref(null)

const toast = useToast();

const selectedCategory = computed(() => {
    const cat = props.competition.categories.find(x => x.pivot.id==selectedID.value)
    return cat
})
const selectedID = ref(null)
const showCategoryEditDialog = ref(false)
const categoryInCompEditor = ref(null)
const showAssignCategoryDialog = ref(false)
const showCreateNewCategoryDialog = ref(false)
const newCategoryName = ref(null)

const onAction = ({action, id}) => {
    switch (action) {
        case 'editCategoryInComp':
            editCategoryInComp(id)
            break
        case 'removeFromComp':
            removeFromComp(id)
            break
    }
}

const removeFromComp = (id) => {
    const payload = {
        compid : props.competition.id,
        id
    }
    store.dispatch('competitions/removeCategoryFromComp',payload)
    .then(ret => toast.success(ret.message))
    .catch(err => toast.error(err.response.data.message))
}
const editCategoryInComp = (id) => {
    selectedID.value = id
    showCategoryEditDialog.value = true
}

const onCategoryCreate = () => {
   const payload = {name: newCategoryName.value, ['compid'] : props.competition.id}
   store.dispatch("competitions/addNewCategory",payload)
   .then(() => {
      toast.success("Saved")
      selectedID.value = null 
      showCreateNewCategoryDialog.value = false
    })
    .catch(err => {
      toast.error(handleValidationErrors(err))
    })
}
const onCategoryAssign = () => {
   const payload = {id: addCategoryID.value, ['compid'] : props.competition.id}
   store.dispatch("competitions/assignCategoryToComp",payload)
   .then(() => {
      toast.success("Saved")
      selectedID.value = null 
      showAssignCategoryDialog.value = false
    })
    .catch(err => {
      toast.error(handleValidationErrors(err))
    })
}
const onCategorySave = () => {
   const editedCategoryInfo = categoryInCompEditor.value.value
   const payload = {...editedCategoryInfo.pivot, ['compid'] : props.competition.id}
   store.dispatch("competitions/updateCategoryInComp",payload)
   .then(() => {
      showCategoryEditDialog.value = false
      toast.success("Saved")
      selectedID.value = null 
    })
    .catch(err => {
    showCategoryEditDialog.value = false
      toast.error(handleValidationErrors(err))
    })

}
const props = defineProps({
    'competition' : {type : Object, default : null},
})


const cols = [
    { id: 'id' },
    { id: 'catincomp' },
    { id: 'nimi' ,sortable : true},
    { id: 'participants', label : 'Participants' ,sortable : true},
    { id: 'max_participants', label : 'Max. participants' ,sortable : true},
    { id: 'price', sortable : true},
    { id: 'memberprice', },
    { id: 'final', label : 'Final results from'},
    { id: 'actions' },

]
const categories = computed(() => props.competition.categories)
const getPossibleCategories = computed(() => {
    return props.competition.all_categories.map(x => ({id : x.id, label : x.nimi}))
})


</script>
