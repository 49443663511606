<template>
    <div class="container" >
        <modal v-model="showEditDialog" title="Wall editor" ok-title="Save" @ok="onSaveWall" size="md">
            <wall-editor :id="selectedWall" ref="refWallEditor" />
        </modal>
      <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
        <h1 class="page-header">Walls <small><button class="btn btn-sm btn-primary mt-3 ms-3" @click.prevent="addNewWall" > Add new...</button></small></h1>
        <p class="lead">Set up the walls/sectors you have in your gym. You can add also gym floor plans 
        for easier navigation in the mobile application.
        <br /><br />
        If you want to setup <strong>floor plans</strong>, they are under the manager menu.</p>
        <oma-table v-if="walls!= null && walls.length > 0" class="table table-sm table-striped table-responsive text-sm table-condensed" :rows="walls" :cols="cols">
          <template #image="{row}">
            <a href="#" @click.prevent="openImageEditor(row.id)"> <i  class="fa fa-2x fa-camera text-danger"></i> </a>
          </template>
          <template #actions="{row}">
            <Dropdown @action="onAction">
              <template #default="{action}">
                <li> <a class="dropdown-item" href="#" @click.prevent="action('edit',row.id)">Edit...</a> </li>
                <li> <a class="dropdown-item" :href="webendpoint+`wall/`+row.id+`/printa4`" target="_print">Print A4...</a> </li>
                <li><a class="text-warning dropdown-item" href="#" @click.prevent="action('remove_problems',row.id)">Remove all problems</a></li>
                <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('del_wall',row.id)">Remove</a></li>
              </template>
            </Dropdown>
          </template>
          </oma-table>
          </div>
          </div><!-- end of row --></template>
<script setup>
// eslint-disable-next-line no-unused-vars
import OmaTable from '@/components/OmaTable.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
import Modal from '@/components/Modal.vue'
import { webendpoint } from '@/helpers/problemator'
import WallEditor from '@/components/WallEditor.vue'
import Dropdown from '@/components/Dropdown.vue'

const selectedWall = ref(null)
const showEditDialog = ref(false)
const refWallEditor = ref(null)

const toast = useToast()
const cols = [
  { id: 'image' },
  { id: 'id', sortable: true },
  { id: 'wallchar', sortable: true },
  { id: 'walldesc', sortable: true },
  { id: 'problems_count', label: 'All time problems', sortable: true },
  { id: 'ascents_count', sortable: true },
  { id: 'sort', sortable: true },
  { id: 'actions' },
]
const addNewWall = () => {
  selectedWall.value = null
  showEditDialog.value = true
}
const onAction = ({action, id}) => {
  switch (action) {
    case 'edit':
      editWall(id)
      break
    case 'remove_problems':
      removeProblems(id)
      break
    case 'del_wall':
      delWall(id)
      break
  }

}
const editWall = (id) => {
  selectedWall.value = id
  showEditDialog.value = true
}
const removeProblems = (id) => {
  if (confirm("Are you sure you want to delete all the problems from this wall?")) {
    store.dispatch('walls/deleteWallProblems', { id })
      .then(ret => {
        console.log(ret)
        toast.success("Deleted")
      })
      .catch(err => {
        toast.error(handleValidationErrors(err))
      })
  }

}
const delWall = (id) => {
  if (confirm("Are you sure you want to delete this wall?")) {
    store.dispatch('walls/deleteWall', { id })
      .then(ret => {
        console.log(ret)
        toast.success("Deleted")
      })
      .catch(err => {
        toast.error(handleValidationErrors(err))
      })
  }
}
const onSaveWall = () => {
  refWallEditor.value.save()
    .then(ret => {
      console.log(ret)
      selectedWall.value = null
      showEditDialog.value = false
      refWallEditor.value.reset()
      toast.success("Saved")
    })
    .catch(err => {
      toast.error(handleValidationErrors(err))
    })

}
const walls = computed(() => store.state.walls.all)
const store = useStore()
if (walls.value.length == 0) {
  store.dispatch('walls/all')
}


</script>
  