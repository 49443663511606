<template>
  <div class="">
    <div class="flex items-center justify-center h-16 bg-gray-800 my-1 py-3">
      <button
        v-for="(tab, index) in tabs"
        :key="tab.id"
        :class="[
        'text-white hover:text-gray-200 mx-4 focus:outline-none',
        activeTabIndex === index ? 'font-bold border-b-2 border-white' : ''
        ]"
        @click="setActiveTabIndex(index)"
        >
        {{ tab.name }}
    </button>
  </div>
  <div v-if="activeSerie" class="py-4">
      <h2 class="text-lg font-bold">{{ activeSerie.nimi }}</h2>
      <ul class="list-disc pl-6">
        <li>ID : {{ activeSerie.id }}</li>
        <li>Final serie id: {{ activeSerie.pivot.final_serie_id }}</li>
        <li>Finalist amount: {{ activeSerie.pivot.finalist_amount }}</li>
        <li>Price: {{ activeSerie.pivot.price }}</li>
        <li>Semifinalist amount: {{ activeSerie.pivot.semifinalist_amount }}</li>
      </ul>
      You can move the finalists to the target competition, IF the final serie id is set.
      <br />
      <button @click="sendFinalists" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300">
        Send the finalists to the final competition
      </button>
    </div>
    {{ Object.keys(finalists).length }} finalist(s) selected
    <br />

      <button @click="setFinalists" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 m-1">
        select finalists
      </button>
      <button @click="deselect" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 m-1">
        deselect
      </button>
  <table v-if="activeTabData.length" class="table-auto">
    <thead>
      <tr>

        <th class="px-4 py-2">sel</th>
        <th class="px-4 py-2">id</th>
        <th class="px-4 py-2"># shirt</th>
        <th class="px-4 py-2">Standing</th>
        <th class="px-4 py-2">Name</th>
        <th class="px-4 py-2">Team</th>
        <th class="px-4 py-2">Amount Tops</th>
        <th class="px-4 py-2">Tries Top</th>
        <th class="px-4 py-2">Amount Bonuses</th>
        <th class="px-4 py-2">Tries Bonus</th>
        <th class="px-4 py-2">Points</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in activeTabData" :key="index" :class="getRowClass(data)" >
        <td class="border px-4 py-2"><input type="checkbox"  :value="data.climber.id"  v-model="finalists[ data.climber.id ]" /></td>
        <td class="border px-4 py-2"><label for="isfinalist">{{ data.climber.id }}</label></td>
        <td class="border px-4 py-2">{{ data.climber.pivot?.tshirtnumber }}</td>
        <td class="border px-4 py-2">{{ data.standing }}</td>
        <td class="border px-4 py-2">{{ data.climber.etunimi }} {{ data.climber.sukunimi }}</td>
        <td class="border px-4 py-2">{{ data.climber.team }}</td>
        <td class="border px-4 py-2">{{ data.amountTops }}</td>
        <td class="border px-4 py-2">{{ data.triesTop }}</td>
        <td class="border px-4 py-2">{{ data.amountBonuses }}</td>
        <td class="border px-4 py-2">{{ data.triesBonus }}</td>
        <td class="border px-4 py-2">{{ data.points }}</td>
      </tr>
    </tbody>
  </table>
  <div v-else class="flex items-center justify-center h-full">
    No data available
  </div>
</div>
</template>
<script setup>
import {  computed,  ref } from 'vue'
import store from '../../store/store'
import { useToast } from "vue-toastification"
const toast = useToast()

const finalists = ref({})
const deselect = () => {
    finalists.value = []
  }
const getRowClass = (row) => {
    if (row.isFinalist) {
      return ["bg-green-500"]
    }
  }
const results = computed(() => store.state.competitions.results)
const comp = computed(() => store.state.competitions.competition)

const tabs = computed(() => {
  if (results.value.categories == null) {
      return []
    }
  return Object.keys(results.value.categories).map((key) => {
      const cat = results.value.categories[key]
      return {id : cat.id, name : cat.nimi}
      })
})

const sendFinalists = () => {
  if (activeSerie.value.pivot.final_serie_id == null) {
    alert("I have no idea where to send the finalists to. Set the final serie id for this category and try again")
    return

  }

//let standings = []
// Gather standings, round results and starrt position and use them
// for the next round

  const fin =  Object.keys(finalists.value).filter(key => {
    if (key == undefined || key == "undefined") {
        return false
      }
    const item = finalists.value[key]
    return item
    
    })
  const createLastRoundResults = (item) => {
    return item.amountTops + "t" + item.amountBonuses + "z " + item.triesTop + "t" + item.triesBonus + "z"
    }
  const getFinalistPayload = (fin) => {
    // these are in activetabdata
    //const amountClimbers = activeTabData.value.length +1 
    const amountClimbers = Object.keys(finalists.value).length +1
    const payload = activeTabData.value.filter(item => fin.includes(item.climber.id.toString()))
    .map((item) => {
        return {
        finalist_id : item.climber.id,
        lastroundresults : createLastRoundResults(item),
        lastroundposition : item.standing,
        startposition : (amountClimbers - item.standing),
      }
    })
    return payload
    }
  const payload = {
      target_serie : activeSerie.value.pivot.final_serie_id,
      finalists : getFinalistPayload(fin),
      compid : comp.value.id,
    }
    console.log("Sending",finalists)
    store.dispatch("competitions/sendFinalists",payload)
    .then(ret => {
      toast.success(ret.message)
      })
  }
const activeTabIndex = ref(0);
const activeSerie= ref(null)

const setActiveTabIndex = (index) => {
  activeTabIndex.value = index;
}

/** Constructs the active tab data **/
const activeTabData = computed(() => {
    const tabIndex = activeTabIndex.value
    if (results.value.categories == null) {
        return  []
      }

    const activeSerieId = Object.keys(results.value.categories)[tabIndex]

    activeSerie.value = results.value.categories[activeSerieId]

    const resObj =  results.value.results[activeSerieId]

    if (resObj == null) {
      return []
    }
    const ret =  Object.keys(resObj.results).map(key => resObj.results[key])
    if (ret == null) {
        toast.error("No results for serie "+activeSerie.value.nimi)
        return
      }

    const sorted = ret.sort((a,b) => a.standing-b.standing)
    // Now map into the data the contenders who are going into the finals

    const mapped = sorted.map((contender, index) => {
        const finalistAmount  = parseInt(activeSerie.value.pivot.finalist_amount)
        let isFinalist = false
        if (!isNaN(finalistAmount) && index < finalistAmount) {
          isFinalist = true
        }
        return {...contender, isFinalist}
      })


    return mapped
}); // activeTabData


const setFinalists = () => {
  finalists.value = activeTabData.value
    .filter(item => {
        if (item.climber.id != "deleted" && item.isFinalist) {
        return true
        }
        return false
        })
  .reduce((acc, item) => {
      acc[ item.climber.id ] = true
      return acc
      },{})
}

</script>
