<template>
    <div>
        <h1>Pick a random prize winner</h1>
        <div v-if="competition.paidregistrations.length == 0" class="card bg-danger"><div class="card-body"> BTW. There are no <strong>paid</strong> contenders in this competition, meaning the lottery is useless.</div></div>
        <label>Category</label>
        <v-select id="serieid" v-model="serieid" :reduce="sel => sel.id" :options="getCategoriesDropDown"> </v-select>

        <label> Amount of prize winners to show: </label>
        <input class="form-control" type="text" name="amount" id="res_amount" size="1" v-model="winnerAmount" />

        <checkbox id="ticksonly" type="checkbox" name="ticksonly" value="1" v-model="withTicksOnly">
            Include ONLY competitors who ticked some problems
        </checkbox>

        <button id="prize" type="button" class="btn btn-primary button " @click="randomize">Randomize!</button>

        <ul class="w-full md:w-1/4">
            <li v-for="winner in results" :key="winner.id" class="flex flex-row justify-between">
              <div>{{ winner.id }}</div><div> {{ winner.etunimi }} {{ winner.sukunimi }}</div> 
              <div>{{ winner.pivot.prizewon }}</div>
              <button v-if="!checkIfPrizeWon(winner.pivot.id)" class="btn btn-sm btn-info" @click="() => markPrizeReceived(winner.id, winner.pivot.id)">mark as a winner</button>
              <button v-else class="btn btn-sm btn-secondary" disabled  >Prize already won</button>
            </li>
        </ul>

    </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import Checkbox from '@/components/MaterialCheckbox.vue'
import store from '../../store/store'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const props = defineProps({
    'competition': { type: Object, default: null },
})
const shuffleArray = (arr) => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
    }
    return newArr
}
const checkIfPrizeWon = (pivotid) => {
    const contender = props.competition.paidregistrations.find(x => x.pivot.id == pivotid)
    if (contender != null && contender.pivot.prizewon == 1) {
        return true
    }
    return false
}
const withTicksOnly = ref(true)
const winnerAmount = ref(10)
const serieid = ref('all')
const results = ref([])
const getCategoriesDropDown = computed(() => [{ id: 'all', label: 'All categories' }, ...props.competition.categories.map(x => ({ id: x.id, label: x.nimi }))])

const markPrizeReceived = (contenderid, id) => {
    const payload = {
        compid : props.competition.id,
        prizewon : 1,
        contenderid , 
        id : id,
        paid : true,
    }
    store.dispatch('competitions/saveContenderExtraInfo', payload)
}
const randomize = () => {
    const contenders = props.competition.paidregistrations
    results.value = shuffleArray(contenders).filter(x => x.pivot.prizewon ==0).slice(0, Math.min(winnerAmount.value, contenders.length))
}
</script>
