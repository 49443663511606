
<template>
  <div v-if="circuitAscentsByClimber != null">

    <div class="row">
      <div class="col-md-12 col-12">

        <oma-table :rows="tableCircuitAscents" :columns="columns" class="table table-striped">
          <template #climber="{row}">
            <div v-if="row.climber != null">
              {{ row.climber?.etunimi }} {{ row.climber?.sukunimi }}
            </div>
          </template>
          <template #ascentCount="{row}">
            {{ row.ascentCount }}
          </template>
          <template #actions="{row}">
            <button class="btn btn-sm btn-info" @click="selectedClimber=row.climber.id;showAscentListDialog=true">show ascents</button>
            <div v-if="row.climber != null">
              <div v-if="selectedClimber == row.climber.id && showAscentListDialog">
                <button class="btn btn-sm btn-success" @click="showAscentListDialog=false">close</button>
                <ul>
                  <li v-for="asc in ascentsForClimber(row.climber.id)" :key="asc.id">
                    {{ asc.tstamp }}
                    {{ asc.problem.tag }}
                    {{ asc.problem.grade.name }}
                    {{ asc.problem.colour.name }}
                  </li>
                </ul>
                <button class="btn btn-sm btn-success" @click="showAscentListDialog=false">close</button>
              </div>
            </div>
          </template>
        </oma-table>

      </div>

    </div>


  </div>
  <div v-else>Loading...</div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import OmaTable from '@/components/OmaTable.vue'

const showAscentListDialog = ref(false)
const selectedClimber = ref(null)
const columns = [
  { id: 'climber', sortable : true},
  { id: 'ascentCount', sortable : true},
  { id: 'actions', },
]
const store = useStore()
const props = defineProps({ 'id': String })
const ascentsForClimber = (climberid) => {
  const ascents = circuitAscentsByClimber.value[climberid]
  return ascents
}
const tableCircuitAscents = computed(() => {
  return Object.keys(circuitAscentsByClimber.value).map(climberid => {
    const ascents = circuitAscentsByClimber.value[climberid]
    const ascentCount = ascents.length
    // Get climber name
    const anAscent = ascents.find(x => x != null)
    const climber = anAscent.climber
    return { climber, ascentCount }
  }).sort((a, b) => b.ascentCount - a.ascentCount)
})
const circuitAscentsByClimber = computed(() => store.state.circuitAscentsByClimber)
store.dispatch('getCircuitAscents', props.id)

</script>


