<template>
    <div v-if="modelValue" class="modal fade show" tabindex="-1"  style="display : block !important;">
      <div :class="getDialogStyles">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><slot name="title">{{ title }}</slot></h5>
            <button type="button" class="text-black btn "  aria-label="Close" @click.prevent="close"><font-awesome-icon size="lg" icon=" fa-xmark" /></button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer" :close="close" :ok="ok">
            <button  v-if="okOnly == null || okOnly == false" type="button" class="btn btn-secondary" @click="close" >{{ closeTitle }}</button>
              <button type="button" class="btn btn-primary" @click="ok">{{ okTitle }}</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  components : {
  },
  props: {
    closeTitle : {
      type : String,
      default : 'Close'
    },
    okOnly : {
      type : Boolean,
      default : false
    },
    okTitle : {
      type : String,
      default : 'ok'
    },
    'modelValue' : {
     type : Boolean,
    default : false,
    },
    size : {
      type : String,
      default : '',
    },
    closeOnOk : {
      type : Boolean,
      default : false
    },
    title : {
      type : String,
      default : 'Modal title',
    },

  },
  emits: ['update:modelValue','ok'],
  data : function() {
     return {
     }
  },
  computed : {
    getDialogStyles() {
       return {
         'modal-dialog' : true,
         'modal-sm' : this.size =='sm',
         'modal-lg' : this.size =='lg',
         'modal-xl' : this.size =='xl',
       }
    }
  },
  mounted() {


  },
  methods : {
     close() {
        this.$emit('update:modelValue',false)
     },
    ok() {
        if (this.closeOnOk) {
          this.$emit('update:modelValue',false)
        }
        this.$emit('ok',this.modelValue)
    }
  },
}
</script>

