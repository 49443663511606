<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-6">
        <h5 class="mb-0">Wall twister</h5>
        <p class="mb-0 text-sm">
          Something fun for you parties
        </p>
      </div>
    </div>

    <div v-if="showColourConfig" class="absolute left-10 top-10 bg-white p-3 border border-2 w-1/4">
      <h2 class="font-bold my-1">Colour configurator<small @click.prevent="showColourConfig = false" class="text-red-400 text-sm mx-1"> close X</small></h2>
      <div class="my-1">The colours are automatically saved to YOUR browser settings. If you use this with an another computer,
        the colours will be reset. </div>

      <div class="border p-1">
        <table width="100%">
          <thead>
            <tr class="bg-blue-200 ">
              <th>Colour </th>
              <th>Code</th>
              <th>actions</th>
            </tr>
          </thead>
          <tr v-for="col in colours" :key="col.name">
            <td>{{ col.name }}</td>
            <td>{{ col.code }}</td>
            <td><a href="#" @click.prevent="removeColour(col.name)" class="text-red-500 text-sm">remove</a></td>
          </tr>
        </table>
      </div>
      <strong class="block my-1">Add new colour by selecting it from the picker below</strong>
      <button class="block my-1 bg-green-400 border px-4 py-1" @click="addColour">Add selected colour</button>
      {{ selectedColour?.hex }}

      <input class="border px-4 my-1 py-1 block" type="text" name="name" v-model="selectedName" placeholder="Give colour a name" />
      <color-picker theme="light" :color="selectedColour" :sucker-hide="false" @changeColor="changeColor" />

    </div>

    <div class="mt-4 row">


      <div class="col-md-9 col-12">

        <div class="flex flex-col items-center  gap-4">
          <div>
            <h1 v-if="limb != null" class="bigText font-bold uppercase">{{ limb.name }}</h1>
          </div>
          <div>
            <h1 v-if="colour != null" class="bigText font-bold uppercase" :style="{ color: colour.code }">{{ colour.name }}</h1>
          </div>
          <div>
            <button @click="randomize" class="text-7xl px-12 py-4 text-center uppercase font-bold text-blue-100 bg-blue-600 rounded-md">Randomize!</button>
            <br />
            <a href="#" @click.prevent="configureColours">Configure colours</a>

          </div>

        </div>




      </div>

      <div class="col-md-3 ">
        <p class="my-1">Limb weights:</p>
        <small class="block">Reduced weight</small>
        <input type="text" v-model="reducedWeight" class="border px-2 py-1 w-12" />
        <ul>
          <li v-for="limb in limbs" :key="limb.name">{{ limb.name }} - {{ limb.weight }}</li>
        </ul>

        <p class="my-1">History:</p>
        <ul>
          <li v-for="line in history" :key="line.limb.name">{{ line.limb.name }} - {{ line.colour.name }}</li>
        </ul>
      </div>

    </div>



  </div>
</template>

<script setup >
import { onMounted, ref } from 'vue'
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'

const selectedColour = ref('#59c7f9')

const limbs = ref([
  {
    name: 'left foot',
    weight: 100,
  },
  {
    name: 'right foot',
    weight: 100,
  },
  {
    name: 'left hand',
    weight: 100,
  },
  {
    name: 'right hand',
    weight: 100,
  },
])
const colours = ref([
  {
    name: 'blue',
    code: "#0000ff"
  },
  {
    name: 'red',
    code: "#ff0000"
  },
  {
    name: 'green',
    code: "#00ff00"
  },
])
const addColour = () => {
  const newColour = {
    name: selectedName.value,
    code: selectedColour.value.hex
  }
  colours.value = [newColour, ...colours.value]
  selectedName.value = ""
  // Save these to localStorage
  localStorage.setItem("savedCols", JSON.stringify(colours.value))
}
const removeColour = (colname) => {
  colours.value = colours.value.filter(x => x.name != colname)
  localStorage.setItem("savedCols", JSON.stringify(colours.value))

}
const showColourConfig = ref(false)
const selectedName = ref("")
const limb = ref(null)
const colour = ref(null)
const reducedWeight = ref(40)
const changeColor = (col) => {
  selectedColour.value = col
}
const getRandomWeightedValue = (options) => {
  const totalSum = options.reduce((acc, item) => acc + item.weight, 0)

  let runningTotal = 0
  const cumulativeValues = options.map((item) => {
    const relativeValue = item.weight / totalSum
    const cv = {
      key: item,
      value: relativeValue + runningTotal
    }
    runningTotal += relativeValue
    return cv
  })
  const r = Math.random()
  return cumulativeValues.find(({ value }) => r <= value).key
}

const configureColours = () => {
  showColourConfig.value = !showColourConfig.value
}
const previousLimb = ref(null)

const history = ref([])
const randomize = () => {
  previousLimb.value = limb.value
  // Find value between 0 and totalWeight
  limb.value = getRandomWeightedValue(limbs.value)

  // Change weight for this limb
  limbs.value = limbs.value.map(x => {
    if (x.name == limb.value.name) {
      x = { ...x, weight: parseInt(reducedWeight.value) }
    } else {
      // Move back to 100
      x = { ...x, weight: 100 }
    }
    return x
  })

  //limb.value = limbs.value[parseInt(Math.random()*limbs.value.length)]
  colour.value = colours.value[parseInt(Math.random() * colours.value.length)]

  history.value = [{ limb: limb.value, colour: colour.value }, ...history.value]
}
randomize()
onMounted(() => {
  // Take colours from localStorage if any.
  const savedCols = JSON.parse(localStorage.getItem("savedCols"))
  if (savedCols != null) {
    colours.value = savedCols
  }


})

</script>

<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bigText {
    font-size: 3rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bigText {
    font-size: 8rem;
  }


}
</style>