<template>
  <div :style="getStyles"></div>
</template>
<script setup>
import { computed } from 'vue'
 const props = defineProps({
    bg : { type : String, default : "#000"},
    text : { type : String, default : "#fff"},
    size : { type : Number, default : 32}
 })
const getStyles = computed(() => {
  return {
    'background-color' : props.bg,
    'color' : props.text,
    'width' : props.size+"px",
    'height' : props.size+"px",
  }
})
</script>
