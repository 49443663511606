<script setup>
import { useStore } from 'vuex'
import { onMounted, ref, computed  } from 'vue'
import Modal from '@/components/Modal.vue'
import SlimProblemList from '@/components/SlimProblemList.vue'
import {
  Bar,
} from 'vue-chartjs'

const loading = ref(true)
const selectedGrade = ref(null)
const store = useStore()
store.dispatch('getProblems').then(() => {
    loading.value = false
})
onMounted(() => {

})
const showProblemList = ref(false)
const filteredProblems = computed(() => {
    if (selectedGrade.value == null) {
        return store.state.problems
    }
    // Todo: Support fort other grades
    debugger
    return store.state.problems.filter(x => x.grade.font == selectedGrade.value.toString().toLowerCase())
})

//const problems = computed(() => store.state.problems)

const c1 = ref(null)
const c2 = ref(null)
const onClick = (event) => {

      /** Finds out which grade is clicked... */
      //const c1val = c1.value
      const chart = c1.value.chart
      if (chart == null) {
        return
      }
      const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
      let first = null
      let gradeName = null
      if (points.length > 0) {
        first = points[0]
        const index = first.index
        const labels = store.state.basic.charts.gradebars_boulder.labels
        gradeName = labels[index]
      }
      if (gradeName != null) {
        // Fetch which problems are with this grade.
        selectedGrade.value = gradeName
        showProblemList.value = true
      }
      
}
const total = computed(() => store.state.basic.charts.gradebars_boulder.total)
const data = computed(() => ( {
    'labels' : store.state.basic.charts.gradebars_boulder.labels,
    'datasets' : store.state.basic.charts.gradebars_boulder.datasets
} ))

const data2 = computed(() => ( {
    'labels' : store.state.basic.charts.ascents_boulder.labels,
    'datasets' : store.state.basic.charts.ascents_boulder.datasets
} ))

</script>
<template>
    <div class="card mb-4">
        <Modal v-model="showProblemList" size="lg" :title="'Show problems of selected grade ('+selectedGrade+')'">
            <SlimProblemList :problems="filteredProblems" :grade="selectedGrade" />
        </Modal>
        <div class="d-flex">
            <div class="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4">
                <i class="material-icons opacity-10" aria-hidden="true">list</i>
            </div>
            <div class="position-relative">
                <h6 class="mt-3 mb-2 ms-3 fs-4">Boulders and ascents <small>({{ total }})</small></h6>
                Click on a bar to list the problems of that grade.
            </div>
        </div>
        <div class="card-body p-3">
            <div  v-if="data.datasets != null" style="max-width: 400px">
                <Bar ref="c1" :chart-data="data" chart-id="c1" :width="400" :height="200" @click="onClick" />
                <Bar ref="c2" :chart-data="data2" chart-id="c2" :width="400" :height="200" />
            </div>
        </div>
    </div>
</template>

