<template>
    <modal v-model="showEditDialog" title="Problem Aging Editor" ok-title="Save" @ok="onSave" size="md">
        <aging-editor :id="selectedRow" ref="refEditor" />
    </modal>

<div class="container">
    <h2 class="page-header">Problem aging configuration <small class="fs-6 text-muted">{{ rows.length}} rule(s) active <a href="#" @click.prevent="addNew" class="btn btn-sm btn-primary">Add new...</a></small></h2>
    <p>With problem aging rules you can set certain grades for a routetype to have faster circulation in the
        gym. On the problem list, there's a value called PAI (=Problemator aging index) which tells you how soon
        the route should be replaced. Values closing to 100 are the next to be replaced, values <strong>over</strong>
        100 should be replaced immediately. </p>
    <p>
        Example: The default aging for a sport route is 16 weeks. If for example grades between 6a and 6b start to
        get <i>dirty</i> tags way before that, one might want to adjust the circulation interval for those grades.
        <br><br>
        By setting aging time for 6a, 6a+ and 6b to 10 weeks, PAI value tells you in the problem list that those
        grades should be replaced faster, thus overriding the default 16 week interval.
    </p>
    <oma-table
    :cols="cols"
    :rows="rows"
    >
    <template #active="{value}">
        <i v-if="value==1" class="fa fa-check text-success"></i>
        <i v-else class="fa fa-x-mark text-danger"></i>
    </template>
      <template #actions="{row}">
        <Dropdown @action="onAction">
          <template #default="{action}">
            <li> <a class="dropdown-item" href="#" @click.prevent="action('editRow',row.id)">Edit...</a> </li>
            <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('del',row.id)">Remove</a></li>
          </template>
        </Dropdown>
      </template>
    </oma-table>
    
</div></template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
import Modal from '@/components/Modal.vue'
//import { webendpoint } from '@/helpers/problemator'
import AgingEditor from '@/components/AgingEditor.vue'
import OmaTable from '../../components/OmaTable.vue'
import Dropdown from '@/components/Dropdown.vue'

const selectedRow = ref(null)
const showEditDialog = ref(false)
const refEditor = ref(null)
const toast = useToast()
const onAction = ({ action, id }) => {
  switch (action) {
    case 'editRow':
      editRow(id)
      break

    case 'del':
      del(id)
      break
  }
}

const cols = [
{id : 'id'},
{id : 'grade.name'},
{id : 'routetype'},
{id : 'aging'},
{id : 'timeunit'},
{id : 'active'},
{id : 'actions'},
]
const del= (id) => {
  if (confirm("Are you sure you want to delete this item?")) {
    store.dispatch('aging/delete', { id })
      .then(ret => {
        console.log(ret)
        toast.success("Deleted")
      })
      .catch(err => {
        toast.error(handleValidationErrors(err))
      })
  }
}
const addNew= () =>{
  selectedRow.value = null
  showEditDialog.value = true
}
const editRow= (id) => {
  selectedRow.value = id
  showEditDialog.value = true
}
const onSave= () => {
  refEditor.value.save()
      .then(ret => {
          console.log(ret)
          selectedRow.value = null
          showEditDialog.value = false
          refEditor.value.reset()
          toast.success("Saved")
      })
      .catch(err => {
          toast.error(handleValidationErrors(err))
      })
  
}
const store = useStore()
const rows = computed(() => store.state.aging.all)
store.dispatch('aging/all')

</script>
