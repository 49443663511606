<template>
<div>
<modal v-model="showAscentsDialog" size="lg" title="Problem ascents"  close-title="Close" ok-title="Proceed" >
    <div class="card"><div class="card-body">If you need to delete ascents, go to enter points, search for a climber and remove the ascents from there.</div></div>
    <div class="my-2">{{ problemAscents.length}} ascent(s)</div>
    <oma-table class="table table-sm table-striped table-responsive text-sm table-condensed" :rows="problemAscents" :cols="problemAscentCols">
    <template #climber="{ value }">
        {{ value.etunimi }} {{ value.sukunimi }}
    </template>
    </oma-table>
    <template #footer="{ close}">
        <button type="button" class="btn btn-primary" @click="close">Close</button>
    </template>
</modal>
<modal v-model="showAttachProblemsDialog" size="lg" title="Attach problems"  close-title="Close" ok-title="Proceed" @ok="onProblemAttach">
    <div class="card text-dark bg-info "><div class="card-body">Hey, you can select all by clicking CTRL-A (or ⌘-A on Mac) when you have clicked the Problems selectbox. Or cherry pick by using CTRL+click a row (⌘+row on a Mac. Or ranges with SHIFT key pressed while choosing start and end.)</div></div>
    <column-selector  v-model="selectedProblems" :available="gymProblems" />
    <template #footer="{ok, close}">
        <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
        <button type="button" class="btn btn-primary" @click="ok">Save</button>
    </template>
</modal>
<modal v-model="showProblemEditDialog" title="Problem information"  close-title="Close" ok-title="Proceed" @ok="onProblemSave">
    <problem-in-competition-editor  :problem="selectedProblem" :competition="competition" ref="problemInCompEditor" />
    <template #footer="{ok, close}">
        <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
        <button type="button" class="btn btn-primary" @click="ok">Save</button>
    </template>
</modal>

<div class="w-4/5">
    <h2>Competition problems</h2>
    <p>The actual problems/routes are not added here. Here you will only attach existing problems/routes
    to a competition</p>

    <div class="alert alert-info alert-success" role="alert">
        The number means the route number which is usually printed on a paper an attached next to the problem/route. It's usually 
        something like. Men's Final Route 1 --&gt; M1. Or Women Final Route #2 --&gt; W2. Sometimes just simply 1, 2, 3 ...
    </div>
    
    
</div>


<button class="btn btn-primary btn-lg btn-block" @click="showAssignProblemDialog">Attach problems to this competition...</button>
<div class="card my-1">
    <div class="card-body">
        Click a route number to quickly edit its value
    </div>
</div>
<div class="card my-1">
    <div class="card-body">
      Please refer to the manual how to add routes in competitions which have more than one phase (= qualifying/semifinal/final).

      <a href="https://manual.problemator.fi/competitions/routes_categories.html">Open manual</a>
      <br />
      <br />
      <strong class="text-red">THIS IS IMPORTANT!</strong> If you are using IFSC2018 Bouldering rules, YOU MUST bind the routes to categories. Otherwise you will NOT see any results!
    </div>
</div>
<oma-table class="table table-sm table-striped table-responsive text-sm table-condensed" :rows="problems" :cols="cols">
    <template #wall="{ value }">
        <strong>{{ value.wallchar }}</strong>
        {{ value.walldesc }}
    </template>
    <template #num="{ row }">
        <click-to-edit ok-title="Save" :id="row.pivot.id" @ok="(data) => onEditNum(data)">{{ row.pivot.num }}</click-to-edit>
    </template>
    <template #grade="{ value }">
        <strong>{{ value.name }}</strong>
    </template>
    <template #name="{ row }">
        {{ row.tag?.substr(7) }}
    </template>
    <template #colour="{ value }">
        <div class="flex flex-row gap-1">
            <colour-box :bg="value.code" size="20" />
            {{ value.name }}
        </div>
    </template>
    <template #actions="{ row }">
        <Dropdown @action="onAction">
            <template #default="{action}">

                <li><a class="dropdown-item" href="#" @click.prevent="action('editProblemInComp',row.id)">Edit problem in competition</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="action('showAscents',row.id)">Show who climbed this problem</a></li>
                <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('removeFromComp',row.pivot.id)">Remove from competition</a></li>
            </template>
        </Dropdown>
    </template>
    <template #boundtocategory="{ row}">
        <span v-if=" getPossibleCategoryBinding(row.pivot.bind_to_category)!= null">
            <span class="fa fa-link"></span>
            {{ getPossibleCategoryBinding(row.pivot.bind_to_category) }}, {{ row.pivot.routetype }}
        </span>
        <span v-else>
        </span>
    </template>
</oma-table>
</div>

</template>

<script setup>
import { computed, ref } from 'vue'
import ColourBox from '@/components/ColourBox.vue'
import Modal from '@/components/Modal.vue'
import OmaTable from '@/components/OmaTable.vue'
import ColumnSelector from '@/components/ColumnSelector.vue'
import ClickToEdit from '@/components/ClickToEdit.vue'
import ProblemInCompetitionEditor from '@/components/competitions/ProblemInCompetitionEditor.vue'
import store from '../../store/store'
import Dropdown from '@/components/Dropdown.vue'

const selectedProblems = ref([])
const gymProblems = computed(() => Object.keys(store.state.problems).map(key => {
    const x = store.state.problems[key]
    // Include tag in map, because it will be used in sorting later
    let author = null
    if (x.author != null && x.author.nick != null) {
        author = x.author.nick
    }
    return { id: x.id, 'label': x.tag?.substr(7) + " " + x.grade.name + " " + x.colour.name + " by " + author, tag: x.tag }
}).sort((a, b) => {
    // Sort by tag
    const aTag = a.tag != null ? a.tag : ""
    return aTag.localeCompare(b.tag)
})
)
const showAscentsDialog = ref(false)
const showAssignProblemDialog = () => {
    showAttachProblemsDialog.value = true
    // Finds the problems shared between the gyms this competition is 
    // shared with.
    // Initialize the selected problems...
    selectedProblems.value = props.competition.problems.map(x => {
        let author = null
        if (x.author != null && x.author.nick != null) {
            author = x.author.nick
        }
        return { id: x.id, 'label': x.tag?.substring(7) + " " + x.grade.name + " " + x.colour.name + " by " + author }
    })
    store.dispatch('getProblems', { compid: props.competition.id })
}
/*
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification";

const toast = useToast();
*/
const onEditNum = ({ id, value }) => {
    const payload = { compid: props.competition.id, id, 'num': value }
    store.dispatch("competitions/changeProblemInComp", payload)
}

const selectedProblem = computed(() => {
    return props.competition.problems.find(x => x.id == selectedID.value)
})
const selectedID = ref(null)
const showAttachProblemsDialog = ref(false)
const showProblemEditDialog = ref(false)
const problemInCompEditor = ref(null)
const removeFromComp = (id) => {
    if (confirm("Are you sure?")) {
        const payload = {
            compid: props.competition.id,
            id
        }
        store.dispatch('competitions/removeProblemFromComp', payload)
    }
}
const problemAscentCols = [
    { id: 'id' },
    { id: 'tstamp' },
    { id: 'climber' },
    { id: 'tries' },
    { id: 'tries_bonus' },
    { id: 'sport_points' },
    { id: 'sport_timer_secs', 'label': 'Sport secs.' },

]
const problemAscents = computed(() => store.state.competitions.problemAscents)
const showAscents = (id) => {
    const payload = {
        compid: props.competition.id,
        problemid: id
    }
    store.dispatch('competitions/showProblemAscents', payload)
    showAscentsDialog.value = true
}
const onAction = ({action, id}) => {
    switch (action) {
        case 'editProblemInComp':
            editProblemInComp(id)
            break
        case 'showAscents':
            showAscents(id)
            break
        case 'removeFromComp':
            removeFromComp(id)
            break
    }
}
const editProblemInComp = (id) => {
    selectedID.value = id
    showProblemEditDialog.value = true
}
const onProblemAttach = () => {
    showAttachProblemsDialog.value = false
    const payload = {
        problems: selectedProblems.value,
        compid: props.competition.id
    }
    store.dispatch("competitions/attachProblemsToComp", payload)
}
const onProblemSave = () => {
    const editedProblemInfo = problemInCompEditor.value.value
    const payload = { ...editedProblemInfo.pivot, ['compid']: props.competition.id }
    store.dispatch("competitions/changeProblemInComp", payload)
    .then(() => {
      showProblemEditDialog.value = false
      })
}
const getPossibleCategoryBinding = ((catid) => {
    debugger
    const categories = props.competition.categories
    const cat = categories.find(x => x.id == catid)
    if (cat != null) {
        return cat.nimi
    }
    return null
})
const props = defineProps({
    'competition': { type: Object, default: null },
})


const cols = [
    { id: 'id' },
    { id: 'num' },
    { id: 'name' },
    { id: 'wall' },
    { id: 'colour' },
    { id: 'grade' },
    { id: 'boundtocategory', label: 'Bound to' },
    { id: 'actions' },

]
const problems = computed(() => props.competition.problems)

</script>
