<template>
  <form v-if="wall != null" id="editwall" role="form" class="form container">
    <div class="row">

      <div class="card">
        <div class="card-body">Wall characters are usually A,B,...,Z. After that AA, AB etc. Try to stick only to one to three chars like A, A1, A224. 
        <br /><br />
        <div v-if="wall.id == '*new*'" class="">
        <br />
        <div class="flex flex-row">
         Highest char in use: 
        <strong class="px-1">{{ currentHighestWallChar }}</strong>
        , next suggested key <strong class="px-1"><copy-paste @click="useKey(getNextKey(currentHighestWallChar))">{{ getNextKey(currentHighestWallChar) }}</copy-paste></strong>
        </div>
        </div>
        <br />Wall name is the actual description of the wall, like "Mega overhang" or "Training 45degree overhang". </div>
      </div>

      <div class="col-12">
        <label for="id" class="control-label ">wall ID</label>
        <input id="id" v-model="wall.id" type="text" readonly class="form-control-plaintext">
      </div>

      <div class="col-12">
        <label for="wallchar" class="control-label col-sm-3">Wall character</label>

        <input type="text" name="wallchar" class="form-control" required="required" v-model="wall.wallchar" style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;">

      </div>
      <div class="form-group">
        <label for="walldesc" class="control-label col-sm-3">Wall name</label>

        <input type="text" name="walldesc" class="form-control" required="required" v-model="wall.walldesc">

      </div>

      <div class="form-group">
        <label for="sort" class="control-label col-sm-3">Sort</label>

        <input type="text" name="sort" class="form-control" id="sort" v-model="wall.sort">

      </div>

    </div>


  </form>
  <div v-else>Loading...</div>

</template>
<script setup>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { getNextKey } from '@/helpers/helpers.js'
import CopyPaste from '@/components/CopyPaste.vue'

const useKey = (key) => {
  wall.value.wallchar = key
}
const gymWalls = computed(() => store.state.basic.walls)
const currentHighestWallChar = computed(() => {
  const ordered = gymWalls.value.sort((a, b) => {
    let ca = ""
    let cb = ""
    if (a.wallchar != null) {
      ca = a.wallchar
    }
    if (b.wallchar != null) {
      cb = b.wallchar
    }
    return ca.localeCompare(cb)
  })
  const highest = ordered.pop()
  if (highest == null) {
    return "A"
  }
  return highest.wallchar

})
const store = useStore()
const props = defineProps({ 'id': String })

const wall = computed(() => store.state.walls.wall)
const skeleton = {
  id: "*new*",
}

if (props.id == null) {
  // Assume new problem (skeleton)
  store.commit('walls/wall', { ...skeleton })
} else {
  store.dispatch('walls/getWall', props.id)
}

// eslint-disable-next-line no-unused-vars
const save = (type) => {
  return store.dispatch('walls/saveWall', { ...wall.value })
}
const reset = () => {
  console.log("Resetting wall to", skeleton)
  const newSkeleton = { ...skeleton }
  store.commit('walls/wall', newSkeleton)
}

// Expose save method outside
defineExpose({ save, reset })
</script>