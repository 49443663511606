<template>
  <div v-if="!authChecked">
    Please wait, initializing...
  </div>
  <div v-else>
    <div v-if="!authenticated">
      <cover />
    </div>
    <div v-else>
      <global-editors />
      <main
          class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
          >
          <!-- nav -->
        <navbar
            :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
            :color="isAbsolute ? 'text-white opacity-8' : ''"
            :min-nav="navbarMinimize"
            />
        <router-view />
          <app-footer v-show="showFooter" />
            <configurator
                :toggle="toggleConfigurator"
                :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
                />
      </main>
    </div>
  </div>
</template>
<script>
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import Cover from "@/views/auth/signin/Cover.vue";
import { mapMutations, mapState } from "vuex";
import store from '@/store/store.js'
import GlobalEditors from '@/components/GlobalEditors.vue'

export default {
  name: "App",
  components: {
    Configurator,
    Navbar,
    GlobalEditors,
    AppFooter,
    Cover,
  },
  computed: {
    ...mapState([
      "isRTL",
      "authenticated",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "authChecked",
      "authenticated",
      "authToken",
    ])
  },
  mounted() {
    store.dispatch('getGyms')
    // Check authentication
    store.dispatch('checkAuth')


  },
  watch : {
    authenticated(newValue ) {
      if (newValue) {
        store.dispatch('getBasic')
      }
    }
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  }
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
  195deg,
  #66bb6a 0%,
  #43a047 100%
  ) !important;
}
</style>
