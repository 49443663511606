<template>
    <div class="input-group mb-3 py-0">
        <input :placeholder="placeHolder" class="form-control" type="text" v-model="search" />
        <button class="btn btn-danger mb-0" type="button" @click="clearSearch">clear</button>
    </div>
    <div class="block text-red-500" v-if="searching && termTooShort">Search term is too short, min. 3 chars.</div>
    <div class="block text-blue-500" v-if="searchReady && hits.length == 0">Search did not find any results with search <strong>{{ search }}</strong></div>


    <div class="relative" v-if="searchReady && hits.length > 0">
        <div style="z-index : 1000;" class="absolute left-10 -top-20 m-2 border-2 rounded-md border-black bg-white w-96 h-80">
            <div>
                <h4 class="text-center my-1">Choose a contender</h4>
                <div  class="h-48 overflow-scroll bg-gray-300 ">
                    <ul class="p-0 ">
                        <li v-for="hit in hits" :key="hit.id" class="m-0 p-0 text-white border  text-center py-1 ">
                            <a href="#" @click.prevent="() => selectHit(hit)">
                                {{ hit.etunimi }} {{ hit.sukunimi }} {{ hit.email }}<small class="self-end">{{ hit.id }}</small>
                            </a>
                        </li>
                    </ul>
                </div>
                        <div class="fs-6 text-center bg-gradient-muted">Scroll for more...</div>
                <div class=" my-1 mx-1" @click="clearSearch"><button class="btn btn-sm btn-danger">Close</button></div>
            </div>
        </div>
    </div>


</template>
<script setup>
import  debouncedRef  from "@/helpers/debouncedRef.js"
import {  ref, watch  } from 'vue'
import axios from 'axios'
const search = debouncedRef(null,400)
const hits = ref([])
const termTooShort = ref(true)
const searching = ref(false)
const searchReady = ref(false)
const props = defineProps({
    url : {type : String, default : '', required : true},
    modelValue : { type : null},
    placeHolder : { type : String, default : ''},
    multiple : {type : Boolean, default : false},
})
const emit = defineEmits(['select', 'cancel','clear'])
watch(search, term => {
    searchReady.value = false
    searching.value = true
    if (term == "" || term == null) {
        searching.value = false
    }
    if (search.value.length < 3) {
        termTooShort.value = true
        return
    }
    termTooShort.value = false
    searching.value = true
    axios.post(props.url, { term })
        .then(r => r.data)
        .then((ret) => { hits.value = ret; searching.value = false; searchReady.value = true })
})
const clearSearch = () => {
    search.value = ""
    hits.value = []
    emit('clear')
}
const selectHit = (hit) => {
    console.log(hit)
    clearSearch()
    if (props.multiple) {
        let add = []
        if (props.modelValue != null) {
            add = props.modelValue
        }
        emit('update:modelValue',[hit, ...add])
        emit('select',[hit, ...add])

    } else {
        emit('update:modelValue',hit)
        emit('select',hit)
    }
}
</script>