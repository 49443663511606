<template>
    <div v-if="comp != null" id="editcomp" role="form" class="form">

        
        <p>You can add judges to your competition. This enables the user to do the judging
        from their Problemator mobile app. Ask them to find the competition and there
        will be a button for the judging.</p>

        Judges:
        <ul>
        <li v-for="addjudge in addJudges" :key="addjudge.id">{{ addjudge.id }} {{ addjudge.etunimi }} {{ addjudge.sukunimi }} 
        <button class="btn btn-sm btn-danger" @click="delJudge(addjudge.id)">del</button>
        </li>
        </ul>
        <contender-search place-holder="Start searching for a judge" v-model="addJudges" :url="searchURL"  multiple />
        <button @click="attachJudges" class="btn  btn-info">Save judges</button>


    </div>
    <div v-else>Loading...</div>

</template>
<script setup>

import { useStore } from 'vuex'
import { onMounted, ref, computed  } from 'vue'
import ContenderSearch from '@/components/ContenderSearch.vue'
import { endpoint } from '@/helpers/problemator.js'

const store = useStore()
const comp = computed(() => store.state.competitions.competition)
const addJudges = ref([])
const delJudge = (id) => {
    addJudges.value = addJudges.value.filter(x => x.id != id)
    attachJudges()
}
onMounted(() => {
    addJudges.value = JSON.parse(JSON.stringify(comp.value.judges))
})
const attachJudges = () => {
    const payload = {
        compid : comp.value.id,
        judges : addJudges.value.map(x => x.id)
    }
    store.dispatch("competitions/attachJudges",payload)
}
const searchURL = computed(() => {
  return endpoint + "climber/search"
})



</script>