<template>
    <p>Here you can add additional information for the contender. This information is
        only for <strong>this</strong> competition and does not change the contender
        basic information like bio, height, ape index, country etc.</p>

        Participation ID:  {{ modelValue.id }}<br />

        <strong>Only the serie is a required field</strong>
    <form>
      

        <div class="row" >
            <div class="my-2 col-12 col-md-12 bg-blue-100 p-2">
                <label for="floatingInput">Serie / Category</label>
                <v-select id="serieid" v-model="modelValue.serieid" :reduce="sel => sel.id" :options="series" required>
                </v-select>
            </div>
            <button type="button" @click.prevent="showExtended = !showExtended" class="bg-blue-400 hover:bg-blue-500 px-4 py-2 rounded-md text-white">Toggle extended info</button>
            <div v-if="showExtended" class="row">
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Number on T-shirt</label>
                  <input type="text" class="form-control" v-model="modelValue.tshirtnumber" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Start position</label>
                  <popover hover title="Info" content="Starting position order number"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.startposition" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Starting group</label>
                  <popover hover title="Info" content="If starting groups are used, name it here"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.startgroup" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">World Ranking</label>
                  <popover hover title="Info" content="Used to define the qualifying start order in bouldering"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.world_ranking" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Qualifying standing</label>
                  <popover hover title="Info" content="This will be shown in result list as qualifying round standing'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.qualifying_position" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Qualifying Result</label>
                  <popover hover title="Info" content="This will be shown in result list's qualifying round result'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.qualifying_results" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Semifinal standing</label>
                  <popover hover title="Info" content="This will be shown in result list as semifinal round standing'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.semifinal_position" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Semifinal Result</label>
                  <popover hover title="Info" content="This will be shown in result list's semifinal round result'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.semifinal_results" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Last round standing</label>
                  <popover hover title="Info" content="Note: Use ony if using two competition method. This will be shown in result list as 'last round standing'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.lastroundposition" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Last round Result</label>
                  <popover hover title="Info" content="Note: Use ony if using two competition method. This will be shown in result list's 'last round result'"><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.lastroundresults" />
              </div>

              
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Point adjust</label>
                  <popover hover title="Info" content="You can adjust single competitor's points to move the competitor around the result list."><i class="mx-2 fa fa-question"></i></popover>
                  <input type="text" class="form-control" v-model="modelValue.adjustpoints" />
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label>Cup results hiding</label>
                  <popover hover title="Info" content="Sometimes competitors need to be hidden from the CUP results (eg. they are not egilible to participate a cup)"><i class="mx-2 fa fa-question"></i></popover>
                  <Checkbox class="form-control" @change="emit('modelValue',modelValue)" v-model="modelValue.hidefromcup">
                      Hide from cup results
                  </Checkbox>
              </div>
              <div class="my-2 col-12 col-md-6">
                  <label for="floatingInput">Paid?</label>
                  <popover hover title="Info" content="Set here the paid date so that user is marked as paid."><i class="mx-2 fa fa-question"></i></popover>

                  <div class="flex flex-row">
                  <input class="mx-1 px-2 py-1 block border" type="date" v-model="modelValue.paid" />
                  <button type="button" class="px-2 py-1 rounded-md text-sm bg-red-500 text-white" @click="modelValue.paid=null" >clear</button>
                  <small class="px-1 pt-2">{{ modelValue.paid }}</small>
                  </div>
              </div>
            </div>
            <p>You can edit these datas from 'edit contender in competition'. </p>
        </div>

    </form>
</template>
<script>
export const extraSkeleton = {
    id : null, // Id for the pivot table (comp_participates or comp_participates_unpaid)
        tshirtnumber : null,
        startgroup : null,
        lastroundposition : null,
        lastroundresults : null,
        adjustpoints : null,
        hidefromcup : false,
        paid : null,
        serieid : null,
    }
</script>
<script setup>
    import Checkbox from '@/components/MaterialCheckbox.vue'
    import Popover from "vue3-popper";
    import vSelect from 'vue-select'
    import { ref } from 'vue'
    import 'v-calendar/dist/style.css';
    const emit = defineEmits(['update:modelValue'])
    const showExtended = ref(false)
    defineProps({
        modelValue: { type : null},
        series : { type : Array, default : []},
    })

    
</script>
