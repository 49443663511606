<template>
  <div>
    <form v-if="problem != null" id="editproblem" role="form" class="form-floating">

      <div v-if="problem.id != '*new*'">


        <div class="mt-1 pb-3 mb-2 text-center font-bold text-4xl">Problem {{ right(problem.tag, 4) }} <small class="text-xs text-gray-600">{{ problem.id }}</small></div>

        <div class="row mt-2">
          <div class="col-md-3 col-sm-6 mt-lg-0 mt-4">
            <statistics-card :title="{ text: 'Likes', value: problem.c_like ?? 0}" :icon="{
            name: 'favorite_border',
            color: 'text-white',
            background: 'primary',
            }" />
          </div>
          <div class="col-md-3 col-sm-6 mt-lg-0 mt-4">
            <statistics-card :title="{ text: 'Ascents', value: problem.ascentCount ?? 0}" :icon="{
            name: 'leaderboard',
            color: 'text-white',
            background: 'success',
            }" />
          </div>
          <div class="col-md-3 col-sm-6 mt-lg-0 mt-4">
            <statistics-card :title="{ text: 'Messages', value: problem.messages.length }" :link="getMessageViewLink" :icon="{
            name: 'message',
            color: 'text-white',
            background: 'info',
            }" @click="onClickViewMessages(problem.id)" />
          </div>

          <div class="col-md-3 col-sm-6 mt-lg-0 mt-4">
            <statistics-card :link="getBetavideoViewLink" :title="{ text: 'Betavideos', value: problem.betavideos.length }" :icon="{
            name: 'video_label',
            color: 'text-white',
            background: 'warning',
            }" @click="onClickViewBetavideos(problem.id)" />
          </div>

          <div class="col-12 mt-2 ">
            <div class="card">
              <div class="card-body p-3 pt-2 mt-2">
                <h4>Problem info</h4>

                <div class="d-flex flex-row ">
                  <div class="text-center mx-1">
                    <span alt="dangerous" class="mx-1 fa fa-warning fa-2x" :class="getDangerousClasses" @click.prevent="onProblemTag('dangerous')"></span>
                    <br /><small class="position-relative">Dangerous <span v-if="problem.total_dangerous > 0" class="badge bg-danger">{{ problem.total_dangerous }}</span></small><br />
                    <a class="text-danger text-xs" href="#" @click.prevent="showReporters">Show reporters</a><br />
                    <a class="text-danger text-xs" href="/t/problemator/mark_dirty_resolved/?id={$p.problemid}" @click.prevent="mkarDangerousResolved">Mark resolved</a>
                  </div>
                  <div class="text-center mx-1">
                    <span alt="cirty" class="mx-1 fa fa-wrench fa-2x" :class="getDirtyClasses" @click.prevent="onProblemTag('dirty')"></span>
                    <br /><small class="position-relative">Dirty <span v-if="problem.total_dirty > 0" class="badge bg-warning">{{ problem.total_dirty }}</span></small><br />
                    <a class="text-warning text-xs" href="/t/problemator/_list_dirty_users/?id={$p.problemid}">Show reporters</a><br />
                    <a class="text-warning text-xs" href="/t/problemator/mark_dirty_resolved/?id={$p.problemid}" data-pid="{$p.problemid}">Mark resolved</a>

                  </div>
                  <div class="text-center mx-1">
                    <span alt="soon to be removed" target="soontoberemoved" class="mx-1 fa problemtag fa-recycle fa-2x" :class="getSoonRemovalClass" @click.prevent="onProblemTag('soontoberemoved')"></span>
                    <br /><small>To be removed</small>
                  </div>
                  <div class="text-center mx-1">
                    <span alt="Visible" class="mx-1 fa problemtag fa-eye fa-2x" :class="getVisibleClass" @click.prevent="onProblemTag('visible')"></span>
                    <br /><small>Visible</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-2 ">
        <div class="card">
          <div class="card-body p-3 pt-2 mt-2">
            <h4>Problem attributes</h4>

            <div id="pattributes" class="collapse show">
              <small>
                <pill-badge v-for="(att, index) in attributes" :key="index" class="mx-1" :variant="success" :checked="isChecked(att)" @checked="(val) => changeProblemAttribute(val, att)">
                  {{ right(att,-5) }}
                </pill-badge>
              </small>

            </div>

          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body p-3 pt-2 mt-2">
          <h4>Problem details</h4>

          <div class="row">
            <div class="col-md-3 col-sm-12">
              <label for="id" class="control-label ">ID</label>
              <input id="id" v-model="problem.id" type="text" readonly class="form-control-plaintext">
            </div>
            <div class="col-md-3 col-sm-12">
              <label for="added" class="control-label ">Problem creation date</label>
              <date-picker id="added" v-model="problem.added" mode="dateTime" is24hr>
                <template #default="{ inputValue, inputEvents }">
                  <input class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300" :value="inputValue" v-on="inputEvents" />
                </template>
              </date-picker>
            </div>


            <div class="col-md-3 col-sm-12">
              <label for="location" class="control-label ">Route author</label>
              <v-select id="authorid" v-model="problem.authorid" :reduce="sel => sel.id" :options="authorsDropdown"> </v-select>
            </div>

            <div class="col-md-3 col-sm-12">
              <label for="routetype" class="control-label ">Problem type</label>
              <v-select id="routetype" v-model="problem.routetype" :options="routeTypes" />
            </div>


            <div class="col-md-3 col-sm-12">
              <label for="gradeid" class="control-label ">Choose a grade</label>
              <v-select id="gradeid" v-model="problem.gradeid" :reduce="sel => sel.id" :options="gradesDropdown" />
            </div>

            <div class="col-md-3 col-sm-12">
              <label for="wallid" class="control-label ">Choose a wall</label>
              <v-select id="wallid" v-model="problem.wallid" :reduce="sel => sel.id" :options="wallsDropdown" />
            </div>


            <div class="col-md-3 col-sm-12">
              <label for="colour" class="control-label ">Colour</label>
              <v-select id="colour_choose" v-model="problem.colourid" :reduce="sel => sel.id" :options="coloursDropdown" />
            </div>

            <div class="col-md-3 col-sm-12">
              <label for="startdefinition" class="control-label ">Starting holds</label>
              <v-select id="startdefinition" v-model="problem.startdefinition" :reduce="sel => sel.id" :options="startOptions" />
            </div>

            <div class="col-md-3 col-sm-12">
              <label for="enddefinition" class="control-label ">Problem end</label>
              <v-select id="enddefinition" v-model="problem.enddefinition" :reduce="sel => sel.id" :options="topOptions" />
            </div>

            <div class="col-md-4 col-sm-12">
              <label for="sort" class="control-label">Sort</label>
              <input id="sort" v-model="problem.sort" class="form-control" type="number" name="sort" size="3" />
            </div>

            <div class="col-md-4 col-sm-12">
              <label for="addt" class="control-label">Addt. info (eg. arete excluded, dyno, morpho)</label>
              <textarea id="addt" v-model="problem.addt" class="form-control"></textarea>
            </div>


            <!--
              {include file="templates/problemator/problem_attributes.tpl" num=1}
            -->


          </div>
        </div>
      </div>


    </form>
    <div v-else>Loading...</div>

  </div>
</template>
<script setup>

import { useStore } from 'vuex'
import { computed } from 'vue'
import { right } from '@/helpers/strings.js'
//import Checkbox from '@/components/MaterialCheckbox.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
//import MaterialBadge from "@/components/MaterialBadge.vue";
import StatisticsCard from "@/components/StatisticsCard.vue"
//import BadgePositioned from "@/components/BadgePositioned.vue";
import { DatePicker } from 'v-calendar'
import PillBadge from '@/components/bs5/PillBadge.vue'
import 'v-calendar/dist/style.css'

const store = useStore()
const props = defineProps({ 'id': String })


const problem = computed(() => store.state.problem)
const walls = computed(() => store.state.basic.walls)
const authors = computed(() => store.state.basic.authors)
const colours = computed(() => store.state.basic.colours)
const user = computed(() => store.state.basic.user)
const grades = computed(() => store.state.basic.grades)
const attributes = computed(() => store.state.basic.attributes)
const gym = computed(() => store.state.basic.gym)
const skeleton = {
  id: "*new*",
  added: new Date(),
  authorid: user.value.id,
  messages: [],
  betavideos: [],
  visible: 1,
  startdefinition: gym.value.startdefault,
  enddefinition: gym.value.enddefault,
}

if (props.id == null) {
  // Assume new problem (skeleton)
  store.commit('problem', { ...skeleton })
} else {
  store.dispatch('getProblem', props.id)
}

const isChecked = (field) => {
  return problem.value[field] == 1
}
const getDirtyClasses = computed(() => {
  if (problem.value.c_dirty > 0) {
    return { 'active': true, 'text-danger': true }
  } else {
    return { 'passive': true, 'fa-gray': true }
  }
})

const getSoonRemovalClass = computed(() => {
  if (problem.value.soontoberemoved == 1) {
    return { 'active': true, 'text-danger': true }
  } else {
    return { 'passive': true, 'fa-gray': true }
  }
})
const getVisibleClass = computed(() => {
  if (problem.value.visible == 1) {
    return { 'active': true, 'text-success': true }
  } else {
    return { 'passive': true, 'text-danger': true }
  }
})

const getDangerousClasses = computed(() => {
  if (problem.value.c_dangerous > 0) {
    return { 'active': true, 'text-danger': true }
  } else {
    return { 'passive': true, 'fa-gray': true }
  }
})

const authorsDropdown = computed(() => {
  if (authors.value == null || user.value == null) {
    return []
  }
  if (user.value.ismanager == 1 || gym.value.see_other_routesetters == 1) {
    return authors.value.map(a => ({ id: a.id, label: a?.etunimi + " " + a?.sukunimi + " '" + a?.nick + "'" }))
  } else {
    return authors.value.map(a => ({ id: a.id, label: a?.etunimi + " " + a?.sukunimi + " '" + a?.nick + "'" })).filter(a => a.id == user.value.id)
  }
})
const wallsDropdown = computed(() => {
  if (walls.value == null) {
    return []
  }
  return walls.value.map(a => ({ id: a.id, label: a.wallchar + " " + a.walldesc }))
})
const gradesDropdown = computed(() => {
  if (grades.value == null) {
    return []
  }
  return grades.value.map(a => ({ id: a.id, label: a.name }))
})
const coloursDropdown = computed(() => {
  if (colours.value == null) {
    return []
  }
  return colours.value.map(a => ({ id: a.id, label: a.name }))
})

// eslint-disable-next-line no-unused-vars
const save = () => {
  // Save problem
  return store.dispatch('saveProblem', { ...problem.value })
}
const reset = () => {
  console.log("Resetting problem to", skeleton)
  // Save wallid, routetype, end and start holds
  const newSkeleton = {
    ...skeleton,
    ['gradeid']: problem.value.gradeid,
    ['wallid']: problem.value.wallid,
    ['routetype']: problem.value.routetype,
    ['startdefinition']: problem.value.startdefinition,
    ['enddefinition']: problem.value.enddefinition
  }
  store.commit('problem', newSkeleton)
}

const routeTypes = ['sport', 'boulder']
const getMessageViewLink = computed(() => {
  if (problem.value.messages.length > 0) {
    return { text: 'view' }
  }
  return null
})
const getBetavideoViewLink = computed(() => {
  if (problem.value.betavideos.length > 0) {
    return { text: 'view' }
  }
  return null
})
const onClickViewMessages = () => {
}
const onClickViewBetavideos = () => {
}

const topOptions = [
  { id: 'Top Edge', label: 'Top edge' },
  { id: 'Last Hold', label: 'Last hold' },
  { id: 'Top-out', label: 'Top-out' },
]
const startOptions = [
  { id: 'One hold', label: 'One hold' },
  { id: 'Two holds', label: 'Two holds' },
  { id: 'Box', label: 'Box' },
]
const changeProblemAttribute = (value, tag) => {
  const val = (value) ? 1 : 0
  problem.value[tag] = val
}
const onProblemTag = (tag) => {
  return tag
  /*
  switch (tag) {
     case 'soontoberemoved':
      problem.value.soontoberemoved = !problem.value.soontoberemoved
      break;
     case 'visible':
      problem.value.visible = !problem.value.visible
      break;
      case 'dirty':
      problem.value.c_dirty =  !problem.value.c_dirty
      break;
      case 'dangerous':
      problem.value.c_dangerous =  !problem.value.c_dangerous
      break;
  }
  */
}


// Expose save method outside
defineExpose({ save, reset })
</script>


