<template>
    <div>
        <router-link class="btn btn-sm btn-info" :to="'/competitions/'+route.params.compid">Back</router-link>
        <h2>Edit contender </h2>

    </div>
</template>
<script setup>
    import { useRoute } from 'vue-router'
    const route = useRoute()

</script>