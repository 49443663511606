<template>
  <div class="m-2 ">
    <problem-view title="Active problems" :rows="problems" />
  </div>
</template>
<script>
import ProblemView from "@/components/ProblemList.vue"
import { mapState } from 'vuex'

export default {
  name: "DashboardDefault",
  components: {
    ProblemView,
  },
  computed: {
    ...mapState({
      stats: state => state.basic.stats,
      problems: state => state.problems
    }),
  },
};
</script>
  