<template>
  <div>
    <modal v-model="showRemovalDateDialog" size="md" title="Set removal date" @ok="showRemovalDateDialog=false">
      <p>When the removal date is set, the problem will be marked as "soon to be removed" and the
      problems to be removed are being shown on the mobile app.<br /><br />The default date for removal
      defaults to 2 weeks from now. Feel free to change it</p>
      <div class="my-2 form-group">
        <label for="removaldate">Removal date</label>
        <input id="removaldate"   v-model="soonToBeRemovedDialog.removal_date" type="date" class="form-control" />
      </div>
      
      <template #footer>
        <div class="flex flex-row justify-between gap-2">
          <button type="button" class="btn btn-secondary" @click="showRemovalDateDialog=false">Close</button>
          <button type="button" class="btn btn-primary" @click="onExecuteSoonTobeRemoved">Proceed</button>
        </div>

      </template>

    </modal>

    <modal v-model="showMessageDialog" size="xl" title="Message viewing / moderation" @ok="showMessageDialog=false">
      <MessageViewer ref="messageViewer" />
    </modal>
    
    <modal v-model="showCircuitEditorDialog" size="xl" title="Circuit editor" @ok="onSaveCircuitProblems">
      <circuit-problem-editor :id="selectedProblem" ref="circuitProblemEditorRef" />
    </modal>




    <h2 class="mt-3 mb-2 ms-3 ">{{ title }} <small class="text-md">{{ rows.length }} problem(s)</small> <button class="btn btn-primary btn-sm mt-3" @click="newProblem">Add problem <i class="fa fa-plus"></i></button></h2>
<div class="my-1 font-bold">
{{  messages.length }} unread messages, {{ waitingForModerationCount }} waiting for moderation
</div>
<div v-if="waitingForModerationCount > 0" class="alert alert-warning">
 Please open the messages from the top right cornr and moderate the new messages!
</div>

    <p>
      Here you can see your currently active problems. PAI is the Problemator Aging Index. By default,
      the problems will age defined by the gym settings (8 weeks for boulder, 16 weeks for a sport route).
      Then you can additionally change aging rules for route types and grades. Eg. boulders of
      grade 6a can age in 4weeks.
      <br /><br />When the aging index is over 100, the problem should be replaced. Tweak the default
      settings and the aging rules so that you can achieve nicely changing routes/problems before they
      get too dirty/worn.
    </p>
    <div v-if="loading">
       Loading, please wait..</div>

    <div class="col-12 d-flex flex-row justify-content-between">
      <div class="d-flex justify-content-start ">
        <button class="mx-1 flex-grow-0  btn btn-sm btn-secondary" @click="selectAll">select all</button>
        <button class="mx-1 flex-grow-0  btn btn-sm btn-secondary" @click="deselectAll">deselect all</button>
      </div>
      <div class="d-flex justify-content-end flex-grow-1">
        <v-select id="bulkactions" v-model="selectedAction" class="mx-1 w-50" :reduce="sel => sel.id" :options="bulkActions"></v-select>
        <button class="mx-1 btn btn-sm btn-primary flex-grow-1" @click="executeBulk">Execute</button>
      </div>
    </div>
    <div class="col-12 overflow-auto">
      {{ amountSelected }} row(s) selected
      <oma-table :rows="sortedRows" :cols="columns" :sortable="sortable" class="table table-striped table-condensed table-hover">
        <template #circuits="{row}">
          <div v-if="row.circuits.length > 0" class=" badge rounded-pill bg-info" :title="row.circuits?.map(c => c.circuitname)">{{ row.circuits?.length }}</div>
          <span v-else></span>
        </template>
        <template #sel="{row}">
          <div class="flex flex-row">
          <checkbox :checked="selectedRows[row.id]==selectedValue" :checked-value="selectedValue" unchecked-value="0" @change="(val) => onChange(row.id,val)" />
          <span v-if="row.soontoberemoved==1" class="text-sm  py-0 px-1 m-0 bg-red-500 text-white rounded-lg" title="Soon to be removed" >removal {{  date_format(row.removal_date,"DD.MM.") }}</span>
          </div>
        </template>
        <template #added="{row}">
          {{ age(row.added) }}
        </template>
        <template #id="{row}">
          <a href="#" @click.prevent="editProblem(row.id)">{{ row.id }}</a>
        </template>
        <template #tag="{row}">
          <a href="#" @click.prevent="editProblem(row.id)">{{ tagShort(row.tag) }}</a>
        </template>
        <template #grade="{row}">
          {{ row.grade.name }}
        </template>
        <template #colour="{row}">
          <div class="text-center rounded-3 px-1 fw-bold" :style="getStyles(row.colour.code,row.colour.textcolor )">{{ row.colour.name }}</div>
        </template>

        <template #author="{row}">
          {{ row.author?.etunimi }}
          {{ row.author?.sukunimi.substr(0,1) }}.
        </template>
        <template #routetype="{row}">
          {{ row.routetype }}
        </template>
        <template #wall="{row}">
          <span class="fw-bold fs-5">{{ row.wall?.wallchar }}</span> {{ row.wall?.walldesc }}
        </template>
        <template #actions="{row }">
              <Dropdown @action="onAction">
              <template #default="{action}">
                <li><a class="dropdown-item border-radius-md" href="#" @click.prevent="() =>action('edit',row.id)">Edit...</a></li>
                <li><a class="dropdown-item border-radius-md" href="#" @click.prevent="() => action('define_circuits',row.id)">Circuits</a></li>
                <li><a class="dropdown-item border-radius-md text-danger" href="#" @click.prevent="() => action('remove',row.id)">Remove</a></li>
              </template>
              </Dropdown>
        </template>

      </oma-table>

    </div>



  </div>
</template>
<script>
import "@/assets/js/world.js"
import store from "@/store/store.js"
import { webendpoint } from '@/helpers/problemator'
import { date_format } from '@/helpers/helpers.js'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import Modal from '@/components/Modal.vue'
import Checkbox from '@/components/MaterialCheckbox.vue'
import CircuitProblemEditor from '@/components/CircuitProblemEditor.vue'
import MessageViewer from '@/components/MessageViewer.vue'
import OmaTable from '@/components/OmaTable.vue'
dayjs.extend(duration)
import { useToast } from "vue-toastification"
import { handleValidationErrors } from '@/helpers/errors.js'
import vSelect from 'vue-select'
import 'v-calendar/dist/style.css'
import Dropdown from '@/components/Dropdown.vue'

export default {
  name: "SalesTableCard",
  components: {
    Modal,
    MessageViewer,
    OmaTable,
    CircuitProblemEditor,
    Checkbox,
    Dropdown,
    vSelect,
  },
  props: {
    title: {
      type: String,
      default: "Active problems",
    },
    rows: {
      type: Array,
      default: () => [],
    }
  },
  setup() {
    return { date_format}
  },
  data: function () {
    return {
      bulkActions: [

        { id: "markremoval", label: 'Mark as soon to be removed problem' },
        { id: "hide", label: 'Hide selected problems (from problemator mobile)' },
        { id: "show", label: 'Show selected problems (from problemator mobile)' },
        { id: "delete", label: 'Delete selected problems (Careful now!)' },
        //{ id: "link_to_comp", label: 'Link selected problems to a competition...' },
        //{ id: "attach_to_circuits", label: 'Assign selected problems to circuit(s)' },
        { id: "print_to_a4", label: 'Print selected routes/problems to A4' },
      ],
      selectedRows: {},
      selectedValue: 1,
      selectedAction: null,
      isLoading: true,
      messagesLoading : false,
      toast: null,
      sortFunction: null,
      columns: [
        { id: 'sel', label: 'Sel', sortable: false, },
        { id: 'colour', label: 'Colour', sortable: true, filtering : true , filterField : 'name'},
        { id: 'tag', label: 'Tag', sortable: true, filtering : true},
        { id: 'added', label: 'Age', sortable: true, },
        { id: 'pai', label: 'PAI', sortable: true, },
        { id: 'total_ascents', label: 'Ascents', sortable: true, },
        { id: 'likeCount', label: 'Likes', sortable: true, },
        { id: 'grade', label: 'Grade', sortable: true, sortKey : 'score'},
        { id: 'author', label: 'Author', sortable: true, filtering : true , filterField : 'etunimi'},
        { id: 'routetype', label: 'Type', sortable: true, filtering : true },
        { id: 'wall', label: 'Wall', sortable: true, sortKey : 'wallchar', filtering : true, filterField : 'walldesc'},
        { id: 'actions', label: 'Actions', },
      ],
      sortable: {
        order: 'id',
        sort: 'desc',
      },
      dlgEditProblem: false,
      selectedProblem: null,
      showCircuitEditorDialog: false,
      showMessageDialog : false,
      showRemovalDateDialog : false,
      soonToBeRemovedDialog : {
        removal_date : dayjs().add(2,'week').format('YYYY-MM-DD')
      }
    }
  },
  computed: {
  waitingForModerationCount() {
      return store.state.problemMessages.filter(i => i.moderated_by == null).length
    },
    messages() {
      return store.state.problemMessages
    },
    loading() {
      return store.state.loading
    },
    amountSelected() {
      return Object.keys(this.selectedRows).filter(key => this.selectedRows[key] == this.selectedValue).length
    },
    sortedRows() {
        return this.rows
    }
  },
  mounted() {
    this.toast = useToast()
    // eslint-disable-next-line no-unused-vars
    if (this.rows.length == 0) {
      store.dispatch('getProblems').then(() => {
        this.isLoading = false
      })
    }
  },
  methods: {
    onExecuteSoonTobeRemoved() {
      const ids = Object.keys(this.selectedRows).filter(key => this.selectedRows[key] == this.selectedValue)
      const payload = {
        ...this.soonToBeRemovedDialog,
        ids,
        action : this.selectedAction,
      }
      store.dispatch('executeProblemBulk', payload)
      .then(() => {
        this.showRemovalDateDialog = false
      })
    },

    onSaveCircuitProblems() {
      this.$refs.circuitProblemEditorRef.save()
        .then((ret) => {
          this.showCircuitEditorDialog = false
          this.toast.success(ret.message)
        })
        .catch(err => {
          console.log(err)
          this.toast.error(handleValidationErrors(err))
        })
    },
    selectAll() {
      this.selectedRows = this.rows.reduce((acc, item) => {
        acc[item.id] = this.selectedValue
        return acc
      }, {})
    },
    deselectAll() {
      this.selectedRows = {}
    },
    executeBulk() {
      const ids = Object.keys(this.selectedRows).filter(key => this.selectedRows[key] == this.selectedValue)
      const payload = {
        action: this.selectedAction,
        ids
      }
      if (this.selectedAction == 'markremoval') {
        // Ask first the removal date for the selected problems
        this.showRemovalDateDialog = true
      }
      if (this.selectedAction == 'print_to_a4') {
        const href = webendpoint+`problem/printa4/?ids=`+ids.join(",")
        //window.location.href = href
        let a = document.createElement('a')
        a.target = '_blank'
        a.href =href
        a.click();
      } else {
        store.dispatch('executeProblemBulk', payload)
      }
    },
    onChange(pid, val) {
      this.selectedRows[pid] = val
    },
    getStyles(code, textcolor) {
      return { 'background-color': code, color: textcolor }
    },
    newProblem() {
      store.commit('openDialog',{name : 'problemeditor','value' : null})
    },
    onAction({ action, id }) {
      switch (action) {
        case 'edit':
          this.editProblem(id)
          break
        case 'define_circuits':
          this.defineCircuits(id)
          break
        case 'remove':
          this.removeProblem(id)
          break

      }
    },
    editProblem(id) {
      store.commit('openDialog',{name : 'problemeditor','value' : id})
    },
    removeProblem(id) {
      if (confirm("Are you sure?")) {
        store.dispatch("deleteProblem", id)
          .then(ret => this.toast.success(ret.message))
          .catch(err => this.toast.error(handleValidationErrors(err)))
      }
    },
    defineCircuits(id) {
      this.selectedProblem = id
      this.showCircuitEditorDialog = true
    },
    tagShort(tag) {
      if (tag == null) {
        return ""
      }
      return tag.substr(7)
    },
    age(date) {
      var duration = dayjs.duration(dayjs().diff(dayjs(date)))
      return parseFloat(Math.round(duration.asWeeks() * 100) / 100)
    }
  },
}
</script>
