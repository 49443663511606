<template>
    <modal v-model="showContenderExtraInfoDialog" title="Additional information" close-title="Cancel" ok-title="Proceed" @ok="onProceedAdd">
        <contender-extra-info-editor v-model="extra" :series="seriesDropdown" />
        <template #footer="{ ok, close }">
            <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
            <button type="button" class="btn btn-primary" @click="ok">Proceed</button>
        </template>
    </modal>
    <p class="w-4/5">
        Remember that some of the information is bound to competition and these need to be edited via
        <strong>'edit contender in competition'</strong>. Some of these are: Team, Number in T-Shirt, rank
        in last phase of the competition, starting position.
    </p>

    <label>Search and add a new contender to the competition</label>
    <div class="input-group mb-3 py-0">
        <input class="px-2 bg-white form-control" type="text" v-model="search" />
        <button class="btn btn-danger mb-0" type="button" @click="clearSearch">clear</button>
    </div>
    <div class="block text-red-500" v-if="searching && termTooShort">Search term is too short, min. 3 chars.</div>
    <div class="block text-blue-500" v-if="searchReady && hits.length == 0">Search did not find any results with search <strong>{{ search }}</strong></div>


    <div class="relative" v-if="searchReady && hits.length > 0">
        <div style="z-index : 1000;" class="absolute left-10 -top-20 m-2 border-2 rounded-md border-black bg-white w-96 h-80">
            <div>
                <h4 class="text-center my-1">Choose a contender</h4>
                <div class="h-48 overflow-scroll bg-gray-300 ">
                    <ul class="p-0 ">
                        <li v-for="hit in hits" :key="hit.id" class="m-0 p-0 text-white border border-gray-700 text-center py-1 bg-blue-400">
                            <a href="#" @click.prevent="() => selectContender(hit)">
                                {{ hit.etunimi }} {{ hit.sukunimi }} <small class="self-end">{{ hit.id }}</small>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class=" my-1 mx-1" @click="clearSearch"><button class="btn btn-sm btn-danger">Close</button></div>
            </div>
        </div>
    </div>


    <div class="overflow-auto">
    <oma-table class="bg-white table table-sm table-striped table-responsive text-sm table-condensed" :rows="contenders" :cols="cols">
        <template #paid="{ row }">
            <div v-if="row.pivot.paid == null">
                <button @click="changeExtraField(row,{paid : dayjs().format('YYYY-MM-DD')})">
                    <font-awesome-icon size="lg" class="text-red-500" icon=" fa-xmark" />
                </button>
            </div>
            <div v-else>
                {{ dayjs(row.pivot.paid).format("YYYY-MM-DD") }}
            </div>
        </template>
        <template #name="{ row }">
            {{ row.etunimi }} {{ row.sukunimi }}
        </template>
        <template #category="{ row }">
            {{ getUserSerie(row.pivot.serieid) }}
        </template>
        <template #tshirtnumber="{ row }">
            <click-to-edit ok-title="Save" :id="row.pivot.id" @ok="(data) => onEditTShirtNumber(data)">{{ row.pivot.tshirtnumber }}</click-to-edit>

        </template>
        <template #actions="{ row }">
            <Dropdown @action="onAction">
                <template #default="{ action }">


                    <li><a class="dropdown-item" href="#" @click.prevent="action('editcontender', row.id)">Edit contender </a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="action('editcontenderincomp', row.id)">Edit contender in competition</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="action('sendpaymentemail', row.id,props.type)">Send payment email again</a></li>
                    <li><a class="text dropdown-item" href="#" @click.prevent="action('showpaymentlink', row.id)">Show payment link</a></li>
                    <li><a class="text dropdown-item" href="#" @click.prevent="action('sendallok', row.id)">Send all OK message</a></li>
                    <li><a v-if="type == 'paidregistrations'" class="text-warning dropdown-item" href="#" @click.prevent="action('movetounpaid', row.id)">Move to unpaid list</a></li>
                    <li><a v-if="type == 'unpaidregistrations'" class="text-warning dropdown-item" href="#" @click.prevent="action('movetopaid', row.id)">Move to paid list</a></li>
                    <li><a class="text-danger dropdown-item" href="#" @click.prevent="action('removecontender', row.pivot.id)">Remove from this list</a></li>
                </template>
            </Dropdown>




        </template>
    </oma-table>
    </div>

</template>

<script setup>
import { computed, ref, watch } from 'vue'
import debouncedRef from "@/helpers/debouncedRef.js"
import OmaTable from '@/components/OmaTable.vue'
import ContenderExtraInfoEditor from '@/components/competitions/ContenderExtraInfoEditor.vue'
import Modal from '@/components/Modal.vue'
import store from '../../store/store'
import dayjs from 'dayjs'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from "vue-toastification"
import { extraSkeleton } from './ContenderExtraInfoEditor.vue'
import Dropdown from '@/components/Dropdown.vue'
import ClickToEdit from '@/components/ClickToEdit.vue'

const toast = useToast()
const onEditTShirtNumber = ({ id, value }) => {
    const payload = { compid: props.competition.id, id, 'tshirtnumber': value, paid: true }
    store.dispatch("competitions/saveContenderExtraInfo", payload)
}

const props = defineProps({
    'competition': { type: Object, default: null },
    'type': { type: String, default: 'paidregistrations' }
})
const search = debouncedRef(null, 400)
const hits = computed(() => store.state.competitions.searchHits)
const termTooShort = ref(true)
const showContenderExtraInfoDialog = ref(false)
const selectedContender = ref(null)
const categories = computed(() => props.competition.categories)
const extra = ref({ ...extraSkeleton })
const getUserSerie = (serieid) => {
    const serie =  categories.value.find(x => x.id == serieid)
  if (serie == null) {
    return "Serie removed from comp, select a new one."
  }
  return serie.nimi
}
const seriesDropdown = computed(() => {
    return props.competition.categories.map(c => {
        const label = c.nimi + " price:" + c.pivot.price + "€/" + (c.pivot.memberprice ?? 0) + "€, max. part.: " + c.pivot.maxparticipants
        return { id: c.id, label }
    })
})

const onProceedAdd = () => {
    if (extra.value.serieid == null) {
        alert('Serie is required')
        return
    }

    const payload = { ...extra.value, 
        ['contenderid']: selectedContender.value, 
        target_list_paid :(props.type =='paidregistrations'),
        compid: props.competition.id }

    store.dispatch('competitions/addContenderToCompetition', payload)
        .then(ret => {
            console.log(ret)
            ret.id = ret.contenderid
            selectedContender.value = null
            showContenderExtraInfoDialog.value = false
            clearSearch()
            toast.success(ret.message)
        })
        .catch(err => {
            toast.error(handleValidationErrors(err.response.data))
        })
}
const searching = ref(false)
const searchReady = ref(false)
watch(search, term => {
    searchReady.value = false
    searching.value = true
    if (term == "" || term == null) {
        searching.value = false
    }
    if (search.value.length < 3) {
        termTooShort.value = true
        return
    }
    termTooShort.value = false
    searching.value = true
    store.dispatch('competitions/searchForClimber', { term })
        .then(() => { searching.value = false; searchReady.value = true })
})
const clearSearch = () => {
    search.value = ""
    store.commit('competitions/searchHits', [])
}
const selectContender = (hit) => {
    console.log(hit)
    selectedContender.value = hit.id
    showContenderExtraInfoDialog.value = true
}

const cols = [
    { id: 'id' },
    { id: 'name', label: 'Name' , filtering : false, ignoreCase : true},
    { id: 'category.nimi' , filtering : true, filterField : 'category.nimi', ignoreCase : true},
    { id: 'tshirtnumber' , filtering : true},
    { id: 'pivot.lastroundposition' ,label : "Prev. rnd"},
    { id: 'paid', label: 'Paid' },
    { id: 'team' , filtering : true, ignoreCase : true},
    { id: 'maa', label: 'Country' , filtering : true, ignoreCase : true},
    { id: 'actions' },

]
const changeExtraField = (contender, field) => {
    let payload = {
        compid: props.competition.id,
        id : contender.pivot.id,
        ...field
    }
    if (props.type =='paidregistrations') {
        payload.target_list_paid = true
    }

    store.dispatch("competitions/saveContenderExtraInfo", payload)
        .then(ret => {
            console.log(ret)

        })
}

const emits = defineEmits(['action'])
const contenders = computed(() => props.competition[props.type])
const onAction = ({ id, action }) => {
    const payload = {
        id,
        action,
        type: props.type,
    }
    emits('action', payload)
}

</script>
