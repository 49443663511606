<template>
    <div class="row">

        <modal id="another" v-model="showSelectOtherCompResultSelectorDialog" title="Select results from other competition" @ok="onTargetCompSelected">
            <div class="form-group">
                <label for="categoryid" class="form-label ">Select a competition first</label>
                <v-select id="compid" v-model="set_targetcompid" :reduce="sel => sel.id" :options="getCompetitionsDropDown">
                </v-select>
            </div>
            <div class="form-group">
                <label for="categoryid" class="form-label ">Select a category</label>
                <v-select :disabled="set_targetcompid==null" id="serieid" v-model="set_targetserie" :reduce="sel => sel.id" :options="getCategoriesDropDown">
                </v-select>
            </div>
        </modal>
        <div class="col-12">
            <label for="categoryid" class="form-label ">Relation ID</label>
            <input type="text" readonly class="form-control-plaintext" :value="eCategory.pivot.id">
        </div>

        <div class="col-12">
            <label for="categoryid" class="form-label ">Choose an category</label>
            <v-select id="categoryid" v-model="eCategory.pivot.serieid" :reduce="sel => sel.id" :options="getPossibleCategories">
            </v-select>
        </div>

        <div class="form-group">
            <label for="displayname" class="control-label col-sm-5">Category display name</label>
                <input type="text" class="form-control" v-model="eCategory.pivot.displayname" />
                <p class="help-text">This is used in eg. official streams, usually longer name than the normal category name. eg. Men's boulder final)</p>
                </div>
                
                <div class="form-group">
                    <label class="control-label col-sm-5" for="priceforserie">Entry fee for this category</label>
                    <input type="text" name="price" id="priceforserie" v-model="eCategory.pivot.price" required class="form-control" />
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-5" for="priceforserie">Member entry fee</label>
                    <input type="text" name="price" id="priceforserie" v-model="eCategory.pivot.memberprice" required class="form-control" />
                </div>
                
                <div class="form-group my-1">
                    <checkbox checked-value="1" unchecked-value="0" v-model="eCategory.pivot.showinresults" class="mb-0">
                    Is this category published in result lists?
                    </checkbox>
                    <small>For example spectators are usually not published in result lists :)</small>
                </div>
                
                
                <div class="form-group">
                    <checkbox checked-value="1" unchecked-value="0" v-model="eCategory.pivot.showincupresults" >
                    Is this category included in CUP result calculations
                    </checkbox>
                </div>
                <div class="form-group">
                <label for="categoryid" class="form-label ">Choose CUP category</label>
{{ eCategory.pivot.cup_category }}
                    <v-select id="cup_category" v-model="eCategory.pivot.cup_category" :reduce="sel => sel.id" :options="getCupCategoriesDropdown">
                    </v-select>
                </div>
                
                <div class="form-group">
                    <label class="form-label " for="maxparticipants">Max participants in this category</label>
                    <input type="text" v-model="eCategory.pivot.maxparticipants" id="maxparticipants" class="form-control">
                </div>
                
                <div class="form-group">
                    <checkbox checked-value="1" unchecked-value="0" v-model="eCategory.pivot.get_results_from_previous_round" class="my-2">
                    Show result text from the last round
                    </checkbox>
                </div>
                
                
                <div class="form-group">
                    <label class="control-label " for="info">Additional info for the category</label>
                    <input type="text" name="info" id="info" v-model="eCategory.pivot.info" size="20" class="form-control">
                </div>
                
                <div class="form-group">
                    <label class="control-label " for="final_serie_id">Find standings from another competition <small>eg. Finals can be held in another competition</small></label>
                    <p>The order is from larger to smaller. Qualification points to finals.</p>
                    <div class="input-group">
                    <input type="text" name="final_serie_id" id="final_serie_id" v-model="eCategory.pivot.final_serie_id" class="mb-3 form-control">
                    <button  type="button" @click="showCompetitionSelector" class="btn btn-primary ">Set target competition category...</button>
                    </div>
                
                </div>
                
                <div class="form-group">
                    <label class="control-label " for="finalist_amount">Finalist amount and/or How many standing should be gotten from the competition serie above?</label>
                    <input type="text" name="finalist_amount" id="finalist_amount" v-model="eCategory.pivot.finalist_amount" size="20" class="form-control">
                </div>
               <div class="form-group">
                    <label class="control-label " for="semifinalist_amount">Semifinalist amount </label>
                    <input type="text" name="semifinalist_amount" id="semifinalist_amount" v-model="eCategory.pivot.semifinalist_amount" size="20" class="form-control">
                </div> 
                <div class="form-group">
                    <label class="control-label " for="finalist_amount">In which order the routes should be printed to judge sheet (official comps)<small>These are route numbers, eg. 1,5,3,8,10</small></label>
                    <input type="text" name="route_nums_for_sheet" id="route_nums_for_sheet" v-model="eCategory.pivot.route_nums_for_sheet" class="form-control">
                
                </div>
                
                
                </div>
                </template>
<script setup>
import Checkbox from '@/components/MaterialCheckbox.vue'
import { ref, computed } from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import store from '../../store/store'
import modal from '@/components/Modal.vue'
const showSelectOtherCompResultSelectorDialog = ref(false)
const props = defineProps({ category: Object, competition: Object })
const set_targetserie = ref(null)
const set_targetcompid = ref(null)
const eCategory = ref({ ...props.category })
// eslint-disable-next-line
const value = computed(() => eCategory.value)
const getPossibleCategories = computed(() => {
    return props.competition.all_categories.map(x => ({ id: x.id, label: x.nimi }))
})
const competitions = computed(() => store.state.competitions.competitions)
const targetcompid = ref(null)
const targetserie = ref(null)
const getCategoriesDropDown = computed(() => {
    const comp = competitions.value.find(x => x.id == set_targetcompid.value)
    if (comp != null) {
        return [{id : null, label : 'Select a category'},...comp.categories.map(x => ({ id: x.pivot.id, label: x.nimi }))]
    }
    return []
})
const getCupCategoriesDropdown = computed(() => {
  if (props.competition.all_cuptypes == null) {
  return []
  }
  return  props.competition.all_cuptypes.map(x => ({id : x.id, label : x.name}))
  })
const getCompetitionsDropDown = computed(() => {
    return competitions.value.map(x => ({id : x.id, label : x.name}))
})
const showCompetitionSelector = () => {
    store.dispatch('competitions/getCompetitions')
    showSelectOtherCompResultSelectorDialog.value=true
}
const onTargetCompSelected = () => {
    targetcompid.value = set_targetcompid.value
    targetserie.value = set_targetserie.value
    const payload = {
        compid : props.competition.id,
        id : eCategory.value.pivot.id,
        final_serie_id : targetserie.value,
        finalist_amount : eCategory.value.pivot.finalist_amount 
    }
    eCategory.value.pivot.final_serie_id = targetserie.value
    store.dispatch('competitions/updateCategoryInComp',payload)
    showSelectOtherCompResultSelectorDialog.value = false

}
defineExpose({ value })

</script>
