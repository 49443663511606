<template>
    <div class="m-2">
        <modal v-model="showUserMergingDialog" title="User merging" ok-title="Merge" @ok="onMergeUser" size="md">
            <user-merger ref="userMerger" />
        </modal>
      <div class="col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
        <h1 class="page-header">Manager functions <small></small></h1>
        <p class="lead">Here are the functions, which are only for managers. </p>
        <button class="btn btn-danger" @click="showUserMergingDialog=true">User merging</button>
        Merge users which are duplicate (different email, same name. Two emails, one actual user etc.)
        <br />
        <button class="btn btn-danger" @click="deleteAllProblems">Delete all problems</button>
        This is useful, when you want to clear all the problems (whole gym gets stripped out of problems before a comp)
        If you want to clear just one wall, then go to <router-link class="font-bold text-info" :to="{ name : 'Walls'}">walls</router-link> and choose
        'remove problems' from the dropdown menu.
        
      </div>
    </div><!-- end of row -->
  </template>
<script setup>
import { useToast } from "vue-toastification"
import Modal from '@/components/Modal.vue'
// eslint-disable-next-line no-unused-vars
import UserMerger from '@/components/UserMerger.vue'
import { handleValidationErrors } from '@/helpers/errors.js'
import { ref } from 'vue'
import store from '../../store/store'

const deleteAllProblems = () => {
  if (confirm("Are you sure you want to delete ALL active problems? There is no turning back...")) {
    store.dispatch('removeAllProblems')
    .then(ret => {
      toast.success(ret.message)
    })
  }
}
const showUserMergingDialog = ref(false)
const toast = useToast()
const userMerger = ref(null)
const onMergeUser = () => {
  if (confirm("Please double check that the merge master is correct!")) {
  userMerger.value.merge()
      .then(ret => {
          console.log(ret)
          showUserMergingDialog.value = false
          toast.success("Merge done")
      })
      .catch(err => {
          toast.error(handleValidationErrors(err))
      })
  }
}



</script>
  