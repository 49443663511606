<script setup>
import dayjs from 'dayjs'
import { ref, computed } from 'vue'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
const props = defineProps(['problems', 'grade'])
const sortedProblems = computed(() => {
    return props.problems.sort((a, b) => {
        if (sortBy.value == 'wall') {
            return a.wall.wallchar.localeCompare(b.wall.wallchar)
        } else if (sortBy.value == 'colour') {
            return a.colour.name.localeCompare(b.colour.name)
        } else if (sortBy.value == 'author') {
            return a.author.sukunimi.localeCompare(b.author.etunimi)
        } else if (sortBy.value == 'ascentCount') {
            return b.ascentCount - a.ascentCount
        } else if (sortBy.value == 'likeCount') {
            return b.likeCount - a.likeCount
        }
    })

})
const getRowClass = (idx) => {
   return ((idx % 2 == 0) ? 'bg-white' : 'bg-gray-100') + " border-b dark:bg-gray-900 dark:border-gray-700"
}
const age = (date) => {
    var duration = dayjs.duration(dayjs().diff(dayjs(date)))
    return parseFloat(Math.round(duration.asWeeks() * 100) / 100)
}
const getStyles = (code, textcolor) => {
    return { 'background-color': code, color: textcolor }
}
const tagShort = (tag) => {
    if (tag == null) {
        return ""
    }
    return tag.substr(7)
}
const sortBy = ref('wall')
</script>
<template>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <h2>{{  sortedProblems.length }} problems</h2>
    <div class="flex flex-row gap-1">
        <strong>Sort by </strong>
        <div v-for="sort in ['wall','author','colour','ascentCount','likeCount']" :key="sort"  >
            <div>
                <input :id="sort" type="radio" :value="sort" v-model="sortBy" /> 
                <label :for="sort"> 
                    {{ sort }}
                </label>
            </div>
        </div>
    </div>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th  class="text-center px-2 py-3">
                        Tag
                    </th>
                    <th class="text-center px-2 py-3">
                        Wall
                    </th>
                    <th class="text-center px-2 py-3">
                        Colour
                    </th>
                    <th class="text-center px-2 py-3">
                        Author
                    </th>
                    <th class="text-center px-2 py-3">
                        Ascents
                    </th>
                    <th class="text-center px-2 py-3">
                        Likes
                    </th>
                </tr>
            </thead>
            <tbody>
                    <tr v-for="(p, idx) in sortedProblems" :key="p.id" :class="getRowClass(idx)">
                        <td scope="col" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ tagShort(p.tag) }}
                        </td>
                        <td class="px-4 py-4">
                        {{ p.wall.wallchar }} |
                        {{ p.wall.walldesc }}
                        </td>
                        <td class="px-4 py-4">
                            <div class="text-center rounded-3 px-1 fw-bold" :style="getStyles(p.colour.code, p.colour.textcolor)">{{ p.colour.name }}</div>
                        </td>
                        <td class="px-4 py-4">
                            {{ p.author?.etunimi }}
                            {{ p.author?.sukunimi.substr(0, 1) }}.
                        </td>
                        <td class="px-4 py-4">
                            {{  age(p.added) }}
                        </td>
                        <td class="px-4 py-4">
                            {{  p.ascentCount }}
                        </td>

                        <td class="px-4 py-4">
                        {{  p.likeCount }}
                        </td>
                    
                    
                    </tr>
                
                
                    
                    
                </tbody>
            </table>
        </div>
</template>
