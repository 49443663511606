<template>
    <form v-if="cup != null" id="editcup" role="form" class="form container">

        <div class="row">
            <div class="col-12">
                <label for="id" class="control-label ">Cup ID</label>
                <input id="id" v-model="cup.id" type="text" readonly class="form-control-plaintext">
            </div>

            <div class="col-12">
                <checkbox v-model="cup.published" :checked-value="1" unchecked-value="0">
                    Published
                    </checkbox>
            </div>

            <div class="col-12">
                <label for="sort" class="control-label">Cup name</label>
                <input id="sort" v-model="cup.cupname" class="form-control" type="text" name="sort" size="3" />
            </div>

            <div class="col-12">
                <label for="sort" class="control-label">Competitions in this CUP</label>
                <column-selector  v-model="cup.comps" :available="availableComps" labelField="name" />
            </div>

            <div class="col-12">
                <label for="sort" class="control-label">Point sharing rules</label>
                <json-editor-vue  v-model="cup.pointsharing" ></json-editor-vue>
            </div>
            


            Points sharing sample. <a href="#" @click.prevent="useReferencePoints"  class="underline text-info my-2">Use these points</a>
            <code id="samplepoints" class="text-sm text-red-400 border border-red-700 p-2 rounded-md bg-gray-200">
1=100
2=80
3=60
4=50
5=45
6=40
7=36
8=32
9=29
10=26
11=24
12=22
13=20
14=18
15=16
16=15
17=14
18=13
19=12
20=11
21=10
22=9
23=8
24=7
25=6
26=5
27=4
28=3
29=2
30=1
other=0
            </code>



        </div>


    </form>
    <div v-else>Loading...</div>

</template>
<script setup>
  
  import { useStore } from 'vuex'
  import { computed, onMounted } from 'vue'
  import JsonEditorVue from 'json-editor-vue'
  import Checkbox from '@/components/MaterialCheckbox.vue'
  import ColumnSelector from '@/components/ColumnSelector.vue'
  
  const store = useStore()
  const props = defineProps({ 'id': String })

  const availableComps = computed(() => store.state.competitions.competitions)
  
  
  onMounted(() => {
    store.dispatch('competitions/getCompetitions')
  })

  const useReferencePoints = () => {
    const payload = {
        id : cup.value.id,
        pointsharing : {"1":"100","2":"80","3":"60","4":"50","5":"45","6":"40","7":"36","8":"32","9":"29","10":"26","11":"24","12":"22","13":"20","14":"18","15":"16","16":"15","17":"14","18":"13","19":"12","20":"11","21":"10","22":"9","23":"8","24":"7","25":"6","26":"5","27":"4","28":"3","29":"2","30":"1","other":"0"}
    }
    store.commit('cups/updateCup', payload)
  }
  
  const cup = computed(() => store.state.cups.cup)
  const skeleton = {
      id: "*new*",
      published: true,
  }
  
  if (props.id == null) {
      // Assume new problem (skeleton)
      store.commit('cups/cup', { ...skeleton })
  } else {
      store.dispatch('cups/getCup', props.id)
  }
  
  // eslint-disable-next-line no-unused-vars
  const save = (type) => {
      return store.dispatch('cups/saveCup', { ...cup.value })
  }
  const reset = () => {
      console.log("Resetting cup to", skeleton)
      const newSkeleton = { ...skeleton }
      store.commit('cups/cup', newSkeleton)
  }
  
  // Expose save method outside
  defineExpose({ save, reset })
  </script>