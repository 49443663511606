<template>
    <form v-if="contender != null" id="editcontender" role="form" class="form">

        <div class="row">
            <div class="col-md-12 col-sm-12">
                <label for="id" class="control-label ">ID</label>
                <input id="id" v-model="contender.id" type="text" readonly class="form-control-plaintext">
            </div>


            <div class="col-md-12 col-sm-12">
                <label for="sort" class="control-label">First name</label>
                <input id="sort" v-model="contender.etunimi" class="form-control" type="text" name="etunimi" size="3" />
            </div>
            <div class="col-md-12 col-sm-12">
                <label for="sort" class="control-label">Last name</label>
                <input id="sort" v-model="contender.sukunimi" class="form-control" type="text" name="sukunimi" size="3" />
            </div>
            <div class="col-md-12 col-sm-12">
                <label for="sort" class="control-label">Email
                </label>
                <input id="sort" v-model="contender.email" class="form-control" type="text" name="email"/>
                <small>Please use an unique email address</small>
            </div>
            <div class="col-md-12 col-sm-12">
                <label for="sort" class="control-label">Team</label>
                <input id="sort" v-model="contender.team" class="form-control" type="text" name="team" size="3" />
            </div>

            <div class="col-md-12 col-sm-12">
                <label for="height" class="control-label">Height</label>
                <input id="height" v-model="contender.height" class="form-control" type="number" />
            </div>

            <div class="col-md-12 col-sm-12">
                <label for="height" class="control-label">Ape index</label>
                <input id="height" v-model="contender.apeindex" class="form-control" type="text" />
                <small>Wing span - height. eg. climber is 178cm tall and wing span is 183cm, ape index is +5. Can also be negative</small>
            </div>

            <div class="col-md-12 col-sm-12">
                <label for="height" class="control-label">Country</label>
                <input id="height" v-model="contender.maa" class="form-control" type="text" />
            </div>


            <div class="col-md-12 col-sm-12">
                <label for="addt" class="control-label">Bio</label>
                <textarea id="addt" v-model="contender.bio" class="form-control"></textarea>
            </div>



        </div>


    </form>
    <div v-else>Loading...</div>

</template>
  <script setup>
  
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  
  const store = useStore()
  const props = defineProps({ 'id': String })
  
  
  const contender = computed(() => store.state.competitions.contender)
  const skeleton = {
      id: "*new*",
  }
  
  if (props.id == null || props.id == "*new*") {
      // Assume new problem (skeleton)
      store.commit('competitions/contender', { ...skeleton })
  } else {
      store.dispatch('competitions/getContender', props.id)
  }
  
  // eslint-disable-next-line no-unused-vars
  const save = (type) => {
      // Save problem
      return store.dispatch('competitions/saveContender', { ...contender.value, ['type'] : type })
  }
  const reset = () => {
      console.log("Resetting contender to", skeleton)
      // Save wallid, routetype, end and start holds
      const newSkeleton = { ...skeleton }
      store.commit('competitions/contender', newSkeleton)
  }
  
  // Expose save method outside
  defineExpose({ save, reset })
  </script>