<template>
  <div class="w-full bg-white p-2">
    <h2 class="mb-0 block">Currently climbing</h2>
    <small class="block">Updates every 5 secs and shows the current climbers.</small>
    <small class="font-bold">
      Last update: {{ latestUpdate }}, {{ updatedSecondsAgo }} seconds ago.
    </small>

    <table class="table table-striped table-bordered table-hover w-full">
      <tr>
        <th>Climber id</th>
        <th>Name</th>
        <th>Category</th>
        <th>Climbing dur</th>
        <th>actions</th>
      </tr>
      <transition-group tag="tbody" name="table-row" v-if="currentlyClimbing.length > 0">
        <tr v-for="part in currentlyClimbing" :key="part.id">
          <td>{{ part.climber.id }} {{ part.climber.etunimi }}</td>
          <td>{{ part.climber.sukunimi }}</td>
          <td>{{  part.category?.nimi }}</td>
          <td>{{  getClimbingDuration(part.climbing_start) }} </td>
          <td>
            <button @click="() => removeFromCurrentlyClimbing(part.climber.id)" class="btn btn-primary px-1 py-1" type="button">end climb</button>
          </td>
        </tr>
      </transition-group>
      <tr v-else>
        <td colspan="100">
          <p>No climbers currently climbing</p>
        </td>
      </tr>
    </table>
    <button @click="clearAllCurrentlyClimbing" class="btn btn-primary px-1 py-1" type="button">Clear all climbers</button>

  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import store from '../../store/store'
import dayjs from 'dayjs'
const props = defineProps({ compid: String })
const currentlyClimbing = computed(() => store.state.competitions.currentlyClimbing)
const latestUpdate = ref(null)
const updatedSecondsAgo = ref(0)
const updateLastUpdateAgo = () => {
  updatedSecondsAgo.value++

}
const getClimbingDuration = (start) => {
  return `${Math.floor(dayjs().diff(dayjs(start), 'minute'))}:${dayjs().diff(dayjs(start), 'second') % 60}`
}
setInterval(updateLastUpdateAgo, 1000)
const clearAllCurrentlyClimbing = () => {
  if (confirm("This clears all climbers from the list. Are you sure?")) {
    const payload = {
      compid: props.compid,
    }
    store.dispatch("competitions/endAllClimbing", payload)
    .then(() => {
      fetchCurrentlyClimbing()
    })
  }

}
const removeFromCurrentlyClimbing = (climberid) => {
  if (confirm("Are you sure?")) {
    const payload = {
      compid: props.compid,
      contid: climberid,
      end: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }
    store.dispatch("competitions/endClimbing", payload)
    .then(() => {
      fetchCurrentlyClimbing()
    })
  }
}

const fetchCurrentlyClimbing = () => {
  // Find latest n ticks for the comp
  store.dispatch("competitions/getCurrentlyClimbing", { compid: props.compid })
    .then(() => {
      const d = new Date()
      latestUpdate.value = d
      updatedSecondsAgo.value = 0
    })

}
const interval = ref(setInterval(fetchCurrentlyClimbing, 5000))
fetchCurrentlyClimbing()

onBeforeUnmount(() => {
  clearInterval(interval.value)
})

</script>

<style>
.tbody-enter-active,
.tbody-leave-active {
  transition: all 0.5s ease;
}

.tbody-enter-from,
.tbody-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
