<template>
  <form  v-if="circuit != null" id="editcircuit" role="form"  class="form">

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <label for="id" class="control-label ">ID</label>
        <input id="id"   v-model="circuit.id" type="text" readonly class="form-control-plaintext"  >
      </div>
        <div class="col-md-12 col-sm-12">
          <label for="id" class="control-label ">Is circuit public?</label>
          <checkbox v-model="circuit.ispublic" :checked-value="1" :unchecked-value="0" />
          <small>If you are a personal trainer and want to give access to this circuit just to selected people, uncheck this and add access to the users you want to be able to see the circuit</small>
        </div>

        <div class="col-md-12 col-sm-12">
          <label for="sort" class="control-label">Circuit name</label>
          <input id="sort" v-model="circuit.circuitname" class="form-control" type="text"  name="sort"  size="3" required />
          <small>Use names like: Easy, Medium, Hard, Beginner, Advanced, Pro, Endurance Set, Power endurance set, Fun climbs or Dyno set</small>
        </div>

        <div class="col-md-12 col-sm-12">
          <label for="sort" class="control-label">Circuit short name (eg. C1, or Yellow1, or Kids1)</label>
          <input id="sort" v-model="circuit.circuitshortname" class="form-control" type="text"  name="sort"  size="3" />
          <small>Use names like: C1, C2 because these are usually printed on problem sticker.</small>
        </div>


        <div class="col-md-12 col-sm-12">
          <label for="gradeid" class="control-label ">Min. grade</label>
          <v-select  id="gradeid" v-model="circuit.grademin"  :reduce="sel => sel.id" :options="gradesDropdown" />
            <small>Minimum grade is just a general guideline. It doesn't actually do anything, it's just a pointer to routesetters which is the preferred minimum grade.</small>
        </div>

        <div class="col-md-12 col-sm-12">
          <label for="gradeid" class="control-label ">Max. grade</label>
          <v-select  id="gradeid" v-model="circuit.grademax"  :reduce="sel => sel.id" :options="gradesDropdown" />
            <small>Maximum grade is just a general guideline. It doesn't actually do anything, it's just a pointer to routesetters which is the preferred maximum grade.</small>
        </div>



        <div class="col-md-12 col-sm-12">
          <label for="colour" class="control-label ">Colour</label>
          <v-select id="colour_choose" v-model="circuit.colourid" :reduce="sel => sel.id" :options="coloursDropdown" />
        </div>



        <div class="col-md-12 col-sm-12">
          <label for="sort" class="control-label">Sort</label>
          <input id="sort" v-model="circuit.sort" class="form-control" type="number"  name="sort"  size="3" />
        </div>

        <div class="col-md-12 col-sm-12">
          <label for="addt" class="control-label">Addt. info (eg. Good for sloper training...)</label>
          <textarea  id="addt"  v-model="circuit.addt" class="form-control"  ></textarea>
        </div>



    </div>


  </form>
  <div v-else>Loading...</div>

</template>
<script setup>

import { useStore } from 'vuex'
import { computed} from 'vue'
import vSelect from 'vue-select'
import Checkbox from '@/components/MaterialCheckbox.vue'
import 'vue-select/dist/vue-select.css'

const store = useStore()
const props = defineProps({ 'id' : String})


const basic = computed(() => store.state.basic)
const circuit = computed(() => store.state.circuits.row)
const colours = basic.value.colours
const grades = basic.value.grades 
const skeleton = {
  id : "*new*", 
}

if (props.id == null) {
  // Assume new (skeleton)
  store.commit('circuits/row',{...skeleton})
} else {
  store.dispatch('circuits/get',props.id)
}

// eslint-disable-next-line no-unused-vars
const save = () => {
  // Save problem
  return store.dispatch('circuits/save',{...circuit.value})
}
const reset = () => {
  console.log("Resetting circuit to",skeleton)
  // Save wallid, routetype, end and start holds
  const newSkeleton = {...skeleton}
  store.commit('circuits/row',newSkeleton)
}

const gradesDropdown = computed(() => {
  if (grades == null) {
    return []
  }
  return grades.map(a => ({id : a.id, label : a.name }))
})
const coloursDropdown = computed(() => {
  if (colours == null) {
    return []
  }
  return colours.map(a => ({id : a.id, label : a.name }))
})


// Expose save method outside
defineExpose({ save, reset })
</script>