<template>
  <modal
    v-model="showEditDialog"
    title="Floorplan editor"
    ok-title="Save"
    @ok="onSave"
    size="md"
  >
    <floorplan-editor :id="selectedRow" ref="refEditor" />
  </modal>
  <div class="container">
    <h2>
      Floor plans<small class="mx-2 fs-6 text-muted"
        >{{ rows.length }} floor plans
        <a class="btn btn-sm btn-primary" href="#" @click.prevent="addNew"
          >Add new...</a
        ></small
      >
    </h2>
    <p>
      You can add several floor plans to the gym. These are shown in problemator mobile's
      front page (and in several other places). These offer an easier navigation through
      the gym problems.
      <br /><br />
    </p>
    <p>
      Just drag the floorpan image here , click upload and you're good to go.

      <DropZone
        class="w-4/5 bg-blue-300 p-4 m-2 border border-blue-900 border-2 rounded-md drop-area"
        @files-dropped="addFiles"
        #default="{ dropZoneActive }"
      >
        <label for="file-input">
          <span v-if="dropZoneActive">
            <span>Drop Them Here</span>
            <span class="smaller">to add them</span>
          </span>
          <span v-else>
            <span>Drag Your Files Here</span>
            <span class="smaller">
              or <strong><em>click here</em></strong> to select files
            </span>
          </span>
          <br />

          <input type="file" id="file-input" multiple @change="onInputChange" />
        </label>
        <div v-if="floorPlan">
          {{ floorPlan.name }}
          <a href="#" @click.prevent="floorPlan = null"
            ><i class="fa fa-trash text-danger"></i
          ></a>
          <button class="mx-2 btn btn-primary" @click="onUploadFile">Upload</button>
        </div>
      </DropZone>
    </p>

    <div class="">
      Amount of floorplans: {{ rows.length }}
      <table class="w-full p-1 mx-1 bg-gray-400 mx-1" cellpadding="1">
      <tr>
      <th>sel.</th>
      <th>id</th>
      <th>image</th>
      <th>has imagemap?</th>
      </tr>
        <tr
          v-for="(slide, i) in rows"
          :key="slide.id"

        >
    <td>
    <button class="btn btn-sm btn-info" @click="selectedRow=slide,curSlide=slide.id;curSlideIndex=i">
        select
    </button>
    </td>
        <td> {{  slide.id }}</td>
        <td> {{  slide.imageurl }}</td>
        <td><div  v-if="slide.imagemap != null"  >yes</div><div v-else>no</div></td>
    <td>
    <button class="btn btn-sm btn-danger" @click="deleteFloorPlan(slide.id)">
        delete
    </button>
    </td>

        </tr>
      </table>
      <div class="m-2 flex flex-col" v-if="curSlide != null">
      <h1 class="font-bold text-2xl">Active floormap <small class="text-sm">{{ selectedRow.id }}</small></h1>

        <div class="md:w-3/5">
        <imagemap-creator v-model="selectedRow.imagemap" :src="selectedRow.url"  />
        </div>
        <textarea cols="80" rows="10" v-model="selectedRow.imagemap"></textarea>

        <button class="btn btn-primary" @click="saveImageMap">
          Save floormap imagemap
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { handleValidationErrors } from '@/helpers/errors.js'
import { useToast } from 'vue-toastification'
import Modal from '@/components/Modal.vue'
import FloorplanEditor from '@/components/FloorplanEditor.vue'
import ImagemapCreator from '@/components/ImagemapCreator.vue'
//import OmaTable from '../../components/OmaTable.vue'
import DropZone from '@/components/upload/DropZone.vue'
import axios from 'axios'
import { endpoint } from '@/helpers/problemator'

const saveImageMap = () => {
  const payload = {
    imagemap: selectedRow.value.imagemap,
    id: selectedRow.value.id,
  }
  store.dispatch('floorplans/update', payload)
}
const floorPlan = ref(null)
const onInputChange = (e) => {
  addFiles(e.target.files)
  e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
}
const addFiles = (newFiles) => {
  let [firstFile, ...rest] = newFiles
  console.log(rest)
  floorPlan.value = firstFile
}

const curSlide = ref(null)
const gym = computed(() => store.state.basic.gym)
const onUploadFile = async () => {
  let formData = new FormData()
  formData.append('file', floorPlan.value)
  // Upload
  const url = endpoint + 'gyms/' + gym.value.id + '/floorplan/upload'
  axios
    .post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((ret) => ret.data)
    .then((ret) => {
      console.log(ret)
      debugger
      toast(ret.imageurl, { allowHtml: true })
      store.commit('floorplans/addRow', ret)
    })
    .catch((err) => {
      toast.error(handleValidationErrors(err))
    })
}

const selectedRow = ref(null)
const showEditDialog = ref(false)
const refEditor = ref(null)
const toast = useToast()

const addNew = () => {
  selectedRow.value = null
  showEditDialog.value = true
}
const deleteFloorPlan = (id) => {
  store.dispatch('floorplans/delete', { id })
}
const onSave = () => {
  refEditor.value
    .save()
    .then((ret) => {
      console.log(ret)
      selectedRow.value = null
      showEditDialog.value = false
      toast.success('Saved')
      refEditor.value.reset()
    })
    .catch((err) => {
      toast.error(handleValidationErrors(err))
    })
}
const store = useStore()
const rows = computed(() => store.state.floorplans.all.sort((b, a) => a.id - b.id))
store.dispatch('floorplans/all')
</script>