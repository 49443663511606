<template>
    <form v-if="comp != null" id="add_ascents" action="" name="add_ascents" class="bg-white p-1">
        <h1 class="my-1 font-bold text-xl">{{ comp.compname ?? comp.name }}</h1>
        <div class="my-2 ">Start writing any part of the contender name. Or use number in shirt. You can even use first letters of first AND last name.</div>
        <div>
            <div class="control-label font-bold" for="contender_name">Contender</div>
            <div v-if="selected != null" class="font-bold text-center">
                {{ selected.id }} {{ selected.etunimi }} {{ selected.sukunimi }}
            </div>
            <div class="input-group mb-3 py-0">
                <input @keyup="fetchContender" class="form-control" type="text" v-model="search" />
                <button class="btn btn-danger mb-0" type="button" @click="clearContender">clear</button>
            </div>

            <div v-if="hits.length > 0" class="relative  m-2 border-2 rounded-md border-black bg-white w-11/12 h-1/2">
                <h4 class="mx-4">Choose a contender</h4>
                <ul>
                    <li v-for="hit in hits" :key="hit.id" class="my-1 text-white border border-gray-700 mx-4 text-center py-1 px-2 bg-blue-400">
                        <a href="#" @click.prevent="() => selectContender(hit)">
                            {{ hit.etunimi }} {{ hit.sukunimi }} <small class="self-end">{{ hit.id }}</small>
                        </a>
                    </li>
                    <li class=" my-2" @click="search = ''; hits = []"><button class="btn btn-sm btn-danger">Close</button></li>
                </ul>
            </div>
        </div>


        <div class="border-top border-l border-r p-3">
        <ul class="bg-white flex flex-row gap-4 border-b pt-1 pb-3">
            <li class="nav-item p-2 border " >
                <a class="nav-link " :class="{ underline : styleActive=='boulder'}" @click.prevent="styleActive='boulder'"   href="#">Boulder</a>
            </li>
            <li class="nav-item p-2 border">
                <a class="nav-link " :class="{underline : styleActive=='sport'}" @click.prevent="styleActive='sport'"   href="#">Sport</a>
            </li>
        </ul>

        <div v-if="styleActive == 'boulder'">
            <div>
                <div class="control-label font-bold " for="routenro">Problem(s)</div>
                <select v-model="routeIds" class="w-full form-control rounded-lg" :multiple="tickMultiple">
                <option value=''>Select a route</option>
                <option v-for="route in filteredBySerieCompProblems" :value="route.pivot.problemid" :key="route.id" >{{getProblemName(route) }}</option>
                </select>
            </div>
            <div class="flex flex-col">
                <div class="control-label " for="tries_top">Tries to top</div>
                <div class="flex flex-row justify-start gap-2">
                    <input type="number" v-model="triesTop" class="font-bold border border-black text-center w-8 p-1" name="tries_top" size="3" />
                    <button @click="triesTop = 0" type="button" class="btn btn-sm btn-info py-2 px-3" value="0">0</button>
                    <button @click="triesTop = 1" type="button" class="btn btn-sm btn-info py-2 px-3" value="1">1</button>
                    <button @click="triesTop = 2" type="button" class="btn btn-sm btn-info py-2 px-3" value="2">2</button>
                    <button @click="triesTop = 3" type="button" class="btn btn-sm btn-info py-2 px-3" value="3">3</button>
                    <button @click="triesTop = 4" type="button" class="btn btn-sm btn-info py-2 px-3" value="4">4</button>
                    <button @click="triesTop = 5" type="button" class="btn btn-sm btn-info py-2 px-3" value="5">5</button>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="control-label col-sm-2" for="tries_bonus">Tries to bonus</div>
                <div class="flex flex-row justify-start gap-2">
                    <input v-model="triesBonus" class="font-bold border border-black text-center w-8 p-1" type="number" name="tries_bonus" size="3" />
                    <button @click="triesBonus = 0" type="button" class="btn btn-sm btn-info py-2 px-3" value="0">0</button>
                    <button @click="triesBonus = 1" type="button" class="btn btn-sm btn-info py-2 px-3" value="1">1</button>
                    <button @click="triesBonus = 2" type="button" class="btn btn-sm btn-info py-2 px-3" value="2">2</button>
                    <button @click="triesBonus = 3" type="button" class="btn btn-sm btn-info py-2 px-3" value="3">3</button>
                    <button @click="triesBonus = 4" type="button" class="btn btn-sm btn-info py-2 px-3" value="4">4</button>
                    <button @click="triesBonus = 5" type="button" class="btn btn-sm btn-info py-2 px-3" value="5">5</button>
                </div>
            </div>
            <div class="mt-3 mb-0 py-1 flex flex-col">
                <button @click="addAscent" class="btn btn-primary px-8 py-2 my-1" type="button">Add ascent</button>
                <button @click="() => fetchAscents(comp.id, contid)" class="btn btn-primary px-8 py-2 my-1" type="button">Fetch ascents</button>
            </div>
        </div>
        <div v-if="styleActive == 'sport'">
            <div class="flex flex-col my-2 " id="sport_enter_page">
                <label class="font-bold" for="problemid">Route</label>
                <div class="my-2">
                    <div v-if="comp.problems.length == 0" class="well well-lg">You don't have any routes yet! Go to <strong>routes</strong> and edit route to set the route type</div>
                    <select v-else v-model="problemid" class="form-control">
                    <option>Select a route</option>
                        <option v-for="prob in filteredBySerieCompProblems" :key="prob.id" :value="prob.id">{{ prob.pivot.num }}. {{ prob.tag }} {{ prob.wallchar }} {{ prob.colour.name }} (max points: {{ prob.pivot.max_points }}, {{ prob.pivot.routetype }}, {{ prob.pivot.bound }})</option>
                    </select>
                </div>
            </div>

            <div class="flex flex-row mb-2 ">
                <div class="w-1/3">
                <div class="">
                    <div>Points:</div>
                    <input ref="sportPointsField" class="font-bold border border-black text-center w-16 p-1" type="text" name="sport_points" id="sport_points" size="5" v-model="sportPoints" />
                    <button @click="sportPoints = 'TOP'" type="button" class="border border-black mx-1 bg-blue-400 px-2 py-1 rounded-md">TOP</button>
                </div>
                </div>
                <div>
                <div class="flex flex-row my-2 justify-center">
                    <div>
                        min<br />
                        <input type="text" class="font-bold border border-black text-center w-16 p-1" name="timemin" v-model="timemin" size="2" />
                    </div>
                    <div>
                        secs<br />
                        <input class="font-bold border border-black text-center w-16 p-1" type="text" name="timesec" v-model="timesec" size="2" />
                    </div>
                    <div>
                        OR input time in seconds<br />
                        <input type="text" class="font-bold border border-black text-center w-16 p-1" name="time_manual" v-model="time_manual" size="4" />
                    </div>
                </div>
                <div id="timer_started"></div>
                <div id="timer_now"></div>
                <div class="flex flex-row justify-center">
                    <button @click="timerStart" class="border border-black rounded-md mx-1 px-2 py-1 text-sm bg-green-500" type="button" id="timer_start">Start</button>
                    <button @click="timerContinue" class="border border-black rounded-md mx-1 px-2 py-1 text-sm bg-gray-500" type="button" id="timer_continue">Continue</button>
                    <button @click="timerStop" class="border border-black rounded-md mx-1 px-2 py-1 text-sm bg-orange-500" type="button" id="timer_stop">Stop</button>
                    <button @click="timerReset" class="border border-black rounded-md mx-1 px-2 py-1 text-sm bg-red-500" type="button" id="timer_reset">Reset</button>
                </div>
                </div>

            </div>
            <div class="mb-1 flex flex-col">
                <button @click="addSportAscent" class="btn btn-primary px-8 py-2 my-1" type="button">Add ascent</button>
                <button @click="() => fetchAscents(comp.id, contid)" class="btn btn-primary px-8 py-2 my-1" type="button">Fetch ascents</button>
            </div>

        </div>
        </div>

        <div v-if="ascents?.length > 0">
            <ul class="my-2">
                <li v-for="asc in ascents" :key="asc.id" class="my-1 border border-gray-700 mx-4 text-white text-center py-1 px-2 bg-blue-400 rounded-lg">
                    <div>
                        {{ asc.id }} | route: {{ asc.num }}, Tries top: {{ asc.tries }}, Tries bonus: {{ asc.tries_bonus }} | {{ asc.sport_points }}
                        <button @click.prevent="removeAscent(asc.tickid)" class="m-0 py-0  px-2 btn-sm btn btn-danger ">delete</button>
                    </div>
                </li>
            </ul>
        </div>

    </form>
    <div v-else>Comp is empty</div>

</template>
  
<script setup>
import { computed, ref } from 'vue'
import { debounce } from "@/helpers/debouncedRef.js"
import store from '../../store/store'
import { useToast } from "vue-toastification"
//import ContenderSearchVue from '../ContenderSearch.vue'
const toast = useToast()
const tickMultiple = ref(true)

const sportPointsField = ref(null)
const props = defineProps({'comp' :null})
const contid = ref(null)
const styleActive = ref('boulder')
const selected = ref(null)
const ascents = computed(() => store.state.competitions.climberAscents)
const hits = ref([])
const routeIds = ref("")
const triesTop = ref(1)
const triesBonus = ref(1)
const search = ref("")
let t = null
var saveTime = (time, type) => {
    // Save timer start also to db... In case of login craziness.
    const act = "sport_timer_" + type
    const opt = {
        "contender": contid.value,
        "problemid": problemid.value,
        "frm_compid": props.comp.id,
        "act": act,
        "time": time,
    }
    localStorage.setItem(type, JSON.stringify(opt))
}
const filteredBySerieCompProblems = computed(() => {
    // Filter by serie. Because certain routes are only for certain series and therefore for
    // certain climbers.
    const climber = selected.value
    if (climber == null || climber.pivot == null) {
        return props.comp.problems
    }
    return props.comp.problems.filter(x => {
        if (x.pivot == null) {
            return true
        }
        if (x.pivot.bind_to_category == null) {
            return true
            }
        return x.pivot.bind_to_category == climber.pivot.serieid 
    })
})
const climberTimer = () => {
    showTime(start.value)
    t = setTimeout(climberTimer, 1000)
}

const showTime = (start) => {
    var now = new Date()
    var time = now.getTime()
    console.log(start)

    const secsFromStart = (time - start) / 1000
    var mins = Math.floor(secsFromStart / 60)
    var secs = Math.round(secsFromStart % 60, 0)
    timemin.value = mins
    timesec.value = secs
}
const timerContinue = () => {
    var now = new Date()
    var time = now.getTime()
    time = time - stopSecs.value
    start.value = time
    saveTime(start.value, 'start')
    t = setTimeout(climberTimer, 1000)
}


const timerStart = () => {
    var now = new Date()
    var time = now.getTime()
    start.value = time
    saveTime(start.value, 'start')
    t = setTimeout(climberTimer, 1000)
}

const timerStop = () => {
    clearTimeout(t)
    // Save stop time.
    var now = new Date()
    var time = now.getTime()
    stop.value = time
    stopSecs.value = time - start.value
    saveTime(stop.value, "stop")
    timerReset(false)
}

const timerReset = (clearTime = true) => {
    start.value = 0
    stop.value = 0
    localStorage.removeItem('start')
    localStorage.removeItem("stop")
    localStorage.removeItem("reset")
    clearTimeout(t)
    if (clearTime) {
        timemin.value = ""
        timesec.value = ""
    }
}


const start = ref(null)
const stopSecs = ref(0)
const timesec = ref("")
const time_manual = ref("")
const timemin = ref("")
const problemid = ref([])
const sportPoints = ref()

const fetchAscents = () => {
    const payload = {
        contid: contid.value,
        compid: props.comp.id
    }
    if (contid.value == null) {
        alert("Select a contender first!")
        return
    }
    store.dispatch('competitions/getClimberAscents', payload)
}
const addSportAscent = () => {
    if (isNaN(parseInt(contid.value))) {
        alert("Cannot add without contender id")
        return
    }
    if (problemid.value.length== 0) {
        alert("Select a problem first")
        return
    }
    const timerSecs = parseInt(timemin.value) * 60 + parseInt(timesec.value)
    const params = {
        contender: contid.value,
        problemid: [problemid.value],
        sport_points: sportPoints.value,
        timemin: timemin.value,
        timesec: timesec.value,
        sport_timer_secs: timerSecs,
        time_manual: time_manual.value,
        comp_id: props.comp.id
    }
    store.dispatch("competitions/addAscent", params)
        .then(ret => {

            toast(ret.message)
            // Empty values
            sportPoints.value = null
            timemin.value = null 
            timesec.value = null
            time_manual.value =null
        })
      .catch(err => toast.error(err.response.data.message))

}

const addAscent = () => {
    if (isNaN(parseInt(contid.value))) {
        alert("Cannot add without contender id")
        return
    }
    if (routeIds.value == "") {
        alert("Cannot add without route id(s)")
        return
    }
  if (!Array.isArray(routeIds.value)) {
    routeIds.value = [routeIds.value]
  }
    const params = {
        contender: contid.value,
        routeid: routeIds.value,
        tries_bonus: triesBonus.value,
        tries_top: triesTop.value,
        comp_id: props.comp.id,
    }
    store.dispatch("competitions/addAscent", params)
        .then(ret => {
            toast(ret.message)
        })
      .catch(err => toast.error(err.response.data.message))
}

const removeAscent = (tickid) => {
  const payload = {
    compid : props.comp.id,
    tickid : tickid,
  }
  store.dispatch("competitions/removeAscent", payload)
  .then(ret => {
     // remove from list
     toast(ret.message);
    })
    .catch(err => toast.error(err.response.data.message))
}
const clearContender = () => {
    selected.value = null
    contid.value = null
    search.value = null
    ascents.value = []
}
const selectContender = (hit) => {
    if (hit != null) {
        selected.value = hit
        search.value = hit.nimi
        contid.value = hit.id
        hits.value = []
        const payload = {
            contid: contid.value,
            compid: props.comp.id
        }
        if (styleActive.value == 'sport') {
            sportPointsField.value.focus()
        }
        store.dispatch('competitions/getClimberAscents', payload)
    }
}
const fetchContender = debounce(() => {
    selected.value = null
    hits.value = []
    const comp = props.comp
    if (search.value == null) {
        return
    }
    const term = search.value.toLocaleLowerCase()
    const m = term.match(/(\w+)\s+(.*)/)
    // Search inside comp
    if (m != null) {
        // eslint-disable-next-line 
        let [whole, first, last] = m
        first = first.toLocaleLowerCase()
        last = last.toLocaleLowerCase()

        hits.value = comp.paidregistrations.filter(c => {
            if (
                (c.etunimi.toLowerCase().indexOf(first) >= 0 && c.sukunimi.toLowerCase().indexOf(last) >= 0)) {
                return true
            }
            return false
        })
    } else {
        // Search from full name
        hits.value = comp.paidregistrations.filter(c => (c.etunimi?.toLowerCase() + " " + c.sukunimi?.toLowerCase()).indexOf(term) >= 0 || c.pivot.tshirtnumber === term)
    }

}, 300)
const getProblemName = (route) => {
  if (route.pivot.num  != null) {
     return route.pivot.num 
  }
  const routeType = route.pivot.routetype != null ? route.pivot.routetype : ""
  return route.tag + " " + routeType
}

</script>
